.code-products-list {
  padding: 40px 0px 0px 0px;
  width: 1300px;

  .header {
    width: 1300px;
    padding-top: 30px;
    padding-bottom: 40px;
  }

  &.loading-code-products {
    text-align: center;
    font-size: 20px;
  }
  .tooltip {
    z-index: unset;
  }
  .exclusiveToPartner {
    .pucl-select-container .pucl-select__filter.hide-filter{
      display: unset;
    }
    .pucl-select__options-select-all {
      display: none;
    }
    .pucl-select-container .pucl-select__menu.multiple .select-element-menu{
      margin-top: 0px;
    }
  }

  .pucl-action-bar {
    z-index: inherit;

    .bar {
      .action-bar-container.left {
        .action-bar-filter-button-container {
          .tooltip {
            opacity: 1;
          }

          .tooltip:hover {
            text-decoration:none;
          }
        }
      }
      .action-bar-container.right {
        > #new-code-product.new-code-product.pucl-btn {
          padding-right: 20px;
          background-color: #0072ce;
          > .button-text {
            color: #fff;
            > .pucl-icon {
              background-color: #0072ce;
            }
          }
        }
      }
    }

    .pucl-filter-container.open {
      .filters {
        .filter {
          width: 500px;
        }
      }
    }
  }

  .code-products-table {
    width: 1300px;
  
    .code-products-content {  
      .sort-icons{
        margin-left: 0px !important;
      }
  
      .header-cell:first-child {
        .header-cell-wrapper {
          .content {
            padding-left: 0px;
          }
        }
      }

      .header-cell:not(:first-child) {
        .header-cell-wrapper {
          .content {
            padding-left: 0px;
            padding-right: 10px;
          }
        }
      }

      .cell-container.code-product-name.first {
        .row-cell {
          a:visited {
            color: #0072ce;
          }

          a:hover {
            text-decoration: none;
          }

          .cell-preface {
            margin-right: 0px;
            .hide-icon {
              padding-right: 0px;
            }
          }   
        }
      }

      .cell-container {
        .tooltip {
          opacity: 1;
        }

        &.applicable-agreement-types {
          a:hover {
            text-decoration: none;
          }
        }

        &.exclusive-to-Partner {
          a:hover {
            text-decoration: none;
          }
        }

        .multiple {
          color: #0072ce;
          font-size: 14px;
          line-height:20px;
          font-family: 'SSTPro-Medium';
        }
      }
    }
  }

  .code-products-paginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .pucl-select-container {
      min-width: 80px;
    }
  
    .page-link {
      text-decoration: none;
    }
  
    .sie-select__field{
      .sie-select-arrow{
        color: #999999;
      }
    }
  
    .sr-only{
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit;
    }
  }
}
