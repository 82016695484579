.order-success-page {
  padding-top: 41px;
  a {
    text-decoration: none;
  }
  .order-success-page-content-wrapper {
    padding-top: 140px;
    width: 100%;
    .order-success-page-content {
      max-width: 600px;
      margin: 0 auto;
      text-align: center;
      .check_circle-icon {
        color: #2b9e98;
      }
      .footer {
        text-align: left;
      }
      .footer-buttons {
        padding-top: 80px;
        display: inline-flex;
        flex-wrap: wrap;
        .product-catalog {
          margin-right: 17.5px;
          .pucl-btn {
            width: 185px;
          }
        }
        .orders {
          margin-left: 17.5px;
          .pucl-btn {
            width: 185px;
          }
        }
      }
    }
  }
}
