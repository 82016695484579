#swap-code-product-modal {
  .static-text {
    margin: 30px 0px 0px 0px;
  }

  .code-product-search {
    display: flex;
    .pucl-input {
      margin-right: 20px;
    }
    #code-product-search-button {
      margin-top: 10px;
    }
  }

  .pucl-data-table {
    min-height: 244px;
    .header-cell {
      padding-left: 0px;
      .header-cell-wrapper {
        margin-left: 10px;
      }
      .content {
        padding-left: 0px;
      }
    }
    .cell-container {
      &.first {
        padding-left: 10px;
      }
      &.label-cell {
        background-color: #f3f3f3;
      }
    }
  }

  .code-product-swap-footer {
    display: flex;
    justify-content: end;
    padding-top: 40px;

    button:first-child {
      margin-right: 15px;
    }
  }
}