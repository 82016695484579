.voucher-type-id-selector-container {
  min-width: 1380px;

  .pucl-table-wrapper {
    .header-cell.type-id-header-name.first.has-checkbox {
      > .header-preface {
        > .pucl-input.pucl-input-wrapper.pucl-checkbox {
          visibility: hidden;
        }
      }
    }

    div.cell-container.voucher-type-name.first {
      .has-checkbox {
        > .row-cell.checkbox {
          margin-top: 0px;
          margin-bottom: 0px;
        }
        > .expand-icon.hide-icon {
          display: none;
        }
      }
    }

    .header-cell {
      &.first {
        .header-cell-wrapper {
          margin-left: 0px;
          .content {
            padding-left: 15px;
          }
        }
      }

      .header-cell-wrapper {
        .content {
          padding-left: 0px;
        }
      }
    }
  }

  .type-id-wrapper {
    background: rgb(255, 255, 255);
    display: flex;
    position: fixed;
    top: 100px;
    left: 0;
    padding-left: 40px;
    width: 100%;
    height: 59px;
    z-index: 2101;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 15px;
    align-items: center;
    justify-content: center;
  
    .type-id {
      height: inherit;
      display: flex;
      align-items: center;
      min-width: 1380px;
  
      .type-id-title {
        h3 {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
  
      .type-id-buttons {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-left: 50px;
  
        .type-id-search.search-open {
          justify-content: end;
          padding-right: 15px;
  
          .text-input-row-flex-wrapper {
            padding-top: 6px;
          }

          > .pucl-search__input-container {
            > .pucl-search__text-input-container {
              > .pucl-input.pucl-input-wrapper {
                padding-bottom: 0px;
              }
            }
          }
        }
  
        .add-type-id-button, 
        .cancel-button {
          width: 120px;
        }

        .add-type-id-button {
          width: 191px;
        }
  
        .cancel-button {
          margin-right: 10px;
        }
      }
    }
  }

  .select-voucher-type-paginator {
    margin-top: 45px;
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between;
    .pucl-select-container {
      min-width: 80px;
    }
  
    .page-link {
      text-decoration: none;
    }
  
    .sie-select__field{
      .sie-select-arrow{
        color: #999999;
      }
    }
  
    .sr-only{
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit;
    }
  }
}
