.orders-list {
  padding-bottom: 65px;

  .orders-list-header {
    padding-top: 70px;
    padding-bottom: 40px;

    .select-container {
      .pucl-search__category-select-container {
        > .pucl-search__category-select {
          width: 224px;
        }
        > .pucl-search__category-select-menu {
          > .category-options-container {
            width: 206px;
          }
        }
      }
    }
  }

  .pucl-action-bar {
    .bar {
      .action-bar-container.left {
        .tooltip:hover {
          text-decoration: none;
        }
      }
  
      .action-bar-container.right {
        > #new-order-button.new-order-button.pucl-btn {
          padding-right: 20px;
          background-color: #0072ce;
          > .button-text {
            color: #fff;
            > .pucl-icon {
              background-color: #0072ce;
            }
          }
        }
      }
    }
  }

  .filters {
    .filters-container {
      flex-wrap: wrap;

      .actionRequired {
        max-width: 305px;
      }

      .creationDateRange {
        padding-top: 10px;
        min-width: 305px;
        .pucl-date-range {
          .pucl-input-field-container {
            min-width: 295px;
          }
        }
      }

      .country {
        min-width: 305px;
      }

      .region {
        min-width: 305px;
      }
      
      .activationState {
        min-width: 305px;
      }

      .orderStatus {
        padding-top: 10px;
        min-width: 305px;
      }

      .agreementType {
        padding-top: 10px;
        min-width: 305px;
      }

      .filter.undefind {
        display: none;
      }
    }
  }

  .pucl-table-wrapper {
    .pucl-action-bar {
      z-index: 1;
    }
    .cell-container.first {
      .activeOrder-link {
        color: #0072ce;
        font-family: 'SSTPro-Medium';

        a:hover {
          text-decoration: none;
        }

        a:active, a:visited {
          color: #0072ce;
        }

        .tooltip {
          opacity: 1;
          .truncate {
            width: 214px;
            line-height: 20px;
            font-size: 14px;
            font-family: 'SSTPro-Roman';
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
          }
        }
      }
    }

    .cell-container {
      .row-cell {
        .row-cell-content {
          .pucl-tag.in-progress,
          .pucl-tag.error,
          .pucl-tag.inactive {
            .tag-icon {
              width: 13px;
            }
          }

          .pucl-tag.valid {
            .tag-icon {
              width: 13px;
              background-color: #2d9e94;
            }
          }

          .pucl-tag.new {
            // @100% Zoom
            @media only screen and (min-width: 2328px) and (max-width: 2560px) {
                .tag-icon {
                  width: 16px;
                }
              }

              // @110% zoom
              @media only screen and (min-width: 2049px) and (max-width: 2328px) {
                .tag-icon {
                  width: 14px;
                }
              }

              // @125+ % zoom
              @media only screen and (min-width: 0px) and (max-width: 2049px) {
                .tag-icon {
                  width: 16px;
                }
              }
          }
        }
      }

      &.disabled {
        .tag-label {
          color: #a8a8a8;
        }
      }

      &.disabled:hover {
        background-color: #f6fbff;
      }

      &.partnerName {
        span {
          color: #363636;
        }

        .tooltip {
          opacity: 1;
          text-decoration: none;

          .tooltip__inner-content {
            color: #fff;
          }
        }

        .tooltip:hover {
          text-decoration: none;
        }

        .truncate {
          color: #363636;
          width: 142px;
          line-height: 20px;
          font-size: 14px;
          font-family: 'SSTPro-Roman';
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .order-list-order-name-tooltip {
      a.tooltip {
        text-decoration: none;
      }
    }

    .order-list-order-countries-tooltip {
      padding-top: 6px;
      padding-left: 2px;
      a.tooltip {
        text-decoration: none;
      }
    }
  }

  .pucl-data-table .header-cell-wrapper .content {
    padding: 0px;
  }

  .order-row {
    border-bottom: 1px solid #000;
  }

  .order-row.disabled.row {
    .order-line {
      color: #a8a8a8;
    }

    span.toggle.collapsed:before {
      color: initial;
    }
  }

  .cell-container.order-line {
    &.isRemoved,
    &.isCancelled {
      color: #a8a8a8;
    }
  }

  .comment {
    padding-top: 5px;

    #order-comment-tooltip {
      z-index: 12108;
    }
  }

  .order-list-pagination {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .pucl-select-container {
      min-width: 80px;
    }

    .page-link {
      text-decoration: none;
    }

    .sie-select__field{
      .sie-select-arrow{
        color: #999999;
      }
    }

    .sr-only{
      width: 9.5px;
      height: 12px;
      margin: 0;
      position: inherit;
    }
  }

  #order-comment-tooltip.tooltip__content {
    width: 300px;
    padding: 10px 0px;

    hr {
      margin-top: 10px;
      margin-bottom: 10px;
      border-color: #d8d8d8;
    }

    .comment-overlay-header,
    .comment-overlay-content {
      padding-left: 10px;
      padding-right: 10px;
    }

    .comment-overlay-content {
      .comment-overlay-content-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
    }
  }

  .tooltip {
    opacity: 1;
    z-index: 0;
    // Adding because Bootstrap is overridding PUCL font-family.
    // Remove when no longer using Boostrap.
    font-family: 'SSTPro-Roman' !important;

    .tooltip__inner-content {
      z-index: 1000;

      .comment-overlay {
        z-index: 1000;

        .comment-overlay-header,
        .comment-overlay-content {
          padding-left: 10px;
          padding-right: 10px;
        }

        .comment-overlay-content {
          .comment-overlay-content-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
          }
        }
      }
    }

    .tooltip--visible {
      opacity: 1;
    }
  }

  .tooltip__content.tooltip--visible {
    z-index: 2100;
  }
}
