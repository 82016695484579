.secondary-row {
  .pucl-icon {
    font-size: 16px;
  }
  .cell-container{
    padding-left: 10px;
  }
  .secondary-headers-row {
    display: flex;
    justify-content: flex-start;
    background-color: #F3F3F3;
    align-items: stretch;
    .secondary-header {
      padding: 10px 20px;
      border: 1px solid #cacaca;
      white-space: wrap;
      word-break: break-word;
      display: flex;
    }
  }
  .secondary-row-content {
    display: flex;
    justify-content: flex-start;
    background-color: #FFF;
    .startDateTime, .endDateTime{
      margin-left: 10px;
      margin-bottom: 5px;
      .time-input {
        margin-left: 10px;
      }
    }
  }
  .download-history-icon {
    margin: 10px;
    display: flex;
    align-items: center;
    .tooltip-label{
      margin-right: 5px;
    }
    .order-history-info-tooltip {
      padding-top: 5px;
    }
  }
  .no-po {
    margin-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
  }
  .poNumber {
    width: 300px;
    display: flex;
    align-items: center;
  }
  .tooltip-wrapper {
    align-self: center;
  }
  .startEndDate {
    display: flex;
    flex-grow: 2;
    align-items: center;
  }
  .startDateTime, .endDateTime {
    display: flex;
    .pucl-date {
      min-width: 163px;
      margin-right: 10px;
    }
    .pucl-time-input {
      &.t12hr {
        & > div:nth-child(2) {
          padding-top: 5px;
        }
      }
    }
  }
  .text.startDateTime, .text.endDateTime, .no-date {
    margin-left: 20px;
  }
}
.tooltipDefaultStyles.startEndDate, .tooltipDefaultStyles.poNumber {
  width: 250px;
  display: flex;
  flex-direction: column;
}