@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

@keyframes spin {
  from { transform: scale(1) rotate(0deg);}
  to { transform: scale(1) rotate(360deg);}
}

@-webkit-keyframes spinw {
  from { -webkit-transform: rotate(0deg);}
  to { -webkit-transform: rotate(360deg);}
}

@-moz-keyframes spinm {
  from { -moz-transform: rotate(0deg);}
  to { -moz-transform: rotate(360deg);}
}

html, body, #root, .App, #root > div {
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #ffffff;

  .content {
    padding: 0 30px;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li span {
  text-decoration: underline;
  cursor: pointer;
}

hr {
  border-color: #000;
}

.required:after {
  content: ' *';
  color: red;
}

tr {
  &.isNew {
    background: lightblue;
  }
  &.hasError {
    color: #999999;
  }
}

select.form-control[disabled] {
  pointer-events: none;
}

.no-wrap {
  white-space: nowrap;
}

div.App.container,
nav.order-nav .container,
nav.partner-nav .container,
nav.global-header .container,
nav.venom-header .container,
div.orders-header .container,
div.users-header .container,
div.search-header .container,
div.partners-header .container
div.vouchers-header .container {
  width: calc(100% - 60px);
}

nav.user-nav .container,
nav.order-nav .container,
nav.global-header .container,
nav.venom-header .container,
nav.partners-nav .container {
  padding: 0;
}

div.order.container,
div.order-info-body .container,
div.order-list.container,
div.partner.container,
div.search.container,
div.user-list.container,
div.user.container,
div.partner-list.container {
  width: 100%;
}

.page-container {
  @media (min-width: 1200px){
    .container {
      width: 96%;
    }
  }
}

.sie-notification:first-child {
  margin-top: 110px;
}

.sie-notification__content{
  text-align: center;
}

.codes .pucl-notification-container {
  top: 60px;
  z-index: 9001;
  position: fixed;
  .voucher-typeId-name-link{
    color: white;
    text-decoration: underline;
    &:hover {
      color: #0072ce;
    }
  }
}

.codes > .content > .container {
  @media(max-width: 1199px) {
    margin-top: -20px;
  }
}

.tooltip {
  cursor: pointer!important;
}

div[role="dialog"] {
  .modal {
    margin-top: 100px;
  }
}

.voucher-only-product {
  height: 16px;
  width: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  font-size: 11px;
  background-color: #636365;
  color: #ffffff;
  margin-right: 5px;
}

.pucl-notification--sucess {
  background-color: #2d9e94;
}


