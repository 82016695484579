.partner {
  padding-top: 69px;
  .partner-nav {
    height: 60px;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    margin-left: -30px;
    position: fixed;
    top: 100px;
    z-index: 1000;
    box-shadow: 0 0 4px 0 rgba(black, 0.3);

    .title {
      font-size: 26px;
      font-family: 'SSTPro-Light';
    }

    @media (max-width: 1200px) {
      top: 80px;
    }
  }

  .pucl-collapsible-container {
    max-width: 100%;
    .rah-static {
      max-width: 100%
    }
  }

  .form-horizontal label.control-label {
    text-align: left;
    font-weight: normal;
  }
  .form-group {
    margin-bottom: 30px;
  }

  .partner-info {
    margin-top: 70px;
    .pucl-collapsible-container {
      max-width: 1300px;
      margin-bottom: 40px;
      .container {
        width: auto;
        max-width: 1300px;
        padding: 0 70px;
        .pucl-divider {
          border: none;
        }

        .label {
          font-family: 'SSTPro-Roman';
          font-size: 14px;
        }
        .value {
          font-family: 'SSTPro-Medium';
          font-size: 14px;
          word-wrap: break-word;
        }
      }
      .pucl-collapsible {
        pointer-events: none;
        .pucl-rightContent {
          .pucl-collapsible-icon {
            visibility: hidden;
          }
        }
      }
    }
  }

  .pucl-table-wrapper {
    box-shadow: none;
    .pucl-data-table {
      min-height: 367px;
    }
  }

  .partner-users {
    .pucl-collapsible-container {
      margin-bottom: 40px;
      max-width: 1300px;
    }
  }

  .users-wrapper {
    .pucl-collapsible-container {
      .pucl-scrollarea--vertical-track {
        padding-bottom: 220px;
      }
    }
  }

  .partner-agreements {
    .pucl-collapsible-container {
      margin-bottom: 40px;
      max-width: 1300px;
    }
  }
  .agreements-wrapper {
    .pucl-collapsible-container {
      .pucl-scrollarea--vertical-track {
        padding-bottom: 220px;
      }
    }
  }
  .partner-sftps {
    .pucl-collapsible-container {
      margin-bottom: 100px;
      max-width: 1300px;
    }
  }
}
