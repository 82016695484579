.user-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px;

  @media(max-width: 1199px) {
    margin-top: -20px;
  }

  .user-nav {
    height: 60px;
    width: 100%;
    background: #fff;
    padding: 10px 0px;
    position: fixed;
    top: 100px;
    z-index: 1;
    box-shadow: 0 0 4px 0 rgba(black, 0.3);

    .container {
      padding-left: 0px;
      padding-right: 0px;
    }

    .title {
      font-size: 26px;
      font-family: 'SSTPro-Light';
    }

    .buttons {
      text-align: right;
    }

    @media (max-width: 1199px) {
      top: 80px;
    }
  }

  .user-info {
    margin-left: 0px;
    padding: 0px 70px;
  }
}

.pucl-collapsible-container {
  background: white;

  .pucl-nested {
    overflow: hidden !important;
  }
}

.userName span {
  color: #063A8f;
  cursor: pointer;
}

.userInfoDatesDetail{
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;

  span{
    margin-left: 154px;
  }
}

.userInfoDetail{
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;

  span{
    margin-left: 170px;
  }
}

.pgp-key {
  margin-bottom: 60px;

  .pgp-hr {
    margin: 0;
    margin-bottom: 30px;
  }

  .tooltip {
    opacity: 1;
    position: static !important;
    margin-left: 6px;
    text-decoration: none;
  }
}

.ecc-warning {
  margin-left: 4px;
  cursor: pointer;
  color: #003791;
}

.pgp-modal-buttons {
  display: flex;
  justify-content: flex-end;

  button:last-child { margin-left: 10px; }
}

.pgp-validation {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  font-size: 12px;
    font-family: 'SSTPro-Roman';
  &.error { color: #b00c12; }
  &.success { color: #00a68e; }
  & .validation-text {
    margin-left: 10px;
  }

  .pucl-icon { margin-top: 3px; }
}

.pgp-key-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  button {
    margin-left: 10px;
  }
}

.pgpKeyAdd {
  text-decoration: none !important;
}

.pgp-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .label {
    display: flex;
    margin-bottom: 10px;
    padding: 0px;
    color: #363636;
    font-size: 14px;
    text-align: left;
    font-family: 'SSTPro-Roman';
  }

  .pucl-btn-link {
    height: 0px;
    padding: 0px;
    border: none;
  }

  .text-area-input {
    min-width: 478px;
    min-height: 136px;
  }

  .management-icons {
    display: flex;

    .download { margin-right: 10px; }

    .pucl-icon {
      margin: 0px;
      font-size: 14px;
    }
  }

  .tooltip-pgp-key-icon {
    color: #616161;
  }
}
