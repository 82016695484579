.order-documents {
  .pucl-collapsible-container {
    .pucl-data-table {
      min-height: 365px;
    }
  }

  .header-cell-wrapper {
    .content {
      padding-left: 0px;
    }
  }

  .pucl-data-table .cell-container.first {
    .row-cell {
      padding-left: 20px;
    }
  }

  .header-cell.remove {
    border-left: none;
  }

  .fileName {
    .fileName-content {
      display: flex;
    }
    .tooltip {
      opacity: 1;
      font-size: 14px;
      line-height:20px;
      text-decoration: none;

      .fileName-text {
        font-size: 14px;
        line-height:20px;
        font-family: 'SSTPro-Medium';
      }
    }

    .virus-scan-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      span.message {

        span#file-name-truncate {
          white-space: pre-wrap;
        }
      }

      .pucl-loader {
        flex-direction: row;
        width: fit-content;

        .pucl-loader-message {
          padding-left: 10px;
        }
      }
    }

    .scan-failed {
      padding-right: 10px;
      
      .tooltip {
        color: #b00c12;
      }
    }

    .file-scan-failed {
      .fileName-text {
        color: #000;
        font-family: 'SSTPro-Roman';
      }
    }
  }

  .shareWithPartner {
    .pucl-select-container {
      width: 70px;
    }
  }

  .remove-cell {
    .row-cell-content {
      justify-content: flex-end;
      padding-right: 20px;
      color: #888;


      &:hover {
        .remove-icon, .remove-text {
          color: #000;
        }
        .disabled {
          color: #888;
        }
      }

      .tooltip {
        opacity: 1;
        font-size: 14px;
        line-height:20px;
        color: #363636;
        text-decoration: none;

        a {
          text-decoration: none;
        }
      }

      .remove-icon.pucl-icon {
        margin-right:10px;
      }
      
      .remove-text-disabled, .disabled {
        color: #888;
      }
    }
  }

  .table-no-results-found {
    padding-top: 60px;
  }
}
