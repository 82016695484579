.permitted-uses-agreement-header {
  height: 70px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-left: -30px;
  padding: 0px 70px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
  position: fixed;
  z-index: 2000;

  .title {
    display: flex;
    align-items: center;

    h3, h6 { margin: 0px; }
  }

  .button {
    text-align: right;
  }

  .divider {
    height: 26px;
    width: 1px;
    margin-left: 20px;
    border-left: 1px solid #E7E7E7;
  }

  .version {
    height: 50%;
    margin-left: 20px;
    align-items: center;
  }
}

.agreement.permitted-uses {
  padding-top: 150px;
  padding-bottom: 35px;
  flex-direction: column;

  .section-label {
    margin-bottom: 30px;
    font-size: 20px;
  }

  .info-container {
    padding: 0px 70px;

    .layout {
      margin: 60px 0px;

      .flex-wrapper {
        display: flex;
        width: 100%;
      }
    }
  }

  .content {
    padding: inherit;

    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .pucl-data-table .header-cell {
    .pucl-checkbox {
      .pucl-input--selection-controls__input input {
        cursor: not-allowed;
      }

      .pucl-label::before {
        background-color: #d2d2d2;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
  .pucl-data-table .cell-container.first .row-cell .cell-preface.has-checkbox.has-expandable {
    margin-right: 0px;
  }
  .pucl-data-table .cell-container.first .row-cell .cell-preface .checkbox~.hide-icon {
    padding: 0 10px;
  }
  .pucl-data-table .row-cell .checkbox {
    margin: 0px;
  }
  .changeHistory .pucl-data-table .cell-container.first .row-cell {
    padding-left: 19px;
  }
  .pucl-collapsible-container .pucl-nested>form {
    background-color: white;
  }
  .pucl-input, .pucl-date {
    label.pucl-label {
      font-weight: initial;
    }

    &:not(:first-child) {
      margin-top: 30px;
    }
  }
  .tooltip-wrapper {
    width: fit-content;
    height: fit-content;
    margin-top: 0px;

    .tooltip {
      display: inline !important;
      font-family: "SSTPro-Roman";
      font-size: 14px;
      line-height: 12px;
      color: #363636;
      z-index: 1 !important;;
    }
  }
}

.WarningModal {
  .modal-dialog {
    margin-top: 200px;
  }
}