.thirdPartyProduct-header {
  padding-top: 40px;
  width: 1370px;

  > .header {
    width: 1370px;

    > .search-header-secondary-nav {
      width: 1370px;

      > div.flex {
        width: 1370px;
      }
    }
  }

  &.thirdPartyCatalog-init-partner-select {
    margin-top: 20px;
  }

  div.flex:nth-child(2) {
    order: 2;
  }

  .partner-select-group-true {
    margin-top: 38px;
  }

  .partner-select-subheader-false {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .partner-select-subheader-true {
    margin-bottom: 16px;
    margin-top: 26px;
  }

  .partner-select-header {
    margin-top: 0 !important;
  }

  .pucl-search__category-select-menu {
    z-index: 2205;
  }

  .partner-select-container {
    margin-top: 10px !important;
  }
}

.thirdPartyProduct-list.layout {
  padding-top: 40px;
  margin-bottom: 65px;
  width: 1370px;
  &.super-admin { padding-top: 100px }

  .tooltip__content {
    z-index: 10;
  }

  .pucl-action-bar {
    z-index: 10;
    label {
      font-weight: initial;
    }
  }
  .search-header-container {
    top: 100px;
  }

  .action-bar-container.left {
    .tooltip{
      display: flex !important;
      position: relative !important;
      opacity: 1;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .pucl-data-table {
    .cell-container {
      &.registrationRegion {
        .row-cell-content {
          display: -webkit-inline-box;
        }
      }
    }
    .cell-container.selected {
      z-index: initial;
    }
    .row-cell {
      overflow-wrap: anywhere;
      .activity {
        .pucl-select__value {
          line-height: 12px;
        }
      }
    }

    .row-cell-content {
      // To keep the download history icon
      // from overlapping with the scrollbar
      &.lastColumn {
        width: 99%;
      }

      a:visited {
        color: #0072ce;
      }
    }

    .lastColumn{
      justify-content: flex-start;
      top: 20;
      .header-cell-wrapper{
        justify-content: flex-start;
        padding-right: 65px;
      }
      // download history tool tip
      // undo margin spacing
      .tooltip {
        margin-left: 0px;
      }
      .download-history-tooltip {
        width: 14px;
        height: 15px;
      }

      .no-order-history {
        width: 240px;
      }

      .download-hisotry-loader {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .tooltip {
      opacity: 1;
      font-family: 'SSTPro-Roman'; // Override Bootstrap tooltip font

      .truncate {
        color: #363636;
        font-size: 14px;
        &.codeNameLink{
          font-size: 14px;
          color: #0089d8;
        }
      }
    }
    .error-tooltip {
      margin-left: -32px;
      margin-right: 20px;

      .tooltip {
        position: initial !important;
        color: #616161;
      }
    }
    a:focus.tooltip, a:hover.tooltip{
      text-decoration: none;
    }

    .content {
      padding: initial;
    }


    .availabilityRegion {
      display: inline;
      width: 100%;
      align-items: flex-end;
      position: static;

      .region-name {
        display: inline-flex;
      }

      .countryTooltipContainer{
        display: inline-flex;
        align-items: flex-end;
        padding-left: 4px;
        z-index: 1;
      }
      .multiCountryTooltip {
        display: flex;
        width: 100%;
        align-items: inherit;
      }
    }
    .table-no-results-found {
      .empty-table-message {
        padding-bottom: 10px;
      }

      .content-pipeline-link {
        text-decoration: none;
      }
    }
  }

  .subtable-wrapper {
    .header-cell.first {
      .header-cell-wrapper {
        margin-left: 55px !important;
      }
    }

    .cell-container.first {
      .row-cell-content {
        margin-left: 55px !important;
      }
    }
  }

  .thirdPartyCatalogPageTableContainer {
    width: 100%;
    .tooltipDefaultStyles {
      a {
        color: #fff;
      }
    }
    .pucl-table-wrapper {
      .expandable-container {
        background-color: #fbfbfb;
        max-height: none;
      }
    }
  }
  .thirdPartyCatalogPagePaginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .pucl-select-container {
      min-width: 80px;
    }

    .page-link {
      text-decoration: none;
    }

    // TODO: remove after bootstrap is extracted
    // used to show arrows on paginator
    .sr-only{
      width: 9px!important;
      height: 12px!important;
      margin: 0;
      position: inherit;
    }
  }

  .loading-vouchers {
    text-align: center;
    font-size: 20px;
  }

  .results-text {
    font-weight:bold;
    padding: 10px 0 20px;
  }
  .thirdPartyProduct-row {
    display: flex;
    margin-top: 13px;
    margin-bottom: 13px;
  }
  .row div {
    padding: 5px 5px 5px 15px;
    min-width: 30px;
    -ms-word-break: break-all;
    word-break: break-all;
  }
  .panel-title a {
    display: inline;
    padding: 10px 0;
  }
  .row div.panel-title {
    padding: 5px 0;
  }
  div {
    &.panel, &.panel-heading {
      padding: 0;
      background: none;
      box-shadow: none;
      border: 0;
      margin-bottom: 0;
    }
  }
  .headers {
    display: flex;
    font-weight: bold;
    border-bottom: 1px solid #000;
    font-size: 12px;
    color: #063A8F;
    & > div {
      cursor: pointer;
      word-break: break-word;
      &.desc::after {
        content: ' \25BC' !important;
      }
      &.asc::after {
        content: ' \25B2' !important;
      }
    }
  }
  .thirdPartyProduct-link, .code-product-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: #0072ce;
    }
  }
  .normal-icon {
    color: black;
  }

  .iconPlacement {
    margin-right: 5px;
    a {
      position: static !important;
    }
  }
  .has-lines span.toggle:before {
    display: inline-block;
  }
  .line-headers {
    background: #DFDFDF;
    color: #A8A8A8;
    td, th {
      padding: 2px 8px !important;
      word-break: break-word;
    }
    th {
      font-weight:normal;
    }
  }

  // TODO: Use cell's classname instead of id on truncate once using PUCL 2.2.1
  #truncate {
    &.tooltip {
      z-index: initial !important;
    }
  }

  .expanded-table {
    z-index: -1 !important;
  }

  .download-button-container{
    margin-right: 25px;
  }

  .download-history-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    min-width: 240px;
    min-height: 50px;

    .download-history-info {
      justify-content: left;
      text-align: left;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .download-history-info-title {
      font-weight: bold;
    }
    .download-history-row {
      display: flex;
    }
    .download-name {
      margin-bottom: 10px;
    }
    .expiration-message {
      margin-top: 10px;
    }
    .download-name,
    .last-downloaded,
    .last-downloaded-by,
    .order-id,
    .batch-id,
    .start-end-date {
      display: flex;
      flex-direction: column;
    }

    .last-downloaded,
    .last-downloaded-by,
    .order-id,
    .batch-id {
      width: 50%;
    }
  }
}

.ThirdPartyWarningModalConfirmButton{
  margin-left: 15px;
}

.fade.modal-backdrop {
  z-index: 2250;
}

.fade.warning-modal.in.modal {
  z-index: 2251;

  .modal-dialog {
    margin-top: 90px;
  }

  .WarningButton.yes {
    width: 105px;
    margin-left: 15px;
  }
}

@media (min-width: 1200px){
  .container {
    width: 1400px;
  }
}

.quantity-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  .tooltip-wrapper {
    position: absolute;
    right: -50px;
  }
}

.tooltip-wrapper {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-left: 10px;

  .tooltip {
    opacity: 1;
    color: #636365;
  }

  .alert-icon {
    color: #c5474b;
  }
}