.partners-page {
  padding-top: 40px;

  .partners-list {
    margin-top: 40px;
    margin-bottom: 65px;
  }

  .pucl-tag .tag-icon {
    width: 14px !important;
  }

  .pucl-data-table {
    .header-cell-wrapper {
      .content {
        padding-left: 0px;
      }
    }

    .cell-container {
      &.first-column {
        .row-cell {
          margin-left: 20px !important;

          a {
            text-decoration: none;
          }

          a:active {
            color: #0072ce;
          }

          a:visited {
            color: #0072ce;
          }
        }
      }

      &.status {
        .row-cell-content {
          .pucl-tag {

            &.valid {
              .tag-icon {
                background-color: #2d9e94;
              }
             }

             &.inactive {
              .tag-icon {
                background-color: #767676;
              }
             }
           }
        }
      }
    }
  }

  .partners-paginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    .pucl-select-container {
      min-width: 80px;
    }

    .page-link {
      text-decoration: none;
    }

    .sie-select__field {
      .sie-select-arrow {
        color: #999999;
      }
    }

    .sr-only {
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit;
    }
  }
}