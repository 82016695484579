.primary-row {
  display: grid;
  background-color: #F3F3F3;
  .tooltip {
    opacity: 1;
  }
  .pucl-icon {
    font-size: 16px;
  }

  .primary-header {
    padding: 10px 20px;
    border: 1px solid #cacaca;
    white-space: wrap;
    word-break: break-word;
  }

  .cell-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    &.codeName .content{
      display: flex;
      align-items: center;
      padding: unset;
    }
    .truncate{
      word-break: break-all;
      color: #363636;
      font-size: 14px;
      &.codeNameLink {
        color: #0089d8;
      }
    }
  }
  
  .codeName {
    a:visited {
      color: #0072ce;
    }
  }
  .activity {
    display: flex;
    padding-right: 10px;
    .pucl-input.pucl-input-wrapper {
      width: 190px;
    }
  }
  .quantity {
    padding-right: 10px;
    padding-left: 10px;
    .pucl-input .text-input-row-flex-wrapper {
      padding-top: 0;
    }
    .quantity-input-wrapper {
      .tooltip-wrapper {
        right: -24px;
      }
    }
  }
  .useCount {
    display: flex;
    padding-right: 10px;
    .pucl-input .text-input-row-flex-wrapper {
      padding-top: 0;
    }
  }
  .orderFee {
    display: flex;
  }
  .redemptionFee {
    display: flex;
  }
}

.tooltipDefaultStyles.useCount{
  width: 200px;
}

.tooltipDefaultStyles.partnerComments {
  max-width: 500px;
}