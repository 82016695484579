.confirmation {
  width: 100%;
  height: 100%;
  position: relative;

  .header {
    top: 60px;
    width: calc(100% + 60px);
    height: 60px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 70px;
    position: fixed;
    left: -30px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }

  .body {
    padding-bottom: 40px;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      @media only screen and (max-width: 900px) {
        flex-direction: column;
        padding: 0px 40px;
      }

      .left-content {
        .image {
          width: 240px;
          height: 240px;
          
        
          @media only screen and (max-width: 900px) {
            width: 120px;
            height: 120px;
          }
        }
      }

      .right-content {
        .title {
          font-size: 36px;
          font-weight: 300;

          @media only screen and (max-width: 900px) {
            text-align: center;
          }
        }
        .description {
          margin-top: 24px;
          font-size: 20px;
          font-weight: 300;
          white-space: pre-wrap;
          max-width: 400px;
        }
        .button-container {
            margin-top: 24px;

            @media only screen and (max-width: 900px) {
              text-align: center;
            }

            .confirmation-button {
              @media only screen and (max-width: 900px) {
                width: 148px;
              } 
            }
          }
      }
    }
  }
}
