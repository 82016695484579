.filters-container {
  display: flex;
  z-index: 5000;

  .filter {
    min-width: 150px;
    max-width: 315px;
    padding-left: 5px;
    padding-right: 5px;

    &:first-child{
      width: 315px;
    }

    &.region {
      width: 235px;
    }

    &.platform,
    &.version {
      width: 185px;
    }

    &.status {
      width: 265px;
    }

    &.releaseDate,
    &.creationDate {
      width: fit-content;
    }

    .pucl-date-range {
      .pucl-input-field-container {
        width: 230px !important;
      }
    }

    &.agreementType {
      width: 240px;
    }

    &.country {
      width: 225px;
    }

    &.voucherProductType {
      width: 175px;
    }
  }
}
