.error-page {
  padding-top: 200px;

  .canceled-tos-content {
    .layout {
      flex-direction: column;
      align-items: center;

      .astro-sitting-playing-360 {
        width: 300px;
        margin-top: 100px;
      }
    }
  }

  .error-page-layout {
    flex-direction: column;
  }
}
