.textarea-container {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  position: relative;
  font-size: 14px;
  color: #363636;

  .textarea-label {
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;

    .star {
      margin-right: 5px;
      color: #EF5A75;
    }
  }

  .textarea-scroll-container {
    border: 1px solid #D2D2D2;
    border-radius: 2.5px;
    &[disabled] {
      background: #EEEEEE;
      border-color: #DBDBDB;
      color: #767676;
      pointer-events: none;
    }
    &:focus-within { border: 1px solid #9ABFD3 }
  }

  .textarea-placeholder {
    position: absolute;
    left: 13px;
    z-index: 2;
    color: #999999;
  }

  .textarea {
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    padding: 6px 12px;
    outline: none;
    &[disabled] { background: transparent; }
  }
  .toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .spacer { width: 10px; }
  
    .section {
      display: flex;
      flex: 1;
      &.right { justify-content: flex-end; }
  
      .management-icons {
        .pgp-delete-key,
        .download {
          z-index: 1;
        }
      }
    }
  }
}

// .toolbar {
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   .spacer { width: 10px; }

//   .section {
//     display: flex;
//     flex: 1;
//     &.right { justify-content: flex-end; }

//     .management-icons {
//       .pgp-delete-key,
//       .download {
//         z-index: 1;
//       }
//     }
//   }
// }
