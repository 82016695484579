
#globalHeader {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 12108;
}

.global-header {
  top: 0;
  margin-bottom: 0;
  border-radius: none;
  .partners-logo {
    margin-top: -5px;
    width: 236px;
    height: auto;
  }
}
.venom-header {
  z-index: 2150;
  padding-top: 60px;
  margin-left: -30px;
  margin-right: -30px;
  position: relative;

  .pucl-local-navigation {
    position: fixed;
    font-family: 'SSTPro-Medium';

    .dropdown {
      label {
        font-weight: inherit;
      }
      ul {
        border: none;
        margin: 0px;
        padding: 0px;
      }
    }
  }

  li {
    span {
      text-decoration: none;
    }
  }
  .dropdown-menu {
    z-index: 2150;
  }

  @media(max-width: 1199px) {
    top: -20px;
  }
}

.venom-header-disabled-orders {
  z-index: 2150;
  padding-top: 60px;
  margin-left: -30px;
  margin-right: -30px;
  position: relative;

  .pucl-local-navigation {
    position: fixed;
    font-family: 'SSTPro-Medium';

    .dropdown {
      label {
        font-weight: inherit;
      }
      ul {
        border: none;
        margin: 0px;
        padding: 0px;
      }
    }
  }

  li {
    span {
      text-decoration: none;
    }
  }
  .dropdown-menu {
    z-index: 2150;
  }
  .pucl-local-navigation > .dropdown:first-child {
    cursor: default;
    pointer-events: none;
  }
  @media(max-width: 1199px) {
    top: -20px;
  }
}
.navbar-default {
  background-color: #000;
  background-image: none;
  border: none;
  .navbar-nav > li > a {
    text-shadow: none;
    &:focus {
      color: #777;
    }

  }
  .navbar-nav > .active {
    > a {
      background-image: none;
      background-color: #000;
      color: #fff;
      &:hover {
        background-image: none;
        background-color: #000;
        color: #fff;
      }
    }
  }
  .navbar-nav > {
    li > a:hover {
      color: #fff;
    }
    .active > a {
      color: #fff;
      background: #000;
      &:focus {
        color: #fff;
        background: #000;
      }
    }
  }

  .navbar-nav {
    li.disabled {
      a {
        color:#777;
      }
    }
  }
}

.help-link {
  color: #fff;
  font-size: 30px;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
}

.navbar-nav > li {
  padding: 0 20px;
}
