.need-pgp-setup-page {
  padding-top: 41px;
  a {
    text-decoration: none;
  }

  .header {
    width: calc(100% + 60px);
    height: 60px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 70px 12px 60px;
    position: fixed;
    left: -30px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);

    .title {
      margin-bottom: 20px;
    }

    .spacer { width: 16px; }

    .buttons {
      display: flex;
    }
  }

  .need-pgp-setup-page-content {
    padding-top: 100px;

    .pucl-label {
      font-weight: 500;
      text-transform: none !important;
    }
  }
}
