.agreements-page {
  padding-top: 40px;

  .agreements-list {
    margin-top: 40px;
    margin-bottom: 65px;
  }

  .tooltip {
    opacity: 1 !important;
    z-index: unset !important;
    font-size: 14px !important;
  }

  .action-bar-container {
    &.right {
      .pucl-btn,
      .pucl-btn .pucl-icon {
        background-color: #0072ce !important;
      }

      .pucl-btn {
        padding-right: 20px !important;

        .button-text {
          color: #ffffff !important;
        }
      }
    }

    .tooltip {
      &:focus, &:hover {
        text-decoration: none;
      }
    }
  }

  .filters {
    .filters-container {
      flex-wrap: wrap;

      .region {
        min-width: 305px;
      }

      .agreementType {
        min-width: 310px;
      }

      .status {
        min-width: 305px;
      }

      .country {
        min-width: 305px;
      }

      .voucherProductType {
        padding-top: 10px;
        min-width: 305px;
      }
    }
  }

  .pucl-data-table {
    .header-cell-wrapper {
      .content {
        padding: 0px;
      }
    }

    .cell-container {
      &.first-column {
        .row-cell {
          margin-left: 20px !important;

          a {
            text-decoration: none;
          }

          a:visited {
            color: #0072ce;
          }
        }
      }

      &.partner-column {
        a {
          text-decoration: none;
        }

        a:visited {
          color: #0072ce;
        }
      }

      &.status-column {
        .row-cell-content {
          .pucl-tag-icon-only {
            margin-right: 5px;

            &.valid {
              background-color: #2d9e94;
             }

             &.inactive {
              background-color: #767676;
             }

             &.warning {
              background-color: #d99c34;
             }

             &.error {
              background-color: #c5474b;
             }
           }
        }
      }
    }
  }

  .agreements-paginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .pucl-select-container {
      min-width: 80px;
    }
  
    .page-link {
      text-decoration: none;
    }
  
    .sie-select__field{
      .sie-select-arrow{
        color: #999999;
      }
    }
  
    .sr-only{
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit;
    }
  }
}