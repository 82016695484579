.free-allocation-agreement-header {
  height: 70px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-left: -30px;
  padding: 0px 70px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
  position: fixed;
  z-index: 2000;

  .title {
    display: flex;
    align-items: center;

    h3 { margin: 0px; }
  }

  .divider {
    height: 26px;
    width: 1px;
    margin-left: 20px;
    border-left: 1px solid #E7E7E7;
  }

  .version {
    height: 50%;
    margin-left: 20px;
    align-items: center;
  }
}

.agreement.free-allocation {
  padding-top: 150px;
  padding-bottom: 35px;
  flex-direction: column;

  &.container,
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .content {
    padding: inherit;
  }

  & > .layout {
    margin: 0px auto;
    width: -moz-fit-content;
    width: fit-content;

    .content-details {
      margin: 60px;
    }
  }

  .pucl-data-table .header-cell {
    .pucl-checkbox {
      .pucl-input--selection-controls__input input {
        cursor: not-allowed;
      }

      .pucl-label::before {
        background-color: #d2d2d2;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
  .pucl-data-table .cell-container.first .row-cell .cell-preface.has-checkbox.has-expandable {
    margin-right: 0px;
  }
  .pucl-data-table .cell-container.first .row-cell .cell-preface .checkbox~.hide-icon {
    padding: 0 10px;
  }
  .changeHistory .pucl-data-table .cell-container.first .row-cell {
    padding-left: 19px;
  }
  .pucl-collapsible-container .pucl-nested>form {
    background-color: white;
  }
  .pucl-input, .pucl-date {
    label.pucl-label {
      font-weight: initial;
    }

    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}

#free-allocation-warning-modal .body {
  padding-right: 0px !important;
  padding-bottom: 0px !important;

  .modal-body-content {
    .warning-modal-intro {
      padding-right: 30px;
    }

    .scroll-content {
      .overlay {
        padding-right: 10px;
      }
    }

    .orderlines-table {
      width: 100%;

      .tableCell {
        &:first-child { min-width: 70px; }
        &:last-child { min-width: 135px; }
      }
    }
  }
}
