.cart-header {
  background: rgb(255, 255, 255);
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 100px;
  left: 0px;
  margin: 0px;
  width: 100%;
  padding: 5px 45px;
  z-index: 2002;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;

  .container {
    .cart-header-layout {
      width: 1370px;
      justify-content: space-between;
    }
  }

  @media(max-width: 1199px) {
    top: 80px;
  }

  a:hover {
    text-decoration: none;
  }

  h3 {
    margin-top: 5px;
  }

  .button-toolbar {
    margin-top: 4px;
    text-align: right;
  }

  .back-to-product {
    padding-right: 10px;
  }
}

.cart-note {
  padding-top: 180px;
  color: #636365;
  margin: 0 auto;
}

.pucl-data-table .cell-container .row-cell {
  overflow-wrap: anywhere;
}

.cart {
  margin: 0 auto;
  width: 1370px;
  .pucl-data-table .content {
    padding: initial;
    white-space: initial !important;
  }
  padding-top: 20px;
  a:hover {
    text-decoration: none;
  }
  .cart-content {
    width: 100%;
    padding-bottom: 65px;

    .tooltip__content {
      z-index: 10;
    }

    .pucl-table-wrapper {
      .cell-container {
        &.registrationRegion {
          .row-cell-content {
            display: -webkit-inline-box;
          }
        }
      }
      .cell-container.selected {
        z-index: initial;
      }

      .cart-row-product-name {
        .tooltip {
          opacity: 1;
          .truncate {
            .code-product-link {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }

      .expandable-container {
        background-color: #fbfbfb;
        max-height: none;

        .po-number-input-wrapper {
          .pucl-input.pucl-input-wrapper {
            padding-bottom: 0px;
          }
        }
      }
    }

    .cell-container {
      .trash-icon-wrap {
        width: 100%;
        i {
          float: right;
          color: #616161
        }
      }
    }

    .code-product-link {
      color: #0072ce;
    }

    .quantity-input-wrapper {
      display: flex;
      align-items: center;
      position: relative;

      .tooltip-wrapper {
        position: absolute;
        right: -25px;
      }
    }

    .tooltip-wrapper {
      width: 16px;
      height: 16px;
      margin-top: 2px;
      margin-left: 10px;

      .tooltip {
        opacity: 1;
        color: #636365;
      }

      .alert-icon {
        color: #c5474b;
      }
    }

    .time-zone-field {
      padding-top: 10px;
    }

    .pucl-date {
      .pucl-input-field-container {
        min-width: 147px;
      }
    }

    .pucl-select-container {
      min-width: 96px;
    }
  }

  .calendar-container {
    z-index: 1;
  }

  .cartPagePaginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .pucl-select-container {
      min-width: 80px;
    }

    .page-link {
      text-decoration: none;
    }

    .sie-select__field{
      .sie-select-arrow{
        color: #999999;
      }
    }

    .sr-only{
      width: 7.5px;
      height: 12px;
      margin: 0;
      position: inherit;
    }
  }
}

.custom-modal-footer {
  width: 900px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #E7E7E7;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 30px;
  margin-left: -31px;
  .buttons-toolbar {
    display: contents;
  }
}

.custom-modal-body {
  white-space: pre-wrap;
  text-align: justify;
}
