$status-spacing: 6px;
.sftp-status {
  text-transform: capitalize;
  span:first-child {
    display: inline-block;
    border-radius: 100px;
    border: 1px solid #000;
    width: 12px;
    height: 12px;
    position: relative;
    vertical-align: baseline;
  }
  &.ACTIVE span:first-child {
    background: green;
    border-color: green;
    color: #fff;
    font-size: 11px;
    margin-right: $status-spacing;
    &::before {
      margin-left: -1px;
    }
  }
  &.INACTIVE span:first-child {
    background: red;
    border-color: red;
    color: #fff;
    font-size: 11px;
    margin-right: $status-spacing;
    &::before {
      margin-left: -1px;
    }
  }
  &.DELETED span:first-child {
    background: #999;
    border-color: #999;
    color: #fff;
    font-size: 11px;
    margin-right: $status-spacing;
    &::before {
      margin-left: -1px;
    }
  }
}

.partner-detail-sftp-grid {
  width: 100%;
  .partner-detail-sftp-row {
    .partner-detail-sftp-col {
      padding: 0px;
      .pucl-loader {
        margin-top: 100px !important;
      }
      div {
        .pucl-data-table {
          .header-cell.first {
            .content {
              padding-left: 0px;
            }
          }
          .header-cell:not(.first) {
            .content {
              padding-left: 0px;
            }
          }
          .cell-container.first {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

