.voucher-batch {
  padding-top: 140px;

  .tooltip {
    opacity: 1;
    text-decoration: none;
  }

  .voucher-batch-detail-page {
    >.flex.voucher-batch-detail-page-loading {
      display: flex;
      justify-content: center;
    }
  }

  .voucher-batch-header {
    background: rgb(255, 255, 255);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100px;
    left: 0px;
    margin: 0px;
    width: 100%;
    height: 59px;
    padding: 5px 45px;
    z-index: 2002;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;

    .voucher-batch-header-content {
      width: 1273px;
      display: flex;

      .voucher-batch-header-left {
        >.title {
          margin-top: 10px;
        }
      }

      .voucher-batch-header-right {
        display: flex;
        align-items: center;
        justify-content: end;

        .cancel-button {
          margin-right: 10px;
        }
      }

    }
  }

  .voucher-batch-info-section-wrapper {
    .pucl-nested {
      padding-bottom: 250px;
    }

    .voucher-batch-details-top {
      padding-top: 30px;
      padding-left: 96px;

      .voucher-type-id-label,
      .voucher-batch-status {
        font-size: 16px;
        font-weight: 600;
      }

      .voucher-type-id {
        font-size: 16px;
        font-weight: 600;
        padding-left: 5px;
        color: #0072ce;
      }

      .voucher-type-id-edit-button {
        font-size: 14px;
        font-weight: 600;
        padding-left: 5px;
        color: #0072ce;
      }
    }

    .voucher-batch-details-top {
      .voucher-id {
        padding-right: 10px;
      }
    }

    .voucher-batch-details {
      padding-top: 30px;

      .layout.voucher-batch-details-layout {
        justify-content: center;

        .voucher-batch-form-group {
          padding-top: 20px;

          &.voucher-batch-details-form-field {
            > .pucl-input.pucl-input-wrapper.pucl-text-area {
              width: 100% !important;

              .text-area-input {
                width: 100%;
              }
            }

          } 
        }
      }
    }

    .copy-icon {
      >i.pucl-icon.ico.ico-copy {
        cursor: pointer;
        padding-left: 5px;
      }

      .ico-copy:before {
        font-size: 18px;
      }
    }

    .voucher-batch-details-form {
      hr {
        margin: 30px 65px 30px 65px;
        border-color: #bdbdbd;
      }

      .download-section-container {
        padding-left: 95px;

        .button-container-col {
          display: flex;
          align-items: start;
          flex-direction: column;
          .pucl-progress-indicator-container {
            padding-top: 80px;
          }
          .button-group {
            padding-top: 30px;
          }
          .button {
            padding-top: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }

  .date-country-section-container-wrap {
    height: 400px;

    .container.date-country-section-container {
      padding-top: 27px;

      .voucher-batch-header-desc {
        padding: 10px 0px 10px 0px;
      }
  
      .layout.sku-country-availability-container {
        padding: 0 35px;
  
        .check-button-container {
          padding-top: 20px;
        }
      }
  
      hr {
        margin: 10px;
        border-color: #bdbdbd;
      }
  
      .voucher-batch-time-zone-radio-container {
        padding-top: 35px;
      }

      .voucher-batch-date-time {
        display: flex;

        .voucher-batch-date-input {
          padding-right: 10px;
        }

        .voucher-batch-time-input {
          padding-top: 23px;

          .pucl-time-input.t12hr {
            div:nth-child(2) {
              padding-top: 5px;
            }
          }
        }
      }
    }
  }

  // Batch History
  .batch-audit-history {
    .header-cell.Date.first {
      .header-cell-wrapper {
        margin-left: 10px;
      }
    }

    .header-cell-wrapper {
      .content {
        padding-left: 10px;
      }
    }

    .cell-container.cell {
      .row-cell {
        padding-left: 20px;
      }
    }
  }
}

.cancel-batch-modal {
  .pucl-radio-group.required::after {
    display: none;
  }

  .warning-message {
    padding-top: 38px;
  }
}
