.user-info {
  .pucl-divider {
    border: none;
    background: #e7e7e7;
  }

  .pucl-input:not(:last-child) {
    margin-bottom: 10px;
  }

  .error {
    color: #c30000;
  }

  .success {
    color: #00a700;
  }

  .instructions {
    margin-bottom: 50px;
    margin-top: 30px;
  }
}

.pgp-key-modal {
  .modal-dialog {
    margin-top: 171px;
  }
}

.downloadIcon {
  color: #003791;
  cursor: pointer;
}

.trashIcon {
  margin-left: 15px;
  color: #003791;
  cursor: pointer;
}
