.po-number {
  .po-number-input-wrapper {
    input {
      width: 137px;
    }
  }
  &.cell-container{
    border-bottom: unset;
  } 

  // File Icon
  .file_upload, .file-download-icon, .insert_drive_file {
    color: #616161;
    text-decoration: none;
  }

  .file_upload:hover, .file-download-icon:hover, .insert_drive_file:hover {
    color: #006db4;
  }

  .po-upload-hidden .file-download-upload-label {
    margin-left: 10px;

    .file-download-icon {
      padding-top: 8px;
    }

  }

  .po-upload {
    min-width: 33px;
    min-height: 24px;
    display: flex;
    
    .file-download-upload-label {
      padding-left: 5px;
    }

    .tooltip {
      opacity: 1;
    }
  }

  .row-cell .row-cell-content .po-upload-hidden .po-pending {
    margin-left: 5px;
    .tooltip {
      top: 3px;
    }
  }

  .row-cell .row-cell-content .po-upload-hidden .po-error-icon {
    margin-left: 1px;
  }

  #error-po {
    color:  #C5474B;
  }

  .po-upload > label {
    margin-left: 10px;
    margin-bottom: 0px;
    color: #0072ce;
    cursor: pointer;
    width: 100%;
    height: 100%;
    input {
      display: none;
    }
  }

  .ico-alert {
    padding-left: 5px;
  }
}


.pucl-data-table .po-number.cell-container .row-cell {
  min-height: 40px;
  height: unset;
}