.order {
  .tooltip {
    opacity: 1;
    text-decoration: none;
  }

  .order-header {
    height: 70px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-left: -30px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
    position: fixed;
    z-index: 2000;
  
    .container {
      padding-left: 0px;
      padding-right: 0px;
      max-width: 1270px;
    }
  
    @media (max-width: 1199px) {
      margin-top: 20px;
    }
  
    .order-title {
      display: flex;
      align-items: center;
      font-size: 26px;
  
      h3 { margin: 0px; }
    }
  }

  .order-sections {
    padding-top: 140px;

    .order-info-section {
      .order-info-free-allocation-content {
        padding: 50px;
  
        .item-title {
          line-height: 18px;
          font-weight: 700;
          font-size: 14px;
          color: #363636;
          padding-bottom: 15px;
        }
  
        .item-value {
          a {
            text-decoration: none;
          }
          
          a:visited {
            color: #0072CE;
          }
        }
      }
    }
  
    .order-contents-section {
      .header-cell {
        .header-cell-wrapper {
          .content {
            padding-left: 0px;
          }
        }
    
        &.codeProduct.first {
          .header-cell-wrapper {
            margin-left: 10px;
          }
        }

        &.country {
          .header-cell-wrapper {
            .content {
              word-break: break-all;
              white-space: normal;
            }
          }
        }
      }

      .cell-container {
        &.order-line-3p.codeProductName.first {
          .row-cell-content {
            padding-left: 10px;
    
            a:hover {
              text-decoration: none;
            }
    
            a:visited {
              text-decoration: none;
              color: #0072CE;
            }
          }
        }

        &.order-line-3p.fulfillment {
          .row-cell-content {
            .pucl-loader {
              width: fit-content;
              padding: 6px 10px 0px 10px;
            }
          }
        }
      }
    }
  }
}
