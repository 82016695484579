.catalog {
  padding-top: 40px;
  .header-cell .ico-select-arrow {
    opacity: 0;
    &.selected {
      opacity: 100;
    }
  }
  .header-cell-wrapper .content {
    padding-left: 0px;
  }
  .indicator-container {
    width: fit-content;
    margin: 150px auto;
  }

  .catalog-nav {
    background: rgb(255, 255, 255);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100px;
    left: 0px;
    margin: 0px;
    width: 100%;
    padding: 5px 45px;
    z-index: 2002;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;

    .catalog-nav-content {
      display: inline-flex;
      min-width: 1270px;
      
      .catalog-title {
        padding-left: 10px;
      }

      .return-nav-button {
        margin: 10px;
      }
      .return-nav-button {
        margin: 10px;
      }
    }
  }

  .pucl-table-wrapper {
    margin-top: 130px;
    margin-bottom: 50px;
    .pucl-action-bar {
      z-index: inherit;

      .filters {
        .filter {
          width: 300px;
        }
      }

      .action-bar-filter-button-container {
        .tooltip {
          opacity: 1;
        }
        .tooltip:hover {
          text-decoration:none;
        }
      }
    }

    .row-cell.checkbox {
      margin-top: 0px;
    }
  }

  .no-vouchers {
    text-align: center;
  }
  .code-products-paginator {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 45px;
    margin-bottom: 145px;
    display: flex;
    justify-content: space-between;
    .pucl-select-container {
      min-width: 80px;
    }
  
    .page-link {
      text-decoration: none;
    }
    .sr-only{
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit;
    }
  
    .sie-select__field{
      .sie-select-arrow{
        color: #999999;
      }
    }
  }
}

