.tos {
  width: 100%;
  position: relative;

  .header {
    top: 60px;
    width: calc(100% + 60px);
    height: 60px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 70px;
    position: fixed;
    left: -30px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);

    .title {
      margin-bottom: 20px;
    }

    .spacer { width: 16px; }

    .buttons {
      display: flex;
    }
  }

  .body {
    padding: 0px 240px;
    padding-bottom: 40px;
    flex-direction: column;
    .title {
      margin-bottom: 50px;
      font-size: 30px;
    }

    h3 {
      margin-top: 0px;
    }

    .terms {
      white-space: pre-wrap;
      text-align: justify;
    }
  }
}
