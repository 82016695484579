@import '../../../baseStyles/_colors.scss';

$icon-size: 18px;
$icon-color: $UI-Control;
$disabled-primary-button: $InputDefault;

.permitted-uses-order-nav {
  height: 70px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-left: -30px;
  padding: 0px 70px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
  position: fixed;
  z-index: 2000;

  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media (max-width: 1199px) {
    margin-top: 20px;
  }

  .order-title {
    display: flex;
    align-items: center;
    font-size: 26px;

    h3 { margin: 0px; }
  }

  .divider {
    height: 26px;
    width: 1px;
    margin-left: 20px;
    border-left: 1px solid #E7E7E7;
  }

  .status {
    height: 50%;
    margin-top: 2px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;

    .status-label {
      font-family: 'SSTPro-Light';
    }

    .value {
      margin-left: 6px;
      font-family: 'SSTPro-Medium';

      > div {
        display: inline;
      }

      #current-comment {
        font-weight: initial;
      }

      .comment-icon {
        margin-left: 5px;
        position: relative;

        #message-icon-clickbox {
          cursor: pointer;

          .pucl-icon {
            vertical-align: middle;
          }
        }

        .comment-message-container {
          position: absolute;
          top: calc(100% + 6px);
        }
      }
    }
  }

  .buttons {
    &.save {
      text-align: right;
    }

    &.approve {
      display: flex;
      justify-content: right;

      & > button, .action-button {
        margin-right: 15px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .action-button {
    position: relative;

    .message-board-container {
      position: absolute;
      top: calc(100% + 6px);
      &.amend { left: -40%; }
      &.reject { left: -120%; }
    }
  }

  .pucl-message-board {
    .close {
      font-size: 12px;
    }
  }
}

.permitted-uses-order {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px;

  .link {
    text-decoration: none;
    &:visited {
      color: #0072CE;
    }
  }

  .info-container {
    padding: 0px 70px;

    .layout {
      margin: 60px 0px;

      .flex-wrapper {
        display: flex;
        width: 100%;
      }
    }

    .static-text {
      .label { font-weight: 500; }
    }

    .po-number {
      display: flex;
      align-items: center;
      justify-content: start;
      width: 70%;

      .pucl-input .pucl-label + .pucl-form-input {
        z-index: 0;
      }

      .pucl-input.pucl-input-wrapper {
        .pucl-label {
          font-weight: 500;
        }
        .pucl-input-container {
          width: 100%;
        }
        .pucl-input-field-container {
          width: 100%;
        }
      }


      .po-download-icon, .po-error-icon, .po-pending {
        margin-top: 30px;
        .tooltip {
          margin-left: 15px;
          opacity: 1;
          z-index: unset !important;

          #download-po, #error-po {
            /* margin and font-size applied here because material-icon styles currently overwrite pucl size prop */
            margin-top: 4px;
            font-size: $icon-size;
            color: $icon-color;
          }
          #error-po {
            color:  #C5474B;
          }
        }
      }
    }

    .order-comments {
      margin-top: 30px;
      .pucl-label {
        font-weight: 500;
      }
    }

    .pucl-checkbox {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    .disc-substitute {
      display: flex;
      align-items: baseline;
      width: 15%;
      margin-bottom: 30px;
      .currency-value {
        margin-left: 10px;
      }
      .per-code-fee-label {
        margin-left: 10px;
        white-space: nowrap;
      }
    }

    .pucl-input--selection-controls__input .pucl-label {
      font-weight: 500;
    }
  }
}

.third-party-order-contents {
  white-space: nowrap;

  .isDisabledSwap {
    .pucl-collapsible-container {
      .pucl-collapsible {
        .pucl-rightContent {
          display: none;
          button {
            color: $disabled-primary-button;
            border-color: $disabled-primary-button;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }
    }
  }

  .tooltip-container {
    height: 100%;
    width: 65px;
    display: flex;
    align-items: center;
  }

  .tooltip {
    opacity: 1;
    display: inline !important;
    margin-top: 2px;
    font-size: 14px;
    z-index: unset !important;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
  

  .header-cell-wrapper {
    .use-count{
      width: 220px;
    }
    .content {
      padding: 0px;
    }
    .info-icon {
      color: $icon-color;
      margin-left: 10px;
    }
  }

  .order-line-cell.first .row-cell-content {
    padding-left: 20px;
  }

  .order-line-cell {
    &.autocanceled {
      background: #eee;
      color: #aaa;

      a { color: #aaa; }
    }
    &.first-cell {
      .cell-preface.has-checkbox {
        margin-right: 0px;
        .checkbox {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }

      .tooltip {
        right: 20px;
      }
    }

    .warning-icon {
      color: red;
    }

    .iconPlacement {
      margin-right: 10px;
      display: flex;
      a {
        position: static !important;
      }
    }

    .batch-filename-copy {
      margin-left: 20px;
      margin-right: 15px;
      height: 100%;
      display: flex;
      align-items: center;

      #copy-file-name {
        color: $icon-color;
        &::before { font-size: 13px; }
      }
    }

    .pucl-date {
      min-width: 120px;
    }

    .time-input {
      margin-left: 10px;
      width: 126px;
    }

    .date-tooltip-wrapper {
      width: 20px;
      margin-left: 10px;
      z-index: 10;

      // tooltip renders too low, no amount of html adjustment seems to fix the issue
      #start-date-error.tooltip__content,
      #end-date-error.tooltip__content {
        bottom: 17px !important;
      }

      .pucl-icon {
        color: #b00c12;
      }
    }

    &#startDate, &#endDate {
      .tooltip {
        position: relative !important;
        z-index: unset !important;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .render-status {
      display: flex;

      .pucl-loader {
        margin-top: 3px;
        margin-left: 6px;
      }
    }
  }

  .kabob__menu {
    .kabob__icon {
      font-size: 15px !important;
    }
  }
}

.third-party-order-history {
  .header-cell {
    .content {
      padding-left: 0px;
    }
  }

  .cell-container {
    &.first {
      padding-left: 20px;
    }
    .status-icon {
      display: inline-block;
      padding: 0px 10px;

      &.rotate {
        transform: rotate(180deg);
      }
    }
    .status-wrap {
      margin-top: -20px;
      a:hover, a:focus {
        text-decoration: none;
      }
      .tooltip {
        opacity: 1;
        .truncate {
          width: 362px;
          line-height: 20px;
          font-size: 14px;
          font-family: 'SSTPro-Roman';
          color: #363636;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .order-history-comment-tooltip {
          max-width: 300px;
        }
      }
    }
  }
}
