.sku-info-section-container {
  max-width: 1261px;
  margin: 23px auto 46px;
  padding: 25px 0;

  .sku-info-section-header {
    border-bottom: 1px solid #ccc;
    margin-left: 0;
    margin-right: 0;
    padding: 0 40px 10px;

    .sku-info-section-header-label {
      font-size: 19px;
      line-height: 23px;
      color: #858383;
    }

    .new-sku-btn-col {
      margin-top: -5px;

      button.new-sku-btn {
        font-size: 1.2em;
        color: #063A8F;

        span {
          border-radius: 100px;
          background: rgb(6, 58, 143);
          width: 23px;
          color: rgb(255, 255, 255);
          display: inline-block;
          margin-right: 10px;
          text-align: center;
        }
      }
    }
  }


  .sku-info-section-skus-table {
    padding: 30px 40px 10px;
    .container {
      width: auto;
    }

    .sku-info-table-header, .sku-info-table-row {
      border-bottom: 1px solid #ccc;
    }

    .sku-info-table-row {
      padding: 15px 0;



      &.header {
        padding: 0 0 5px 0;
        font-weight: bold;

        .sku-info-table-sku-id {
          padding-left: 35px;
          font-weight: bold;
        }
      }

      &.no-children, &.child {
        .sku-info-table-sku-id {
          .expanding-icon {
            visibility: hidden;
          }
        }
      }

      .sku-info-table-sku-id {
        height: 20px;
        font-weight: bold;

        .sku-id-value {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 334px;
          display: inline-block;

          .ellipsis {
            display: block;
            position: absolute;
            top: 0;
            right: 20px;
            width: 20px;
            height: 20px;
            z-index: 1;
            cursor: pointer;
          }
        }

        .tooltip {
          opacity: 1;
        }
      }

      .expanding-icon {
        cursor: pointer;
        top: -3px;
        margin-right: 5px;
      }

      .actions-column {
        white-space: nowrap;

        .remove-icon-wrapper {
          cursor: pointer;

          .remove-icon {
            padding-right: 5px;
            height: 16px;
          }
        }
      }
    }
  }

  .no-sku-text-container {
    text-align: center;
    padding: 50px 0;

    .no-sku-text {
      color: grey;
      > span {
        display: block;
      }
    }
  }
}

.sku-info-section-sku-id-tooltip {
  &.top {
    .tooltip-arrow {
      border-top-color: #767676;
    }
  }
  .tooltip-inner {
    background-color: #767676;
    color: #ffffff;
    word-break: break-all;
  }
}

.sku-info-section {
  .country.first.subtable, .header-cell.country, .header-cell.status, .header-cell.start-date.subtable, .header-cell.end-date.subtable {
    .content {
      padding: 0;
    }
  }
  .row-cell-content {
    .ico-copy {
      margin-left: 5px;
    }
    .ico-trashcan {
      margin-right: 5px;
    }
  } 

  .cell-container.sku-info-table-sku-id.first {
    #skuId-truncate{
      cursor: default;
    }
    .tooltip {
      opacity: 1;
    }
  }
}

.sku-info-table-sku-id-link {
  a {
    font-size: 14px;
    font-family: 'SSTPro-Roman';
  }
}
