.links-header-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 8px;

  .cart-link {
    display: flex;
    align-items: center;

    .pucl-pill {
      margin: 0px;
      margin-right: -14px;
    }

    .shopping-cart {
      color: #0072ce;
      margin-left: 12px;
    }

    .pucl-btn-link {
      padding-left: 5px;
    }
  }

  .orders-link-disabled {
    float: right;
    pointer-events: none;
  }
}
