@import '../../baseStyles/_colors';
.voucher-type-detail-page {
  padding-top: 140px;

  .voucher-type-header {
    background: rgb(255, 255, 255);
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 100px;
    left: 0px;
    margin: 0px;
    width: 100%;
    height: 59px;
    padding: 5px 45px;
    z-index: 2002;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;

    .header.layout {
      justify-content: center;

      .header-title-container {
        padding-left: 15px;

        .title {
          margin-top: 10px;
        }
      }

      .buttons-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px;

        .buttons-wrapper {
          display: flex;
          width: 184px;
          justify-content: space-between;

          .pucl-btn {
            .button-text {
              width: 45px;

              a {
                color: inherit;
              }
            }
          }
        }

        .cancel-button-wrapper {
          display: flex;
          justify-content: space-between;

          .pucl-btn {
            .button-text {
              width: 45px;

              a {
                color: inherit;
              }
            }
          }
        }
      }
    }
  }

  .voucher-type-details-section {
    .voucher-type-details-form {
      padding: 72px;
      .voucher-type-details-row-2,
      .voucher-type-details-row-3 {
        padding-top: 20px;
      }
    }

    .copy-icon {
      > i.pucl-icon.ico.ico-copy {
        cursor: pointer;
        padding-left: 5px;
      }

      .ico-copy:before {
        font-size: 18px;
      }
    }
  }

  .associated-code-product-section {
    .cell-container.code-product-name.first {
      > .row-cell {
        > .row-cell-content {
          padding-left: 20px;

          > a {
            cursor: pointer;
            color: #0072ce;;
            font-weight: 500;
            text-decoration: none;
          }
        }
      }
    }

    .cell-container.code-product-country {
      > .row-cell {
        > .row-cell-content {
          .tooltip {
            opacity: 1;
            text-decoration: none;

            .multiple {
              cursor: pointer;
              color: #0072ce;;
              font-weight: 500;
              font-size: 14px;
            }

            .countries-tooltip-content {
              max-width: 300px;
            }
          }
        }
      }
    }
  }

  .voucher-batch-section {
    .pucl-collapsible-container {
      .pucl-table-wrapper {
        .cell-container.voucher-batch-table-batch-id.first {
          .has-checkbox {
            > .row-cell.checkbox {
              margin-top: 0px;
              margin-bottom: 0px;
            }
          }

          .row-cell-content {
            > .voucher-batchId-link {
              cursor: pointer;
              color: #0072ce;;
              font-weight: 500;
              text-decoration: none;
            }
          }
        }

        .cell-container.voucher-batch-table-actions.actions-column {
          .tooltip {
            opacity: 1;
          }
        }
      }

      .voucher-batches-paginator {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 45px;
        margin-bottom: 145px;
        display: flex;
        justify-content: space-between;
        .pucl-select-container {
          min-width: 80px;
        }
      
        .page-link {
          text-decoration: none;
        }
      
        .sie-select__field{
          .sie-select-arrow{
            color: #999999;
          }
        }
      
        .sr-only{
          width: 9px;
          height: 12px;
          margin: 0;
          position: inherit;
        }
      }
    }

    .pucl-collapsible-container {
      .pucl-scrollarea--vertical-track {
        padding-bottom: 220px;
      }
    }
  }

  .tooltip__content.tooltip--visible {
    z-index: 1000;
  }
}

#voucher-type-detail-form-header-desc {
  font-size: 12px;
}

.cancel-batch-modal {
  .warning-icon {
    color: $Warning;
    margin-right: 5px;
  }
}
