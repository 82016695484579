.container.maintainance-page{
  margin: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient( #3FAECD, #2391CB);
  .layout .flex:last-of-type  {
    margin: auto;
    margin-top: 200px;
    margin-bottom: 200px;  }
  .maintainance-page-content-wrapper {
    width: 860px;
    height: 460px;
    background-color: #FFFFFF;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .content-title {
      h3 {
        font-weight: 200;
        font-size: 36px;
      }
    }

    .content-title-icon {
      width: 128px;
    }
    
    .maintainance-page-content {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .maintainance-page-content-body{
        padding-top: 10px;
        text-align: center;
        font-size: 20px;
        font-weight: 200;
      }
    }
    
    .help-link {
      display: flex;
      text-decoration: none;
      color: #0072CE;
      font-size: 14px;
      font-weight: 500;
    }
    
    .help-link:hover {
      text-decoration: none;
      color: #0089D8;
    }
    
    .help-text {
      margin-left: 5px;
    }
  }
  
  /* .content-title-icon {
    width: 100px;
    height: 200px;
  } */
}
