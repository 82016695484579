.order-history {
  a:hover {
    text-decoration: none;
  }

  .order-history-header {
    padding-top: 40px;

    > .header {
      width: 1370px;

      .search-header-secondary-nav {
        justify-content: flex-end;
      }
    }

    .pucl-search__category-select-menu {
      z-index: 2101;
    }
  }

  .order-history-table {
    margin-top: 40px;
    width: 1370px;

    .tooltip {
      opacity: 1;
      z-index: 0 ;
      // Adding because Bootstrap is overridding PUCL font-family.
      // Remove when no longer using Boostrap.
      font-family: 'SSTPro-Roman' !important;
      .tooltip__inner-content {
        z-index: 1000;
        .comment-overlay {
          z-index: 1000;
        }
      }
      .tooltip--visible {
        opacity: 1;
      }
    }

    .tooltip__content.tooltip--visible {
      z-index: 2100;
    }

    .code-product-name-container {
      width: 100%;
      .tooltip {
        position: relative !important;
      }
    }
    .cell-container.order-cta.stuck-horizontal-right {
      z-index: 997 !important;
    }

    .cell-container.order-cta {
      z-index: 0 !important;
      .row-cell-content {
        justify-content: end;
      }
    }

    .order-cta-button {
      width: 132px;
      line-height: 15px;
    }

    .pucl-action-bar {
      z-index: 10;
      label {
        font-weight: initial;
      }
    }

    .action-bar-container {
      margin-left: 30px;
      &.right {
        .bulk-download-button {
          background: #EEEEEE !important;
          border: 1px solid #D2D2D2;
          border-radius: 3px;
          padding-right: 20px !important;
          .button-text {
            color: #999999 !important;
          }
        }

        .bulk-download-button:hover {
          background-color: #dadada !important;
        }
      }

      .tooltip {
        margin-left: -27px;
        margin-right: 10px;
      }
    }

    .order-history-content {
      width: 100%;
      padding-bottom: 65px;
      margin: 0 auto;

      .tooltipDefaultStyles {
        a {
          color: #fff;
        }
      }

      .pucl-table-wrapper {
        .expandable-container {
          background-color: #fbfbfb;
          max-height: none;
        }
      }
      .header-cell:first-child {
        .header-cell-wrapper {
          .content {
            padding-left: 20px;
          }
        }
      }
      .sort-icons{
        margin-left: 0px !important;
      }

      .header-cell:not(:first-child) {
        .header-cell-wrapper {
          .content {
            padding-left: 0px;
            padding-right: 10px;
          }
        }
      }

      .header-cell-info {
        border-left: none;
      }

      .row-cell {
        .order-history-info-tooltip {
          width: 20px;
          height: 20px;
          margin-left: 10px;
          .tooltip {
            color: #767676;
            width: auto;
            i {
              font-size: 20px;
            }
          }
          .info-overlay {
            box-sizing: border-box;
            .column {
              float: left;
              width: 50%;
              padding-left: 15px;
            }

            /* Clear floats after the columns */
            .row:after {
              display: table;
              clear: both;
            }
          }
          .tooltip__content {
            width: 400px;
            z-index: 2000;
          }
        }
      }

      .cell-container {
        &.first {
          .row-cell.checkbox {
            margin-top: 0px;
            .pucl-input--selection-controls__input.disabled {
              input {
                margin-left: 0px;
              }
            }
          }
        }

        .calendar-container {
          z-index: 1;
        }
        .cta-button-wrap {
          display: block;
        }

        .time-zone-field {
          width: 96px;
          padding-top: 10px;
        }
      }
      .comment-tooltip {
        margin: 0 auto;

        .tooltip {
          color: #767676;
        }
      }

      #order-history-comment-tooltip.tooltip__content {
        width: 300px;
        padding: 10px 0px;

        hr {
          margin-top: 10px;
          margin-bottom: 10px;
          border-color: #d8d8d8;
        }

        .comment-overlay-header,
        .comment-overlay-content {
          padding-left: 10px;
          padding-right: 10px;
        }

        .comment-overlay-content {
          .comment-overlay-content-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
          }
        }
      }

      .info-tooltip {
        margin: 0 auto;
        .tooltip {
          color: #767676;
        }

        .info-overlay {
          box-sizing: border-box;

          .column {
            float: left;
            width: 50%;
            padding-left: 15px;
          }

          /* Clear floats after the columns */
          .row:after {
            display: table;
            clear: both;
          }
        }

        .tooltip__content {
          width: 400px;
        }
      }

      .code-product-link {
        color: #0072ce;
        font-size: 14px;
      }
      .pucl-date {
        padding-right: 4px;
        .pucl-input-field-container {
          min-width: 158px;
        }
      }

      .information-row-container {
        padding: 0 50px;
        .information-row-comment {
          margin-right: 0px;
          margin-left: auto;
          .information-row-item-title {
            margin-right: 0px;
          }
          .order-history-info-tooltip {
            margin-left: 10px;
            .tooltip {
              color: #636365;
            }
          }
        }
      }

      .content-split-horizontal-rule {
        margin-left: 70px;
      }
    }

    .orderHistoryPagePaginator {
      margin-top: 45px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      .pucl-select-container {
        min-width: 80px;
      }

      .page-link {
        text-decoration: none;
      }

      .sie-select__field{
        .sie-select-arrow{
          color: #999999;
        }
      }

      .sr-only{
        width: 7.5px;
        height: 12px;
        margin: 0;
        position: inherit;
      }
    }
  }
  .truncate {
    overflow-wrap: anywhere;
    font-size: 14px;
    color: #363636;
  }
}
