
// Brand Primary Colors
// These are the brand colors of Sony PlayStation
$SonyBlue: #003086;
$SonyBlack: #000000;
$SonyWhite: #FFFFFF;

// Primary UI Colors
// Blues and greys of the UI are taken from the DS4 controller lightbar and touch pad
$Link: #0072CE;
$UI-ControlDefault: #0072CE;
$UI-Active: #67CAFF;
$TextBlack: #363636;
$InputDefault: #999999;
$BodyWhite: #EEEEEE;
$UI-ControlDisabled: #EEEEEE;

$Row-Highlight: #D4E2F3;
$DropDown-Active-Outline: #0072CE;


// Secondary UI Colors
// Text colors must meet the Sony Group web accessibiltiy guidelines
$UI-Control: #616161;
$Text-Grey: #767676;
$UI-Accessory: #DEDEDE;
$Text-PlaceHolder: #DEDEDE;
$UI-Hover: #F5F5F5;

$Background-Highlight: #F1F1F1;
$Field-Outline: #CECECE;
$Active-Text: #4A4A4A;

// Semantic Colors
// Semantic colors are taken from the original PlayStation brandmark
$Valid: #00A394;
$Error: #E41017;
$Warning: #FBB700;
$Informational: #006DB4;

// Secondary Brand Colors
$ValidSecondary: #00A68E;
$ErrorSecondary: #EF5A75;
$WarningSecondary: #5682C2;
$InformationalSecondary: #C27EB5;

$BlueGradient: linear-gradient(#3FAECD, #2391CB);
$PurpleGradient: linear-gradient(#C08BEC, #944EAF);
$RedGradient: linear-gradient(#E37A8f, #2391CB);
