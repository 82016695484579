#voucherBatches-container.voucher-batches-list.container {
  padding-top: 40px;
  width: 1380px;

  .voucherBatches-nav {
    justify-content: space-between;
    padding-top: 30px;

    .search {
      margin-right: 20px;
    }
  }

  .bulk-action {
    display: flex;
    margin-top: 20px;
    margin-bottom: 30px;

    .bulk-action-selector {
      width: 200px;
    }
  }

  &.loading-voucher-batches {
    text-align: center;
    font-size: 20px;
  }

  .filters-toggle {
    color: rgb(6, 58, 143);
    font-weight: bold;
    span {
      margin-right: 5px;
    }
  }

  .voucherBatchBulkAction-button {
    margin-left: 10px;
  }

  .results-text {
    font-weight: bold;
    padding: 10px 0 20px;
  }

  span.toggle {
    &:before {
      content: '\25BC ';
      display: none;
      cursor: pointer;
    }
    &.collapsed:before {
      content: '\25B6 ';
    }
  }

  .row > div {
    padding: 5px;
    -ms-word-break: break-all;
    word-break: break-all;

    &.status {
      -ms-word-break: break-word;
      word-break: break-word;
    }
  }

  .checkboxCol {
    padding: 0;
  }

  .voucherPurpose {
    max-width: 100px;
  }

  .panel-title a {
    display: inline;
    padding: 10px 0;
  }

  .row div.panel-title {
    padding: 5px 0;
  }

  div {
    &.panel,
    &.panel-heading {
      padding: 0;
      background: none;
      box-shadow: none;
      border: 0;
      margin-bottom: 0;
    }
  }

  .headers {
    font-weight: bold;
    border-bottom: 1px solid #000;
    font-size: 12px;
    color: #063a8f;

    & div {
      cursor: pointer;
      word-break: break-word;
      &.desc::after {
        content: ' \25BC' !important;
      }
      &.voucherBatchId.desc::after {
        position: absolute;
        top: 7px;
        left: 80px;
      }
      &.asc::after {
        content: ' \25B2' !important;
      }
      &.voucherBatchId.asc::after {
        position: absolute;
        top: 7px;
        left: 80px;
      }
    }

    .disableSort {
      cursor: default;
      color: #333333;
    }

    .allCheckbox {
      width: 20px;
      display: inline;
    }

    .voucherBatchIdLabel {
      position: absolute;
      top: 7px;
      left: 25px;
    }
  }

  .checkbox {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .voucher-batch-link {
    color: #063a8f;
    cursor: pointer;
    padding-top: -3px;
    &:hover {
      text-decoration: underline;
    }
  }

  .page-header {
    h1 {
      margin-top: 0;
      font-size: 24px;
    }
    border: none;
  }

  .voucher-batch-row {
    padding: 5px 0;
    position: relative;
  }

  input#createdStartDate,
  input#createdEndDate {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px !important;
    border-style: solid;
    border-width: 1px;
    height: 38px;
  }

  .separator {
    height: auto;
    position: relative;
    width: 100vw;
    border-bottom: 1px solid gray;
    left: calc(-50vw + 50% - 8px);
  }

  .action-bar-container {
    margin-left: 30px;

    &.left {
      .action-bar-filter-button-container {
        .tooltip {
          opacity: 1;
          margin-left: -27px;
        }

        .tooltip:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.voucher-batches {
  #readOnlyBatchTooltip {
    position: absolute;
  }

  .voucherBatch-batchId {
    a:link {
      text-decoration: none;
    }
    a:hover {
      text-decoration: none;
    }
    a:visited {
      text-decoration: none;
      color: #0072ce;
    }
  }
}

.voucherBatch-status-information {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.search-header {
  height: auto;
  position: relative;
  margin-top: -69px;
  top: 0;
  min-height: 66px;
  width: 100vw;
  left: calc(-50vw + 50% - 8px);
}

.header-cell.voucherBatch-batchId.first {
  background-color: green;
  padding: 10px;
  .row-cell {
    a:visited {
      color: #0072ce;
    }

    a:hover {
      text-decoration: none;
    }

    .cell-preface.has-checkbox.has-expandable {
      margin-right: 0px;
      .hide-icon {
        padding-right: 0px;
      }
    }

    .tooltip {
      opacity: 1;
      padding-right: 5px;
    }
  }
}

.container.voucher-batches {
  .header-cell {
    .header-cell-wrapper {
      .content {
        padding: 0px;
      }
      .sort-icons {
        margin-left: 15px;
      }
    }
  }
}

.cell-container.voucherBatch-batchId.first {
  .row-cell {
    .cell-preface.has-checkbox.has-expandable {
      margin-right: 0px;
      
      .expand-icon.hide-icon {
        padding: 0px 10px;
      }
    }
  }
}

.cell-container.voucherBatch-kabob {
  .tooltip {
    opacity: 1;
    z-index: 0;
  }

  .row-cell-content {
    justify-content: space-between;
  }

  .read-only-status{
    width: 100%;
    display: flex;
    justify-content: space-between;

    .status-icon {
      width: 40px;
    }
  }
}

#voucher-batch-table-cancel-batch-modal {
  z-index: 9999;
}

.voucherBatches-paginator {
  margin-top: 45px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  .pucl-select-container {
    min-width: 80px;
  }

  .page-link {
    text-decoration: none;
  }

  .sie-select__field {
    .sie-select-arrow {
      color: #999;
    }
  }
  .sr-only {
    width: 9px;
    height: 12px;
    margin: 0;
    position: inherit;
  }
}

.warning-modal-intro {
  margin-bottom: 20px;
}
