.voucher-types-list {
  padding-top: 40px;

  .voucherTypes-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    width: 100%;
    padding: 30px 45px;

    .voucherTypes-nav-content {
      display: inline-flex;
      min-width: 1270px;

      .pucl-input-container{
        padding-left: 40px;
        padding-right: 20px;
        width: 460px;
      }
    }
  }

  .action-bar-container {
    margin-left: 30px;

    &.left {
      .action-bar-filter-button-container {
        .tooltip {
          opacity: 1;
          margin-left: -27px;
        }

        .tooltip:hover {
          text-decoration:none;
        }
      }
    }
    &.right {
      .pucl-btn {
        background-color: #0072CE!important;
        padding: 0 20px!important;
        width: auto!important;
        .button-text,
        .ico-action-add{
          background-color: #0072CE!important;
          color: #fff!important;
        }

      }
      .new-code-product.pucl-btn {
        padding-right: 20px;
        background-color: #0072ce;
        .button-text {
            color: #fff;
        }
      }
    }
  }

  .filters-container{
    .npRegion{
      width: 315px;
    }
  }

  .header-cell {
    .header-cell-wrapper {
      .content {
        padding-left: 0px;
      }
    }
  }

  .cell-container.voucherTypeName.first {
    .row-cell {
      a:visited {
        color: #0072ce;
      }

      a:hover {
        text-decoration: none;
      }

      .cell-preface {
        margin-right: 0px;
        .hide-icon {
          padding-right: 0px;
        }
      }

      .tooltip {
        opacity: 1;
        padding-right: 5px;
      }
    }

  }

  .voucherTypes-paginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .pucl-select-container {
      min-width: 80px;
    }

    .page-link {
      text-decoration: none;
    }

    .sie-select__field{
      .sie-select-arrow{
        color: #999999;
      }
    }

    .sr-only{
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit;
    }
  }
}



