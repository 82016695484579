.voucherCoverageTable-container {
    margin-top: 10px;
    .settlementCurrency, .commissionRate{
        width: 100%;
    }
    .panel-title{
        .remove-product{
            background-image: none;
            text-shadow: none;
            border: none;
            box-shadow: none;
            background: none;
            i {
                color: #063A8F;
            }
        }
        .third-party-country-count{
            float: right
        }
        .third-party-country-count{
            margin-right: 10px;
            padding-right: 10px;
        }
    }
    
    .panel-body .container{
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        .row div{
            margin-top: 10px;
            margin-bottom: 10px;
            .checkbox{
                margin: 0px;
            }
        }
    }
}

.third-party-coverage-table {
  .pucl-data-table {

    .cell-container.first {
      .has-checkbox {
        .row-cell.checkbox {
          margin: 0px;
        }
      }
    }
    
  }
}