$icon-size: 18px;
$icon-color: #616161;

.order-detail-page,
.pucl-table-wrapper {
  & > .tooltip__content {
    z-index: 2005;
  }
}

.order-steps {
  display: flex;
  height: 40px;
  width: 100%;
  margin-left: -30px;
  background-color: white;
  transform: translateY(40px);
  position: fixed;
  z-index: 2004;
  border-bottom: 1px solid #d2d2d2;

  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .order-progress {
    font-family: SSTPro-Medium;

    .number {
      padding: 3px 8px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
      background-color: #a8a5a5;
      color: #fff;
      text-decoration: none;
    }

    ul {
      height: 50px;
      margin: 0 auto;
      padding: 0;
      list-style-type: none;
      text-align: center;
    }

    li {
      display: inline-block;
      margin: 10px 20px;
      font-size: 14px;
      color: #a8a5a5;

      span {
        text-decoration: none;
        cursor: default;
      }

      &.active {
        color: #363636;

        .number {
          background-color: #363636;
        }
      }
    }

    .comment-icon {
      display: inline-block;
      margin-left: 5px;
      position: relative;

      #message-icon-clickbox {
        cursor: pointer;

        .pucl-icon {
          vertical-align: middle;
        }
      }

      .comment-message-container {
        position: absolute;
        top: calc(100% + 6px);
        text-align: initial;
      }

      .pucl-message-board {
        .close {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    transform: translateY(15px);

    .orderSubmission {
      align-items: flex-start;
      margin-right: 8.5%;
    }
    .fulfillment {
      align-items: center;
      margin-right: 8.5%;
    }
    .Activation {
      align-items: flex-end;
      margin-right: 8.5%;
    }
  }
}

.order-detail-nav {
  height: 70px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-left: -30px;
  transform: translateY(80px);
  box-shadow: rgba(0, 0, 0, 0.26) 0px 0px 15px;
  position: fixed;
  z-index: 2003;

  .container {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .order-detail-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin: 0px;
    }

    .board-icon {
      display: flex;
    }

    .buttons {
      display: flex;
      margin-top: auto;
      &.save {
        text-align: right;
      }

      &.approve {
        display: flex;
        justify-content: right;

        & > button,
        .action-button {
          margin-right: 15px;

          &:last-child {
            margin-right: 0px;
          }
        }
      }

      .tooltip {
        opacity: 1;
        cursor: default !important;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    .action-button {
      position: relative;

      .message-board-container {
        position: absolute;
        top: calc(100% + 6px);
        &.amend {
          left: -40%;
        }
        &.reject {
          left: -120%;
        }
      }
    }

    .pucl-message-board {
      .close {
        font-size: 12px;
      }
    }

    .cancel {
      margin-right: 15px;
    }

    .save {
      margin-right: 15px;
    }

    .submit {
      margin-right: 15px;
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    transform: translateY(50px);
  }
}

.agency-order-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  &.order {
    padding-top: 185px;
  }

  .link {
    text-decoration: none;
    &:visited {
      color: #0072ce;
    }
  }

  .required:after {
    content: none;
  }

  .info-container {
    padding: 0px 50px;

    .layout {
      margin: 50px 0px;

      .flex-wrapper {
        display: flex;
        width: 100%;
      }

      .pucl-input-container{
        margin-bottom: 30px;
      }

      .pucl-select__field {
        margin-bottom: 30px;
      }

      .select-wrapper {
        width: 375px;
      }
    }

    .additional-cost-content{
      max-width: 200px;
      white-space: wrap;
    }

    .static-text {
      .label {
        font-weight: 500;
      }
      .value {
        margin-top: auto;
      }
    }

    .manufacturing {
      display: flex;
      align-items: baseline;
      width: 70%;
      .tooltip {
        color: $icon-color;
        .total-tax-content{
          max-width: 200px;
          white-space: wrap;
        }
        margin-left: 10px;
        opacity: 1;
      }

      .tooltip:hover,
      .tooltip:active {
        text-decoration: none;
      }

      .static-text {
        width: unset;
        margin-bottom: 30px;

        .value {
          margin-top: 15px;
        }
      }

      .pucl-select {
        transform: translateY(40px);
      }
      .pucl-input.pucl-input-wrapper {
        transform: translateX(-75px);
        margin-bottom: 30px;
        .pucl-label {
          font-weight: 500;
        }
      }
    }

    .total-tax {
      display: flex;
      width: 70%;
      .tooltip {
        color: $icon-color;
        .total-tax-content{
          max-width: 200px;
          white-space: wrap;
        }
        margin-left: 10px;
        opacity: 1;
      }

      .tooltip:hover,
      .tooltip:active {
        text-decoration: none;
      }

      .static-text {
        width: unset;
        margin-bottom: 30px;

        .value {
          margin-top: 15px;
        }
      }

      .pucl-select {
        transform: translateY(40px);
      }
      .pucl-input.pucl-input-wrapper {
        transform: translateX(-75px);
        margin-bottom: 30px;
        .pucl-label {
          font-weight: 500;
        }
      }
    }

    .pucl-checkbox {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    .pucl-input--selection-controls__input .pucl-label {
      font-weight: 500;
      margin-top: 5px;

      &:before {
        top: 0.2rem;
      }
    }

    .warning {
      display: flex;
      align-items: baseline;
      .pucl-icon {
        margin-right: 10px;
        color: $icon-color;
      }
    }
  }
}

.agency-order-contents {
  .pucl-data-table .cell-container.first .row-cell {
    margin-left: 20px;
  }

  .cell-container.first {
    div.row-cell {
      .has-checkbox {
        margin-left: -40px;
      }
      .checkbox {
        margin-top: 0px;
      }
    }

    .info-icon {
      .tooltip {
        color: $icon-color;
      }
    }
  }

  .cell-container {
    .truncate {
      color: #000;
      font-size: 14px;
      font-family: 'SSTPRO-Roman';
    }
  }

  .pucl-input-field-container {
    width: 60%;
  }

  .pucl-data-table {
    .header-cell {
      .content {
        padding-left: 0px;

        .tooltip {
          color: $icon-color;
          opacity: 1;
          margin-left: 5px;

          .info-content {
            max-width: 200px;
            white-space: wrap;
          }
        }

        .tooltip:hover,
        .tooltip:focus,
        .tooltip:active {
          text-decoration: none;
        }

        .required {
          margin-right: 5px;
          color: #EF5A75;
          font-family: "SSTPro-Medium";
        }
      }
    }

    .header-cell.can-remove {
      .header-cell-wrapper {
        .content {
          visibility: hidden;
        }
      }
    }

    .header-cell.last {
      border-left: none;
    }

    .cell-container {
      .row-cell {
        height: 70px;
      }
    }

    .cell-container.delete-icon {
      .remove-text {
        padding-left: 5px;
      }
    }

    .order-line-cell {
      font-size: 14px;
      .tooltip {
        opacity: 1;
      }
      .tooltip:hover {
        text-decoration: none;
      }
    }
  }

  .table-no-results-found {
    padding-top: 60px;
  }
}
