.tertiary-row {
  border-bottom: 1px solid #E7E7E7;
  .pucl-icon {
    font-size: 16px;
  }
  .tertiary-headers-row {
    display: flex;
    justify-content: flex-start;
    background-color: #F3F3F3;
    align-items: stretch;
    .tertiary-header {
      padding: 10px 20px;
      border: 1px solid #cacaca;
      word-break: break-word;
      display: flex;
    }
  }
  .tertiary-row-content {
    display: flex;
    justify-content: flex-start;
    .comments{
      padding-top: 10px;
      padding-left: 10px;
      max-width: 930px;
    }
  }
  .tertiary-order-row-content {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: auto;
    gap: 0px;
    .pucl-input.pucl-text-field.form-field {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      .pucl-form-input {
        width: unset;
      }
      .pucl-input-field-container {
        margin-left: 5px;
      }
      .pucl-label {
        order: 1;
      }
    }

    .commenter, .commented-date, .comment, .pucl-input.pucl-input-wrapper {
      padding: 20px;
      border-top: 1px solid #cacaca;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .comments {
    flex-grow: 3;
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid #cacaca;
    .pucl-input.pucl-text-field.form-field {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      .pucl-form-input {
        width: unset;
      }
      .pucl-input-field-container {
        margin-left: 5px;
      }
      .pucl-label {
        order: 1;
      }
    }
    .commenter {
      width: 160px;
      padding-right: 20px;
    }
    .commented-date {
      width: 170px;
      padding-right: 20px;
    }

    .comment, .pucl-input.pucl-input-wrapper {
      width: 642px;
      padding-right: 20px;
    }
  }
  .cta {
    display: flex;
    flex-grow: 1;
    align-items: center;
    border-bottom: 1px solid #cacaca;
  }
}
.tooltipDefaultStyles.orderComments {
  width: 250px;
}