@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
.links-header-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 8px; }
  .links-header-container .cart-link {
    display: flex;
    align-items: center; }
    .links-header-container .cart-link .pucl-pill {
      margin: 0px;
      margin-right: -14px; }
    .links-header-container .cart-link .shopping-cart {
      color: #0072ce;
      margin-left: 12px; }
    .links-header-container .cart-link .pucl-btn-link {
      padding-left: 5px; }
  .links-header-container .orders-link-disabled {
    float: right;
    pointer-events: none; }

.header {
  width: 100%; }
  .header.no-secondary-nav {
    padding-top: 48px; }
  .header a:hover {
    text-decoration: none; }
  @media (max-width: 1199px) {
    .header {
      top: 90px; } }
  .header .search-header-title-container {
    display: flex;
    flex-direction: column; }
  .header .search-header-container {
    justify-content: space-between;
    width: 100%; }
    .header .search-header-container h2 {
      margin-top: 10px; }
  .header .select-container {
    float: right;
    margin-top: 10px; }

.filters-container {
  display: flex;
  z-index: 5000; }
  .filters-container .filter {
    min-width: 150px;
    max-width: 315px;
    padding-left: 5px;
    padding-right: 5px; }
    .filters-container .filter:first-child {
      width: 315px; }
    .filters-container .filter.region {
      width: 235px; }
    .filters-container .filter.platform, .filters-container .filter.version {
      width: 185px; }
    .filters-container .filter.status {
      width: 265px; }
    .filters-container .filter.releaseDate, .filters-container .filter.creationDate {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
    .filters-container .filter .pucl-date-range .pucl-input-field-container {
      width: 230px !important; }
    .filters-container .filter.agreementType {
      width: 240px; }
    .filters-container .filter.country {
      width: 225px; }
    .filters-container .filter.voucherProductType {
      width: 175px; }

.static-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: 'SSTPro-Medium'; }
  .static-text:not(:last-child) {
    margin-bottom: 30px; }
  .static-text .label {
    width: 100%;
    margin-bottom: 10px;
    padding: 0px;
    font-weight: bold;
    color: #363636;
    text-align: left;
    font-size: 14px;
    font-family: 'SSTPro-Roman'; }
    .static-text .label.isSafari {
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-text-stroke: 1px transparent; }
  .static-text .value.isSafari {
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-stroke: 1px transparent; }
  .static-text .required-symbol {
    color: red; }

#swap-code-product-modal .static-text {
  margin: 30px 0px 0px 0px; }

#swap-code-product-modal .code-product-search {
  display: flex; }
  #swap-code-product-modal .code-product-search .pucl-input {
    margin-right: 20px; }
  #swap-code-product-modal .code-product-search #code-product-search-button {
    margin-top: 10px; }

#swap-code-product-modal .pucl-data-table {
  min-height: 244px; }
  #swap-code-product-modal .pucl-data-table .header-cell {
    padding-left: 0px; }
    #swap-code-product-modal .pucl-data-table .header-cell .header-cell-wrapper {
      margin-left: 10px; }
    #swap-code-product-modal .pucl-data-table .header-cell .content {
      padding-left: 0px; }
  #swap-code-product-modal .pucl-data-table .cell-container.first {
    padding-left: 10px; }
  #swap-code-product-modal .pucl-data-table .cell-container.label-cell {
    background-color: #f3f3f3; }

#swap-code-product-modal .code-product-swap-footer {
  display: flex;
  justify-content: end;
  padding-top: 40px; }
  #swap-code-product-modal .code-product-swap-footer button:first-child {
    margin-right: 15px; }

.tooltip-comment, .tooltip-input {
  width: 200px;
  opacity: 1 !important; }
  .tooltip-comment .tooltip-inner, .tooltip-input .tooltip-inner {
    text-align: left;
    background-color: #fff;
    border: 1px solid #878787;
    color: #000;
    font-size: 10px;
    padding: 5px 8px 8px; }
    .tooltip-comment .tooltip-inner textarea, .tooltip-input .tooltip-inner textarea {
      font-size: 12px; }
    .tooltip-comment .tooltip-inner span, .tooltip-input .tooltip-inner span {
      color: #878787; }
    .tooltip-comment .tooltip-inner .input-note, .tooltip-input .tooltip-inner .input-note {
      line-height: 2; }
    .tooltip-comment .tooltip-inner .input-buttons, .tooltip-input .tooltip-inner .input-buttons {
      text-align: right; }
    .tooltip-comment .tooltip-inner [class*="col-"], .tooltip-input .tooltip-inner [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .tooltip-comment .tooltip-inner .comment-header, .tooltip-input .tooltip-inner .comment-header {
      border-bottom: 1px solid #878787;
      line-height: 2; }
    .tooltip-comment .tooltip-inner span.close-icon, .tooltip-input .tooltip-inner span.close-icon {
      float: right;
      color: #000;
      font-weight: bold;
      cursor: pointer; }
    .tooltip-comment .tooltip-inner .comment-info, .tooltip-input .tooltip-inner .comment-info {
      line-height: 2;
      padding-top: 3px;
      padding-bottom: 8px; }
    .tooltip-comment .tooltip-inner .comment-date, .tooltip-input .tooltip-inner .comment-date {
      text-align: right; }
    .tooltip-comment .tooltip-inner .comment-body, .tooltip-input .tooltip-inner .comment-body {
      font-size: 12px;
      word-break: break-all; }

.orders-list {
  padding-bottom: 65px; }
  .orders-list .orders-list-header {
    padding-top: 70px;
    padding-bottom: 40px; }
    .orders-list .orders-list-header .select-container .pucl-search__category-select-container > .pucl-search__category-select {
      width: 224px; }
    .orders-list .orders-list-header .select-container .pucl-search__category-select-container > .pucl-search__category-select-menu > .category-options-container {
      width: 206px; }
  .orders-list .pucl-action-bar .bar .action-bar-container.left .tooltip:hover {
    text-decoration: none; }
  .orders-list .pucl-action-bar .bar .action-bar-container.right > #new-order-button.new-order-button.pucl-btn {
    padding-right: 20px;
    background-color: #0072ce; }
    .orders-list .pucl-action-bar .bar .action-bar-container.right > #new-order-button.new-order-button.pucl-btn > .button-text {
      color: #fff; }
      .orders-list .pucl-action-bar .bar .action-bar-container.right > #new-order-button.new-order-button.pucl-btn > .button-text > .pucl-icon {
        background-color: #0072ce; }
  .orders-list .filters .filters-container {
    flex-wrap: wrap; }
    .orders-list .filters .filters-container .actionRequired {
      max-width: 305px; }
    .orders-list .filters .filters-container .creationDateRange {
      padding-top: 10px;
      min-width: 305px; }
      .orders-list .filters .filters-container .creationDateRange .pucl-date-range .pucl-input-field-container {
        min-width: 295px; }
    .orders-list .filters .filters-container .country {
      min-width: 305px; }
    .orders-list .filters .filters-container .region {
      min-width: 305px; }
    .orders-list .filters .filters-container .activationState {
      min-width: 305px; }
    .orders-list .filters .filters-container .orderStatus {
      padding-top: 10px;
      min-width: 305px; }
    .orders-list .filters .filters-container .agreementType {
      padding-top: 10px;
      min-width: 305px; }
    .orders-list .filters .filters-container .filter.undefind {
      display: none; }
  .orders-list .pucl-table-wrapper .pucl-action-bar {
    z-index: 1; }
  .orders-list .pucl-table-wrapper .cell-container.first .activeOrder-link {
    color: #0072ce;
    font-family: 'SSTPro-Medium'; }
    .orders-list .pucl-table-wrapper .cell-container.first .activeOrder-link a:hover {
      text-decoration: none; }
    .orders-list .pucl-table-wrapper .cell-container.first .activeOrder-link a:active, .orders-list .pucl-table-wrapper .cell-container.first .activeOrder-link a:visited {
      color: #0072ce; }
    .orders-list .pucl-table-wrapper .cell-container.first .activeOrder-link .tooltip {
      opacity: 1; }
      .orders-list .pucl-table-wrapper .cell-container.first .activeOrder-link .tooltip .truncate {
        width: 214px;
        line-height: 20px;
        font-size: 14px;
        font-family: 'SSTPro-Roman';
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600; }
  .orders-list .pucl-table-wrapper .cell-container .row-cell .row-cell-content .pucl-tag.in-progress .tag-icon,
  .orders-list .pucl-table-wrapper .cell-container .row-cell .row-cell-content .pucl-tag.error .tag-icon,
  .orders-list .pucl-table-wrapper .cell-container .row-cell .row-cell-content .pucl-tag.inactive .tag-icon {
    width: 13px; }
  .orders-list .pucl-table-wrapper .cell-container .row-cell .row-cell-content .pucl-tag.valid .tag-icon {
    width: 13px;
    background-color: #2d9e94; }
  @media only screen and (min-width: 2328px) and (max-width: 2560px) {
    .orders-list .pucl-table-wrapper .cell-container .row-cell .row-cell-content .pucl-tag.new .tag-icon {
      width: 16px; } }
  @media only screen and (min-width: 2049px) and (max-width: 2328px) {
    .orders-list .pucl-table-wrapper .cell-container .row-cell .row-cell-content .pucl-tag.new .tag-icon {
      width: 14px; } }
  @media only screen and (min-width: 0px) and (max-width: 2049px) {
    .orders-list .pucl-table-wrapper .cell-container .row-cell .row-cell-content .pucl-tag.new .tag-icon {
      width: 16px; } }
  .orders-list .pucl-table-wrapper .cell-container.disabled .tag-label {
    color: #a8a8a8; }
  .orders-list .pucl-table-wrapper .cell-container.disabled:hover {
    background-color: #f6fbff; }
  .orders-list .pucl-table-wrapper .cell-container.partnerName span {
    color: #363636; }
  .orders-list .pucl-table-wrapper .cell-container.partnerName .tooltip {
    opacity: 1;
    text-decoration: none; }
    .orders-list .pucl-table-wrapper .cell-container.partnerName .tooltip .tooltip__inner-content {
      color: #fff; }
  .orders-list .pucl-table-wrapper .cell-container.partnerName .tooltip:hover {
    text-decoration: none; }
  .orders-list .pucl-table-wrapper .cell-container.partnerName .truncate {
    color: #363636;
    width: 142px;
    line-height: 20px;
    font-size: 14px;
    font-family: 'SSTPro-Roman';
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .orders-list .pucl-table-wrapper .order-list-order-name-tooltip a.tooltip {
    text-decoration: none; }
  .orders-list .pucl-table-wrapper .order-list-order-countries-tooltip {
    padding-top: 6px;
    padding-left: 2px; }
    .orders-list .pucl-table-wrapper .order-list-order-countries-tooltip a.tooltip {
      text-decoration: none; }
  .orders-list .pucl-data-table .header-cell-wrapper .content {
    padding: 0px; }
  .orders-list .order-row {
    border-bottom: 1px solid #000; }
  .orders-list .order-row.disabled.row .order-line {
    color: #a8a8a8; }
  .orders-list .order-row.disabled.row span.toggle.collapsed:before {
    color: initial; }
  .orders-list .cell-container.order-line.isRemoved, .orders-list .cell-container.order-line.isCancelled {
    color: #a8a8a8; }
  .orders-list .comment {
    padding-top: 5px; }
    .orders-list .comment #order-comment-tooltip {
      z-index: 12108; }
  .orders-list .order-list-pagination {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between; }
    .orders-list .order-list-pagination .pucl-select-container {
      min-width: 80px; }
    .orders-list .order-list-pagination .page-link {
      text-decoration: none; }
    .orders-list .order-list-pagination .sie-select__field .sie-select-arrow {
      color: #999999; }
    .orders-list .order-list-pagination .sr-only {
      width: 9.5px;
      height: 12px;
      margin: 0;
      position: inherit; }
  .orders-list #order-comment-tooltip.tooltip__content {
    width: 300px;
    padding: 10px 0px; }
    .orders-list #order-comment-tooltip.tooltip__content hr {
      margin-top: 10px;
      margin-bottom: 10px;
      border-color: #d8d8d8; }
    .orders-list #order-comment-tooltip.tooltip__content .comment-overlay-header,
    .orders-list #order-comment-tooltip.tooltip__content .comment-overlay-content {
      padding-left: 10px;
      padding-right: 10px; }
    .orders-list #order-comment-tooltip.tooltip__content .comment-overlay-content .comment-overlay-content-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px; }
  .orders-list .tooltip {
    opacity: 1;
    z-index: 0;
    font-family: 'SSTPro-Roman' !important; }
    .orders-list .tooltip .tooltip__inner-content {
      z-index: 1000; }
      .orders-list .tooltip .tooltip__inner-content .comment-overlay {
        z-index: 1000; }
        .orders-list .tooltip .tooltip__inner-content .comment-overlay .comment-overlay-header,
        .orders-list .tooltip .tooltip__inner-content .comment-overlay .comment-overlay-content {
          padding-left: 10px;
          padding-right: 10px; }
        .orders-list .tooltip .tooltip__inner-content .comment-overlay .comment-overlay-content .comment-overlay-content-info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px; }
    .orders-list .tooltip .tooltip--visible {
      opacity: 1; }
  .orders-list .tooltip__content.tooltip--visible {
    z-index: 2100; }

.order-documents .pucl-collapsible-container .pucl-data-table {
  min-height: 365px; }

.order-documents .header-cell-wrapper .content {
  padding-left: 0px; }

.order-documents .pucl-data-table .cell-container.first .row-cell {
  padding-left: 20px; }

.order-documents .header-cell.remove {
  border-left: none; }

.order-documents .fileName .fileName-content {
  display: flex; }

.order-documents .fileName .tooltip {
  opacity: 1;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none; }
  .order-documents .fileName .tooltip .fileName-text {
    font-size: 14px;
    line-height: 20px;
    font-family: 'SSTPro-Medium'; }

.order-documents .fileName .virus-scan-status {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .order-documents .fileName .virus-scan-status span.message span#file-name-truncate {
    white-space: pre-wrap; }
  .order-documents .fileName .virus-scan-status .pucl-loader {
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .order-documents .fileName .virus-scan-status .pucl-loader .pucl-loader-message {
      padding-left: 10px; }

.order-documents .fileName .scan-failed {
  padding-right: 10px; }
  .order-documents .fileName .scan-failed .tooltip {
    color: #b00c12; }

.order-documents .fileName .file-scan-failed .fileName-text {
  color: #000;
  font-family: 'SSTPro-Roman'; }

.order-documents .shareWithPartner .pucl-select-container {
  width: 70px; }

.order-documents .remove-cell .row-cell-content {
  justify-content: flex-end;
  padding-right: 20px;
  color: #888; }
  .order-documents .remove-cell .row-cell-content:hover .remove-icon, .order-documents .remove-cell .row-cell-content:hover .remove-text {
    color: #000; }
  .order-documents .remove-cell .row-cell-content:hover .disabled {
    color: #888; }
  .order-documents .remove-cell .row-cell-content .tooltip {
    opacity: 1;
    font-size: 14px;
    line-height: 20px;
    color: #363636;
    text-decoration: none; }
    .order-documents .remove-cell .row-cell-content .tooltip a {
      text-decoration: none; }
  .order-documents .remove-cell .row-cell-content .remove-icon.pucl-icon {
    margin-right: 10px; }
  .order-documents .remove-cell .row-cell-content .remove-text-disabled, .order-documents .remove-cell .row-cell-content .disabled {
    color: #888; }

.order-documents .table-no-results-found {
  padding-top: 60px; }

.order-detail-page > .tooltip__content,
.pucl-table-wrapper > .tooltip__content {
  z-index: 2005; }

.order-steps {
  display: flex;
  height: 40px;
  width: 100%;
  margin-left: -30px;
  background-color: white;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  position: fixed;
  z-index: 2004;
  border-bottom: 1px solid #d2d2d2; }
  .order-steps .container {
    padding-left: 0px;
    padding-right: 0px; }
  .order-steps .order-progress {
    font-family: SSTPro-Medium; }
    .order-steps .order-progress .number {
      padding: 3px 8px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
      background-color: #a8a5a5;
      color: #fff;
      text-decoration: none; }
    .order-steps .order-progress ul {
      height: 50px;
      margin: 0 auto;
      padding: 0;
      list-style-type: none;
      text-align: center; }
    .order-steps .order-progress li {
      display: inline-block;
      margin: 10px 20px;
      font-size: 14px;
      color: #a8a5a5; }
      .order-steps .order-progress li span {
        text-decoration: none;
        cursor: default; }
      .order-steps .order-progress li.active {
        color: #363636; }
        .order-steps .order-progress li.active .number {
          background-color: #363636; }
    .order-steps .order-progress .comment-icon {
      display: inline-block;
      margin-left: 5px;
      position: relative; }
      .order-steps .order-progress .comment-icon #message-icon-clickbox {
        cursor: pointer; }
        .order-steps .order-progress .comment-icon #message-icon-clickbox .pucl-icon {
          vertical-align: middle; }
      .order-steps .order-progress .comment-icon .comment-message-container {
        position: absolute;
        top: calc(100% + 6px);
        text-align: initial; }
      .order-steps .order-progress .comment-icon .pucl-message-board .close {
        font-size: 12px; }
  @media (max-width: 1199px) {
    .order-steps {
      width: 100%;
      -webkit-transform: translateY(15px);
              transform: translateY(15px); }
      .order-steps .orderSubmission {
        align-items: flex-start;
        margin-right: 8.5%; }
      .order-steps .fulfillment {
        align-items: center;
        margin-right: 8.5%; }
      .order-steps .Activation {
        align-items: flex-end;
        margin-right: 8.5%; } }

.order-detail-nav {
  height: 70px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-left: -30px;
  -webkit-transform: translateY(80px);
          transform: translateY(80px);
  box-shadow: rgba(0, 0, 0, 0.26) 0px 0px 15px;
  position: fixed;
  z-index: 2003; }
  .order-detail-nav .container {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px; }
  .order-detail-nav .order-detail-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .order-detail-nav .order-detail-navbar h3 {
      margin: 0px; }
    .order-detail-nav .order-detail-navbar .board-icon {
      display: flex; }
    .order-detail-nav .order-detail-navbar .buttons {
      display: flex;
      margin-top: auto; }
      .order-detail-nav .order-detail-navbar .buttons.save {
        text-align: right; }
      .order-detail-nav .order-detail-navbar .buttons.approve {
        display: flex;
        justify-content: right; }
        .order-detail-nav .order-detail-navbar .buttons.approve > button,
        .order-detail-nav .order-detail-navbar .buttons.approve .action-button {
          margin-right: 15px; }
          .order-detail-nav .order-detail-navbar .buttons.approve > button:last-child,
          .order-detail-nav .order-detail-navbar .buttons.approve .action-button:last-child {
            margin-right: 0px; }
      .order-detail-nav .order-detail-navbar .buttons .tooltip {
        opacity: 1;
        cursor: default !important; }
        .order-detail-nav .order-detail-navbar .buttons .tooltip:hover, .order-detail-nav .order-detail-navbar .buttons .tooltip:focus {
          text-decoration: none; }
    .order-detail-nav .order-detail-navbar .action-button {
      position: relative; }
      .order-detail-nav .order-detail-navbar .action-button .message-board-container {
        position: absolute;
        top: calc(100% + 6px); }
        .order-detail-nav .order-detail-navbar .action-button .message-board-container.amend {
          left: -40%; }
        .order-detail-nav .order-detail-navbar .action-button .message-board-container.reject {
          left: -120%; }
    .order-detail-nav .order-detail-navbar .pucl-message-board .close {
      font-size: 12px; }
    .order-detail-nav .order-detail-navbar .cancel {
      margin-right: 15px; }
    .order-detail-nav .order-detail-navbar .save {
      margin-right: 15px; }
    .order-detail-nav .order-detail-navbar .submit {
      margin-right: 15px; }
  @media (max-width: 1199px) {
    .order-detail-nav {
      width: 100%;
      -webkit-transform: translateY(50px);
              transform: translateY(50px); } }

.agency-order-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white; }
  .agency-order-page.order {
    padding-top: 185px; }
  .agency-order-page .link {
    text-decoration: none; }
    .agency-order-page .link:visited {
      color: #0072ce; }
  .agency-order-page .required:after {
    content: none; }
  .agency-order-page .info-container {
    padding: 0px 50px; }
    .agency-order-page .info-container .layout {
      margin: 50px 0px; }
      .agency-order-page .info-container .layout .flex-wrapper {
        display: flex;
        width: 100%; }
      .agency-order-page .info-container .layout .pucl-input-container {
        margin-bottom: 30px; }
      .agency-order-page .info-container .layout .pucl-select__field {
        margin-bottom: 30px; }
      .agency-order-page .info-container .layout .select-wrapper {
        width: 375px; }
    .agency-order-page .info-container .additional-cost-content {
      max-width: 200px;
      white-space: wrap; }
    .agency-order-page .info-container .static-text .label {
      font-weight: 500; }
    .agency-order-page .info-container .static-text .value {
      margin-top: auto; }
    .agency-order-page .info-container .manufacturing {
      display: flex;
      align-items: baseline;
      width: 70%; }
      .agency-order-page .info-container .manufacturing .tooltip {
        color: #616161;
        margin-left: 10px;
        opacity: 1; }
        .agency-order-page .info-container .manufacturing .tooltip .total-tax-content {
          max-width: 200px;
          white-space: wrap; }
      .agency-order-page .info-container .manufacturing .tooltip:hover,
      .agency-order-page .info-container .manufacturing .tooltip:active {
        text-decoration: none; }
      .agency-order-page .info-container .manufacturing .static-text {
        width: unset;
        margin-bottom: 30px; }
        .agency-order-page .info-container .manufacturing .static-text .value {
          margin-top: 15px; }
      .agency-order-page .info-container .manufacturing .pucl-select {
        -webkit-transform: translateY(40px);
                transform: translateY(40px); }
      .agency-order-page .info-container .manufacturing .pucl-input.pucl-input-wrapper {
        -webkit-transform: translateX(-75px);
                transform: translateX(-75px);
        margin-bottom: 30px; }
        .agency-order-page .info-container .manufacturing .pucl-input.pucl-input-wrapper .pucl-label {
          font-weight: 500; }
    .agency-order-page .info-container .total-tax {
      display: flex;
      width: 70%; }
      .agency-order-page .info-container .total-tax .tooltip {
        color: #616161;
        margin-left: 10px;
        opacity: 1; }
        .agency-order-page .info-container .total-tax .tooltip .total-tax-content {
          max-width: 200px;
          white-space: wrap; }
      .agency-order-page .info-container .total-tax .tooltip:hover,
      .agency-order-page .info-container .total-tax .tooltip:active {
        text-decoration: none; }
      .agency-order-page .info-container .total-tax .static-text {
        width: unset;
        margin-bottom: 30px; }
        .agency-order-page .info-container .total-tax .static-text .value {
          margin-top: 15px; }
      .agency-order-page .info-container .total-tax .pucl-select {
        -webkit-transform: translateY(40px);
                transform: translateY(40px); }
      .agency-order-page .info-container .total-tax .pucl-input.pucl-input-wrapper {
        -webkit-transform: translateX(-75px);
                transform: translateX(-75px);
        margin-bottom: 30px; }
        .agency-order-page .info-container .total-tax .pucl-input.pucl-input-wrapper .pucl-label {
          font-weight: 500; }
    .agency-order-page .info-container .pucl-checkbox {
      margin-top: 15px;
      margin-bottom: 20px; }
    .agency-order-page .info-container .pucl-input--selection-controls__input .pucl-label {
      font-weight: 500;
      margin-top: 5px; }
      .agency-order-page .info-container .pucl-input--selection-controls__input .pucl-label:before {
        top: 0.2rem; }
    .agency-order-page .info-container .warning {
      display: flex;
      align-items: baseline; }
      .agency-order-page .info-container .warning .pucl-icon {
        margin-right: 10px;
        color: #616161; }

.agency-order-contents .pucl-data-table .cell-container.first .row-cell {
  margin-left: 20px; }

.agency-order-contents .cell-container.first div.row-cell .has-checkbox {
  margin-left: -40px; }

.agency-order-contents .cell-container.first div.row-cell .checkbox {
  margin-top: 0px; }

.agency-order-contents .cell-container.first .info-icon .tooltip {
  color: #616161; }

.agency-order-contents .cell-container .truncate {
  color: #000;
  font-size: 14px;
  font-family: 'SSTPRO-Roman'; }

.agency-order-contents .pucl-input-field-container {
  width: 60%; }

.agency-order-contents .pucl-data-table .header-cell .content {
  padding-left: 0px; }
  .agency-order-contents .pucl-data-table .header-cell .content .tooltip {
    color: #616161;
    opacity: 1;
    margin-left: 5px; }
    .agency-order-contents .pucl-data-table .header-cell .content .tooltip .info-content {
      max-width: 200px;
      white-space: wrap; }
  .agency-order-contents .pucl-data-table .header-cell .content .tooltip:hover,
  .agency-order-contents .pucl-data-table .header-cell .content .tooltip:focus,
  .agency-order-contents .pucl-data-table .header-cell .content .tooltip:active {
    text-decoration: none; }
  .agency-order-contents .pucl-data-table .header-cell .content .required {
    margin-right: 5px;
    color: #EF5A75;
    font-family: "SSTPro-Medium"; }

.agency-order-contents .pucl-data-table .header-cell.can-remove .header-cell-wrapper .content {
  visibility: hidden; }

.agency-order-contents .pucl-data-table .header-cell.last {
  border-left: none; }

.agency-order-contents .pucl-data-table .cell-container .row-cell {
  height: 70px; }

.agency-order-contents .pucl-data-table .cell-container.delete-icon .remove-text {
  padding-left: 5px; }

.agency-order-contents .pucl-data-table .order-line-cell {
  font-size: 14px; }
  .agency-order-contents .pucl-data-table .order-line-cell .tooltip {
    opacity: 1; }
  .agency-order-contents .pucl-data-table .order-line-cell .tooltip:hover {
    text-decoration: none; }

.agency-order-contents .table-no-results-found {
  padding-top: 60px; }

.order .tooltip {
  opacity: 1;
  text-decoration: none; }

.order .order-header {
  height: 70px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-left: -30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
  position: fixed;
  z-index: 2000; }
  .order .order-header .container {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 1270px; }
  @media (max-width: 1199px) {
    .order .order-header {
      margin-top: 20px; } }
  .order .order-header .order-title {
    display: flex;
    align-items: center;
    font-size: 26px; }
    .order .order-header .order-title h3 {
      margin: 0px; }

.order .order-sections {
  padding-top: 140px; }
  .order .order-sections .order-info-section .order-info-free-allocation-content {
    padding: 50px; }
    .order .order-sections .order-info-section .order-info-free-allocation-content .item-title {
      line-height: 18px;
      font-weight: 700;
      font-size: 14px;
      color: #363636;
      padding-bottom: 15px; }
    .order .order-sections .order-info-section .order-info-free-allocation-content .item-value a {
      text-decoration: none; }
    .order .order-sections .order-info-section .order-info-free-allocation-content .item-value a:visited {
      color: #0072CE; }
  .order .order-sections .order-contents-section .header-cell .header-cell-wrapper .content {
    padding-left: 0px; }
  .order .order-sections .order-contents-section .header-cell.codeProduct.first .header-cell-wrapper {
    margin-left: 10px; }
  .order .order-sections .order-contents-section .header-cell.country .header-cell-wrapper .content {
    word-break: break-all;
    white-space: normal; }
  .order .order-sections .order-contents-section .cell-container.order-line-3p.codeProductName.first .row-cell-content {
    padding-left: 10px; }
    .order .order-sections .order-contents-section .cell-container.order-line-3p.codeProductName.first .row-cell-content a:hover {
      text-decoration: none; }
    .order .order-sections .order-contents-section .cell-container.order-line-3p.codeProductName.first .row-cell-content a:visited {
      text-decoration: none;
      color: #0072CE; }
  .order .order-sections .order-contents-section .cell-container.order-line-3p.fulfillment .row-cell-content .pucl-loader {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 6px 10px 0px 10px; }

.permitted-uses-order-nav {
  height: 70px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-left: -30px;
  padding: 0px 70px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
  position: fixed;
  z-index: 2000; }
  .permitted-uses-order-nav .container {
    padding-left: 0px;
    padding-right: 0px; }
  @media (max-width: 1199px) {
    .permitted-uses-order-nav {
      margin-top: 20px; } }
  .permitted-uses-order-nav .order-title {
    display: flex;
    align-items: center;
    font-size: 26px; }
    .permitted-uses-order-nav .order-title h3 {
      margin: 0px; }
  .permitted-uses-order-nav .divider {
    height: 26px;
    width: 1px;
    margin-left: 20px;
    border-left: 1px solid #E7E7E7; }
  .permitted-uses-order-nav .status {
    height: 50%;
    margin-top: 2px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    font-size: 14px; }
    .permitted-uses-order-nav .status .status-label {
      font-family: 'SSTPro-Light'; }
    .permitted-uses-order-nav .status .value {
      margin-left: 6px;
      font-family: 'SSTPro-Medium'; }
      .permitted-uses-order-nav .status .value > div {
        display: inline; }
      .permitted-uses-order-nav .status .value #current-comment {
        font-weight: initial; }
      .permitted-uses-order-nav .status .value .comment-icon {
        margin-left: 5px;
        position: relative; }
        .permitted-uses-order-nav .status .value .comment-icon #message-icon-clickbox {
          cursor: pointer; }
          .permitted-uses-order-nav .status .value .comment-icon #message-icon-clickbox .pucl-icon {
            vertical-align: middle; }
        .permitted-uses-order-nav .status .value .comment-icon .comment-message-container {
          position: absolute;
          top: calc(100% + 6px); }
  .permitted-uses-order-nav .buttons.save {
    text-align: right; }
  .permitted-uses-order-nav .buttons.approve {
    display: flex;
    justify-content: right; }
    .permitted-uses-order-nav .buttons.approve > button, .permitted-uses-order-nav .buttons.approve .action-button {
      margin-right: 15px; }
      .permitted-uses-order-nav .buttons.approve > button:last-child, .permitted-uses-order-nav .buttons.approve .action-button:last-child {
        margin-right: 0px; }
  .permitted-uses-order-nav .action-button {
    position: relative; }
    .permitted-uses-order-nav .action-button .message-board-container {
      position: absolute;
      top: calc(100% + 6px); }
      .permitted-uses-order-nav .action-button .message-board-container.amend {
        left: -40%; }
      .permitted-uses-order-nav .action-button .message-board-container.reject {
        left: -120%; }
  .permitted-uses-order-nav .pucl-message-board .close {
    font-size: 12px; }

.permitted-uses-order {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px; }
  .permitted-uses-order .link {
    text-decoration: none; }
    .permitted-uses-order .link:visited {
      color: #0072CE; }
  .permitted-uses-order .info-container {
    padding: 0px 70px; }
    .permitted-uses-order .info-container .layout {
      margin: 60px 0px; }
      .permitted-uses-order .info-container .layout .flex-wrapper {
        display: flex;
        width: 100%; }
    .permitted-uses-order .info-container .static-text .label {
      font-weight: 500; }
    .permitted-uses-order .info-container .po-number {
      display: flex;
      align-items: center;
      justify-content: start;
      width: 70%; }
      .permitted-uses-order .info-container .po-number .pucl-input .pucl-label + .pucl-form-input {
        z-index: 0; }
      .permitted-uses-order .info-container .po-number .pucl-input.pucl-input-wrapper .pucl-label {
        font-weight: 500; }
      .permitted-uses-order .info-container .po-number .pucl-input.pucl-input-wrapper .pucl-input-container {
        width: 100%; }
      .permitted-uses-order .info-container .po-number .pucl-input.pucl-input-wrapper .pucl-input-field-container {
        width: 100%; }
      .permitted-uses-order .info-container .po-number .po-download-icon, .permitted-uses-order .info-container .po-number .po-error-icon, .permitted-uses-order .info-container .po-number .po-pending {
        margin-top: 30px; }
        .permitted-uses-order .info-container .po-number .po-download-icon .tooltip, .permitted-uses-order .info-container .po-number .po-error-icon .tooltip, .permitted-uses-order .info-container .po-number .po-pending .tooltip {
          margin-left: 15px;
          opacity: 1;
          z-index: unset !important; }
          .permitted-uses-order .info-container .po-number .po-download-icon .tooltip #download-po, .permitted-uses-order .info-container .po-number .po-download-icon .tooltip #error-po, .permitted-uses-order .info-container .po-number .po-error-icon .tooltip #download-po, .permitted-uses-order .info-container .po-number .po-error-icon .tooltip #error-po, .permitted-uses-order .info-container .po-number .po-pending .tooltip #download-po, .permitted-uses-order .info-container .po-number .po-pending .tooltip #error-po {
            /* margin and font-size applied here because material-icon styles currently overwrite pucl size prop */
            margin-top: 4px;
            font-size: 18px;
            color: #616161; }
          .permitted-uses-order .info-container .po-number .po-download-icon .tooltip #error-po, .permitted-uses-order .info-container .po-number .po-error-icon .tooltip #error-po, .permitted-uses-order .info-container .po-number .po-pending .tooltip #error-po {
            color: #C5474B; }
    .permitted-uses-order .info-container .order-comments {
      margin-top: 30px; }
      .permitted-uses-order .info-container .order-comments .pucl-label {
        font-weight: 500; }
    .permitted-uses-order .info-container .pucl-checkbox {
      margin-top: 15px;
      margin-bottom: 20px; }
    .permitted-uses-order .info-container .disc-substitute {
      display: flex;
      align-items: baseline;
      width: 15%;
      margin-bottom: 30px; }
      .permitted-uses-order .info-container .disc-substitute .currency-value {
        margin-left: 10px; }
      .permitted-uses-order .info-container .disc-substitute .per-code-fee-label {
        margin-left: 10px;
        white-space: nowrap; }
    .permitted-uses-order .info-container .pucl-input--selection-controls__input .pucl-label {
      font-weight: 500; }

.third-party-order-contents {
  white-space: nowrap; }
  .third-party-order-contents .isDisabledSwap .pucl-collapsible-container .pucl-collapsible .pucl-rightContent {
    display: none; }
    .third-party-order-contents .isDisabledSwap .pucl-collapsible-container .pucl-collapsible .pucl-rightContent button {
      color: #999999;
      border-color: #999999;
      cursor: not-allowed;
      pointer-events: none; }
  .third-party-order-contents .tooltip-container {
    height: 100%;
    width: 65px;
    display: flex;
    align-items: center; }
  .third-party-order-contents .tooltip {
    opacity: 1;
    display: inline !important;
    margin-top: 2px;
    font-size: 14px;
    z-index: unset !important; }
    .third-party-order-contents .tooltip:hover, .third-party-order-contents .tooltip:focus {
      text-decoration: none; }
  .third-party-order-contents .header-cell-wrapper .use-count {
    width: 220px; }
  .third-party-order-contents .header-cell-wrapper .content {
    padding: 0px; }
  .third-party-order-contents .header-cell-wrapper .info-icon {
    color: #616161;
    margin-left: 10px; }
  .third-party-order-contents .order-line-cell.first .row-cell-content {
    padding-left: 20px; }
  .third-party-order-contents .order-line-cell.autocanceled {
    background: #eee;
    color: #aaa; }
    .third-party-order-contents .order-line-cell.autocanceled a {
      color: #aaa; }
  .third-party-order-contents .order-line-cell.first-cell .cell-preface.has-checkbox {
    margin-right: 0px; }
    .third-party-order-contents .order-line-cell.first-cell .cell-preface.has-checkbox .checkbox {
      margin-top: 0px;
      margin-bottom: 0px; }
  .third-party-order-contents .order-line-cell.first-cell .tooltip {
    right: 20px; }
  .third-party-order-contents .order-line-cell .warning-icon {
    color: red; }
  .third-party-order-contents .order-line-cell .iconPlacement {
    margin-right: 10px;
    display: flex; }
    .third-party-order-contents .order-line-cell .iconPlacement a {
      position: static !important; }
  .third-party-order-contents .order-line-cell .batch-filename-copy {
    margin-left: 20px;
    margin-right: 15px;
    height: 100%;
    display: flex;
    align-items: center; }
    .third-party-order-contents .order-line-cell .batch-filename-copy #copy-file-name {
      color: #616161; }
      .third-party-order-contents .order-line-cell .batch-filename-copy #copy-file-name::before {
        font-size: 13px; }
  .third-party-order-contents .order-line-cell .pucl-date {
    min-width: 120px; }
  .third-party-order-contents .order-line-cell .time-input {
    margin-left: 10px;
    width: 126px; }
  .third-party-order-contents .order-line-cell .date-tooltip-wrapper {
    width: 20px;
    margin-left: 10px;
    z-index: 10; }
    .third-party-order-contents .order-line-cell .date-tooltip-wrapper #start-date-error.tooltip__content,
    .third-party-order-contents .order-line-cell .date-tooltip-wrapper #end-date-error.tooltip__content {
      bottom: 17px !important; }
    .third-party-order-contents .order-line-cell .date-tooltip-wrapper .pucl-icon {
      color: #b00c12; }
  .third-party-order-contents .order-line-cell#startDate .tooltip, .third-party-order-contents .order-line-cell#endDate .tooltip {
    position: relative !important;
    z-index: unset !important; }
    .third-party-order-contents .order-line-cell#startDate .tooltip:hover, .third-party-order-contents .order-line-cell#endDate .tooltip:hover {
      text-decoration: none; }
  .third-party-order-contents .order-line-cell .render-status {
    display: flex; }
    .third-party-order-contents .order-line-cell .render-status .pucl-loader {
      margin-top: 3px;
      margin-left: 6px; }
  .third-party-order-contents .kabob__menu .kabob__icon {
    font-size: 15px !important; }

.third-party-order-history .header-cell .content {
  padding-left: 0px; }

.third-party-order-history .cell-container.first {
  padding-left: 20px; }

.third-party-order-history .cell-container .status-icon {
  display: inline-block;
  padding: 0px 10px; }
  .third-party-order-history .cell-container .status-icon.rotate {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.third-party-order-history .cell-container .status-wrap {
  margin-top: -20px; }
  .third-party-order-history .cell-container .status-wrap a:hover, .third-party-order-history .cell-container .status-wrap a:focus {
    text-decoration: none; }
  .third-party-order-history .cell-container .status-wrap .tooltip {
    opacity: 1; }
    .third-party-order-history .cell-container .status-wrap .tooltip .truncate {
      width: 362px;
      line-height: 20px;
      font-size: 14px;
      font-family: 'SSTPro-Roman';
      color: #363636;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .third-party-order-history .cell-container .status-wrap .tooltip .order-history-comment-tooltip {
      max-width: 300px; }

.error-page {
  padding-top: 200px; }
  .error-page .canceled-tos-content .layout {
    flex-direction: column;
    align-items: center; }
    .error-page .canceled-tos-content .layout .astro-sitting-playing-360 {
      width: 300px;
      margin-top: 100px; }
  .error-page .error-page-layout {
    flex-direction: column; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg); }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg); } }
@keyframes spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg); }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg); } }

@-webkit-keyframes spinw {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

html, body, #root, .App, #root > div {
  min-height: 100%;
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #ffffff; }
  body .content {
    padding: 0 30px; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

li span {
  text-decoration: underline;
  cursor: pointer; }

hr {
  border-color: #000; }

.required:after {
  content: ' *';
  color: red; }

tr.isNew {
  background: lightblue; }

tr.hasError {
  color: #999999; }

select.form-control[disabled] {
  pointer-events: none; }

.no-wrap {
  white-space: nowrap; }

div.App.container,
nav.order-nav .container,
nav.partner-nav .container,
nav.global-header .container,
nav.venom-header .container,
div.orders-header .container,
div.users-header .container,
div.search-header .container,
div.partners-header .container
div.vouchers-header .container {
  width: calc(100% - 60px); }

nav.user-nav .container,
nav.order-nav .container,
nav.global-header .container,
nav.venom-header .container,
nav.partners-nav .container {
  padding: 0; }

div.order.container,
div.order-info-body .container,
div.order-list.container,
div.partner.container,
div.search.container,
div.user-list.container,
div.user.container,
div.partner-list.container {
  width: 100%; }

@media (min-width: 1200px) {
  .page-container .container {
    width: 96%; } }

.sie-notification:first-child {
  margin-top: 110px; }

.sie-notification__content {
  text-align: center; }

.codes .pucl-notification-container {
  top: 60px;
  z-index: 9001;
  position: fixed; }
  .codes .pucl-notification-container .voucher-typeId-name-link {
    color: white;
    text-decoration: underline; }
    .codes .pucl-notification-container .voucher-typeId-name-link:hover {
      color: #0072ce; }

@media (max-width: 1199px) {
  .codes > .content > .container {
    margin-top: -20px; } }

.tooltip {
  cursor: pointer !important; }

div[role="dialog"] .modal {
  margin-top: 100px; }

.voucher-only-product {
  height: 16px;
  width: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  font-size: 11px;
  background-color: #636365;
  color: #ffffff;
  margin-right: 5px; }

.pucl-notification--sucess {
  background-color: #2d9e94; }

.catalog {
  padding-top: 40px; }
  .catalog .header-cell .ico-select-arrow {
    opacity: 0; }
    .catalog .header-cell .ico-select-arrow.selected {
      opacity: 100; }
  .catalog .header-cell-wrapper .content {
    padding-left: 0px; }
  .catalog .indicator-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 150px auto; }
  .catalog .catalog-nav {
    background: white;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100px;
    left: 0px;
    margin: 0px;
    width: 100%;
    padding: 5px 45px;
    z-index: 2002;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px; }
    .catalog .catalog-nav .catalog-nav-content {
      display: inline-flex;
      min-width: 1270px; }
      .catalog .catalog-nav .catalog-nav-content .catalog-title {
        padding-left: 10px; }
      .catalog .catalog-nav .catalog-nav-content .return-nav-button {
        margin: 10px; }
      .catalog .catalog-nav .catalog-nav-content .return-nav-button {
        margin: 10px; }
  .catalog .pucl-table-wrapper {
    margin-top: 130px;
    margin-bottom: 50px; }
    .catalog .pucl-table-wrapper .pucl-action-bar {
      z-index: inherit; }
      .catalog .pucl-table-wrapper .pucl-action-bar .filters .filter {
        width: 300px; }
      .catalog .pucl-table-wrapper .pucl-action-bar .action-bar-filter-button-container .tooltip {
        opacity: 1; }
      .catalog .pucl-table-wrapper .pucl-action-bar .action-bar-filter-button-container .tooltip:hover {
        text-decoration: none; }
    .catalog .pucl-table-wrapper .row-cell.checkbox {
      margin-top: 0px; }
  .catalog .no-vouchers {
    text-align: center; }
  .catalog .code-products-paginator {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 45px;
    margin-bottom: 145px;
    display: flex;
    justify-content: space-between; }
    .catalog .code-products-paginator .pucl-select-container {
      min-width: 80px; }
    .catalog .code-products-paginator .page-link {
      text-decoration: none; }
    .catalog .code-products-paginator .sr-only {
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit; }
    .catalog .code-products-paginator .sie-select__field .sie-select-arrow {
      color: #999999; }

.partners-page {
  padding-top: 40px; }
  .partners-page .partners-list {
    margin-top: 40px;
    margin-bottom: 65px; }
  .partners-page .pucl-tag .tag-icon {
    width: 14px !important; }
  .partners-page .pucl-data-table .header-cell-wrapper .content {
    padding-left: 0px; }
  .partners-page .pucl-data-table .cell-container.first-column .row-cell {
    margin-left: 20px !important; }
    .partners-page .pucl-data-table .cell-container.first-column .row-cell a {
      text-decoration: none; }
    .partners-page .pucl-data-table .cell-container.first-column .row-cell a:active {
      color: #0072ce; }
    .partners-page .pucl-data-table .cell-container.first-column .row-cell a:visited {
      color: #0072ce; }
  .partners-page .pucl-data-table .cell-container.status .row-cell-content .pucl-tag.valid .tag-icon {
    background-color: #2d9e94; }
  .partners-page .pucl-data-table .cell-container.status .row-cell-content .pucl-tag.inactive .tag-icon {
    background-color: #767676; }
  .partners-page .partners-paginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between; }
    .partners-page .partners-paginator .pucl-select-container {
      min-width: 80px; }
    .partners-page .partners-paginator .page-link {
      text-decoration: none; }
    .partners-page .partners-paginator .sie-select__field .sie-select-arrow {
      color: #999999; }
    .partners-page .partners-paginator .sr-only {
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit; }

.partner {
  padding-top: 69px; }
  .partner .partner-nav {
    height: 60px;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    margin-left: -30px;
    position: fixed;
    top: 100px;
    z-index: 1000;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3); }
    .partner .partner-nav .title {
      font-size: 26px;
      font-family: 'SSTPro-Light'; }
    @media (max-width: 1200px) {
      .partner .partner-nav {
        top: 80px; } }
  .partner .pucl-collapsible-container {
    max-width: 100%; }
    .partner .pucl-collapsible-container .rah-static {
      max-width: 100%; }
  .partner .form-horizontal label.control-label {
    text-align: left;
    font-weight: normal; }
  .partner .form-group {
    margin-bottom: 30px; }
  .partner .partner-info {
    margin-top: 70px; }
    .partner .partner-info .pucl-collapsible-container {
      max-width: 1300px;
      margin-bottom: 40px; }
      .partner .partner-info .pucl-collapsible-container .container {
        width: auto;
        max-width: 1300px;
        padding: 0 70px; }
        .partner .partner-info .pucl-collapsible-container .container .pucl-divider {
          border: none; }
        .partner .partner-info .pucl-collapsible-container .container .label {
          font-family: 'SSTPro-Roman';
          font-size: 14px; }
        .partner .partner-info .pucl-collapsible-container .container .value {
          font-family: 'SSTPro-Medium';
          font-size: 14px;
          word-wrap: break-word; }
      .partner .partner-info .pucl-collapsible-container .pucl-collapsible {
        pointer-events: none; }
        .partner .partner-info .pucl-collapsible-container .pucl-collapsible .pucl-rightContent .pucl-collapsible-icon {
          visibility: hidden; }
  .partner .pucl-table-wrapper {
    box-shadow: none; }
    .partner .pucl-table-wrapper .pucl-data-table {
      min-height: 367px; }
  .partner .partner-users .pucl-collapsible-container {
    margin-bottom: 40px;
    max-width: 1300px; }
  .partner .users-wrapper .pucl-collapsible-container .pucl-scrollarea--vertical-track {
    padding-bottom: 220px; }
  .partner .partner-agreements .pucl-collapsible-container {
    margin-bottom: 40px;
    max-width: 1300px; }
  .partner .agreements-wrapper .pucl-collapsible-container .pucl-scrollarea--vertical-track {
    padding-bottom: 220px; }
  .partner .partner-sftps .pucl-collapsible-container {
    margin-bottom: 100px;
    max-width: 1300px; }

@media (min-width: 1200px) {
  .container {
    width: 96%; } }

.partner-detail-agreements-grid {
  margin: 0px;
  padding: 0px;
  width: 100%; }
  .partner-detail-agreements-grid .partner-detail-agreements-row {
    margin: 0px; }
    .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col {
      margin: 0px;
      padding: 0px; }
      .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col .disable-link {
        color: #363636; }
      .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col .pucl-loader {
        margin-top: 100px !important; }
      .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col div .pucl-data-table .header-cell.first .content {
        padding-left: 0px; }
      .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col div .pucl-data-table .header-cell:not(.first) .content {
        padding-left: 0px; }
      .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col div .pucl-data-table .cell-container.first {
        padding-left: 20px; }
      .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col div .pucl-data-table .cell-container.agreementStatus .row-cell-content span {
        margin-right: 6px; }
      .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col div .pucl-data-table .header-cell-status .sort-icons {
        padding-right: 10px; }
      .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col div .header-cell-agreementId {
        pointer-events: none; }
      .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col div .header-cell-countryCoverage {
        pointer-events: none; }
      .partner-detail-agreements-grid .partner-detail-agreements-row .partner-detail-agreements-col div .header-cell-voucherProductTypeCoverage {
        pointer-events: none; }

.partner-detail-agreements-paginator {
  margin: 30px;
  display: flex;
  padding-bottom: 115px;
  justify-content: space-between; }
  .partner-detail-agreements-paginator .pucl-select-container {
    min-width: 80px; }
  .partner-detail-agreements-paginator .page-link {
    text-decoration: none; }
  .partner-detail-agreements-paginator .sr-only {
    width: 9px !important;
    height: 12px !important;
    margin: 0;
    position: inherit; }

.partner-users .partner-detail-users-grid {
  width: 100%;
  margin: 0px; }
  .partner-users .partner-detail-users-grid .partner-detail-users-header-col {
    padding: 0px; }
    .partner-users .partner-detail-users-grid .partner-detail-users-header-col .pucl-loader {
      margin-top: 100px !important; }
    .partner-users .partner-detail-users-grid .partner-detail-users-header-col div .pucl-data-table .header-cell.first .content {
      padding-left: 0px; }
    .partner-users .partner-detail-users-grid .partner-detail-users-header-col div .pucl-data-table .header-cell:not(.first) .content {
      padding-left: 0px; }
    .partner-users .partner-detail-users-grid .partner-detail-users-header-col div .pucl-data-table .cell-container.first {
      padding-left: 20px; }
    .partner-users .partner-detail-users-grid .partner-detail-users-header-col div .pucl-data-table .header-cell-pgpKeys-hidden {
      display: none; }
    .partner-users .partner-detail-users-grid .partner-detail-users-header-col div .pucl-data-table .header-cell-role {
      pointer-events: none; }
    .partner-users .partner-detail-users-grid .partner-detail-users-header-col div .pucl-data-table .header-cell-pgpKeys {
      pointer-events: none; }
    .partner-users .partner-detail-users-grid .partner-detail-users-header-col div .pucl-data-table .header-cell-status .sort-icons {
      padding-right: 10px; }

.partner-users .new-pgpKey {
  color: #003597;
  font-weight: 500;
  padding: 0 !important;
  margin-top: -8px;
  text-decoration: none !important; }

.partner-users .partner-detail-users-pgp-keys {
  text-decoration: none !important; }

.partner-users .pgp-button-label {
  width: 100%;
  display: flex;
  align-items: center;
  color: #003597; }

.partner-users .pgp-key-add-plus-button {
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border: 1px solid #003597;
  border-radius: 50%;
  font-size: 13px; }

.partner-users .partner-detail-users-paginator {
  margin: 30px;
  display: flex;
  padding-bottom: 15px;
  justify-content: space-between; }
  .partner-users .partner-detail-users-paginator .pucl-select-container {
    min-width: 80px; }
  .partner-users .partner-detail-users-paginator .page-link {
    text-decoration: none; }
  .partner-users .partner-detail-users-paginator .sr-only {
    width: 7.5px !important;
    height: 12px !important;
    margin: 0;
    position: inherit; }

.sftp-status {
  text-transform: capitalize; }
  .sftp-status span:first-child {
    display: inline-block;
    border-radius: 100px;
    border: 1px solid #000;
    width: 12px;
    height: 12px;
    position: relative;
    vertical-align: baseline; }
  .sftp-status.ACTIVE span:first-child {
    background: green;
    border-color: green;
    color: #fff;
    font-size: 11px;
    margin-right: 6px; }
    .sftp-status.ACTIVE span:first-child::before {
      margin-left: -1px; }
  .sftp-status.INACTIVE span:first-child {
    background: red;
    border-color: red;
    color: #fff;
    font-size: 11px;
    margin-right: 6px; }
    .sftp-status.INACTIVE span:first-child::before {
      margin-left: -1px; }
  .sftp-status.DELETED span:first-child {
    background: #999;
    border-color: #999;
    color: #fff;
    font-size: 11px;
    margin-right: 6px; }
    .sftp-status.DELETED span:first-child::before {
      margin-left: -1px; }

.partner-detail-sftp-grid {
  width: 100%; }
  .partner-detail-sftp-grid .partner-detail-sftp-row .partner-detail-sftp-col {
    padding: 0px; }
    .partner-detail-sftp-grid .partner-detail-sftp-row .partner-detail-sftp-col .pucl-loader {
      margin-top: 100px !important; }
    .partner-detail-sftp-grid .partner-detail-sftp-row .partner-detail-sftp-col div .pucl-data-table .header-cell.first .content {
      padding-left: 0px; }
    .partner-detail-sftp-grid .partner-detail-sftp-row .partner-detail-sftp-col div .pucl-data-table .header-cell:not(.first) .content {
      padding-left: 0px; }
    .partner-detail-sftp-grid .partner-detail-sftp-row .partner-detail-sftp-col div .pucl-data-table .cell-container.first {
      padding-left: 20px; }

.agreement {
  padding-top: 140px;
  padding-bottom: 35px; }
  .agreement .agreement-header-wrapper {
    background: white;
    display: flex;
    position: fixed;
    top: 100px;
    left: 0;
    padding-left: 140px;
    width: 100%;
    height: 59px;
    z-index: 2101;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 15px;
    align-items: center;
    justify-content: center; }
    .agreement .agreement-header-wrapper .agreement-header {
      height: inherit;
      display: flex;
      align-items: center;
      min-width: 1380px; }
      .agreement .agreement-header-wrapper .agreement-header .agreement-header-title {
        display: contents; }
        .agreement .agreement-header-wrapper .agreement-header .agreement-header-title h3 {
          margin-top: 0px;
          margin-bottom: 0px; }
        .agreement .agreement-header-wrapper .agreement-header .agreement-header-title .agreement-version-selector {
          width: 140px;
          padding-left: 10px; }
      .agreement .agreement-header-wrapper .agreement-header .agreement-header-buttons {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-left: 50px; }
        .agreement .agreement-header-wrapper .agreement-header .agreement-header-buttons .agreement-search {
          justify-content: end;
          padding-right: 15px; }
          .agreement .agreement-header-wrapper .agreement-header .agreement-header-buttons .agreement-search .text-input-row-flex-wrapper {
            padding-top: 6px; }
        .agreement .agreement-header-wrapper .agreement-header .agreement-header-buttons .save-agreement-button {
          width: 100px; }
  .agreement .layout.agreement-information-layout .agreement-information-content {
    padding: 30px; }
    .agreement .layout.agreement-information-layout .agreement-information-content .info-wrap {
      width: 400px;
      margin: 0 auto; }
      .agreement .layout.agreement-information-layout .agreement-information-content .info-wrap .pucl-input.pucl-input-wrapper {
        padding-bottom: 20px; }
      .agreement .layout.agreement-information-layout .agreement-information-content .info-wrap .pucl-radio-group.required::after {
        display: none; }
    .agreement .layout.agreement-information-layout .agreement-information-content .agreement-version-wrap .agreement-version-content {
      width: 400px;
      margin: 0 auto; }
      .agreement .layout.agreement-information-layout .agreement-information-content .agreement-version-wrap .agreement-version-content .pucl-input.pucl-input-wrapper {
        padding-bottom: 20px; }
      .agreement .layout.agreement-information-layout .agreement-information-content .agreement-version-wrap .agreement-version-content .pucl-date.form-field .text-input-row-flex-wrapper .pucl-input-field-container {
        width: 400px; }
      .agreement .layout.agreement-information-layout .agreement-information-content .agreement-version-wrap .agreement-version-content .pucl-date.form-field .pucl-input .tooltip {
        opacity: 1; }
      .agreement .layout.agreement-information-layout .agreement-information-content .agreement-version-wrap .agreement-version-content .pucl-date.form-field .pucl-input .tooltip:hover {
        text-decoration: none; }
    .agreement .layout.agreement-information-layout .agreement-information-content .agreement-version-wrap .pucl-input.pucl-input-wrapper.pucl-text-area {
      margin: 0 auto; }
      .agreement .layout.agreement-information-layout .agreement-information-content .agreement-version-wrap .pucl-input.pucl-input-wrapper.pucl-text-area > .pucl-input.pucl-text-field.form-field {
        min-width: 400px; }
        .agreement .layout.agreement-information-layout .agreement-information-content .agreement-version-wrap .pucl-input.pucl-input-wrapper.pucl-text-area > .pucl-input.pucl-text-field.form-field .text-area-input {
          width: 100%; }
  .agreement .agreement-coverage-layout .invoice-timing-content .invoice-timing-layout {
    padding-top: 50px; }
  .agreement .agreement-change-history-layout .cell-container.first .version-number-link {
    color: #0072ce;
    font-size: 14px;
    line-height: 20px;
    font-family: "SSTPro-Medium";
    padding-left: 20px; }

.firstParty-voucherCoverageTable-container .note {
  padding: 10px 0px 5px 10px; }

.firstParty-voucherCoverageTable-container .tooltip {
  opacity: 1;
  font-size: 16px; }

.agency-coverage-table-container {
  margin-top: 30px; }
  .agency-coverage-table-container .pucl-collapsible-container {
    margin-bottom: 0px; }
  .agency-coverage-table-container .pucl-data-table {
    padding-bottom: 249px; }
  .agency-coverage-table-container .row-cell.checkbox {
    margin-top: 0px; }

.free-allocation-agreement-header {
  height: 70px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-left: -30px;
  padding: 0px 70px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
  position: fixed;
  z-index: 2000; }
  .free-allocation-agreement-header .title {
    display: flex;
    align-items: center; }
    .free-allocation-agreement-header .title h3 {
      margin: 0px; }
  .free-allocation-agreement-header .divider {
    height: 26px;
    width: 1px;
    margin-left: 20px;
    border-left: 1px solid #E7E7E7; }
  .free-allocation-agreement-header .version {
    height: 50%;
    margin-left: 20px;
    align-items: center; }

.agreement.free-allocation {
  padding-top: 150px;
  padding-bottom: 35px;
  flex-direction: column; }
  .agreement.free-allocation.container,
  .agreement.free-allocation .container {
    padding-left: 0px;
    padding-right: 0px; }
  .agreement.free-allocation .content {
    padding: inherit; }
  .agreement.free-allocation > .layout {
    margin: 0px auto;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content; }
    .agreement.free-allocation > .layout .content-details {
      margin: 60px; }
  .agreement.free-allocation .pucl-data-table .header-cell .pucl-checkbox .pucl-input--selection-controls__input input {
    cursor: not-allowed; }
  .agreement.free-allocation .pucl-data-table .header-cell .pucl-checkbox .pucl-label::before {
    background-color: #d2d2d2; }
    .agreement.free-allocation .pucl-data-table .header-cell .pucl-checkbox .pucl-label::before:hover {
      cursor: not-allowed; }
  .agreement.free-allocation .pucl-data-table .cell-container.first .row-cell .cell-preface.has-checkbox.has-expandable {
    margin-right: 0px; }
  .agreement.free-allocation .pucl-data-table .cell-container.first .row-cell .cell-preface .checkbox ~ .hide-icon {
    padding: 0 10px; }
  .agreement.free-allocation .changeHistory .pucl-data-table .cell-container.first .row-cell {
    padding-left: 19px; }
  .agreement.free-allocation .pucl-collapsible-container .pucl-nested > form {
    background-color: white; }
  .agreement.free-allocation .pucl-input label.pucl-label, .agreement.free-allocation .pucl-date label.pucl-label {
    font-weight: initial; }
  .agreement.free-allocation .pucl-input:not(:first-child), .agreement.free-allocation .pucl-date:not(:first-child) {
    margin-top: 30px; }

#free-allocation-warning-modal .body {
  padding-right: 0px !important;
  padding-bottom: 0px !important; }
  #free-allocation-warning-modal .body .modal-body-content .warning-modal-intro {
    padding-right: 30px; }
  #free-allocation-warning-modal .body .modal-body-content .scroll-content .overlay {
    padding-right: 10px; }
  #free-allocation-warning-modal .body .modal-body-content .orderlines-table {
    width: 100%; }
    #free-allocation-warning-modal .body .modal-body-content .orderlines-table .tableCell:first-child {
      min-width: 70px; }
    #free-allocation-warning-modal .body .modal-body-content .orderlines-table .tableCell:last-child {
      min-width: 135px; }

.voucherCoverageTable-container {
  margin-top: 10px; }
  .voucherCoverageTable-container .settlementCurrency, .voucherCoverageTable-container .commissionRate {
    width: 100%; }
  .voucherCoverageTable-container .panel-title .remove-product {
    background-image: none;
    text-shadow: none;
    border: none;
    box-shadow: none;
    background: none; }
    .voucherCoverageTable-container .panel-title .remove-product i {
      color: #063A8F; }
  .voucherCoverageTable-container .panel-title .third-party-country-count {
    float: right; }
  .voucherCoverageTable-container .panel-title .third-party-country-count {
    margin-right: 10px;
    padding-right: 10px; }
  .voucherCoverageTable-container .panel-body .container {
    width: 100%;
    max-height: 250px;
    overflow-y: auto; }
    .voucherCoverageTable-container .panel-body .container .row div {
      margin-top: 10px;
      margin-bottom: 10px; }
      .voucherCoverageTable-container .panel-body .container .row div .checkbox {
        margin: 0px; }

.third-party-coverage-table .pucl-data-table .cell-container.first .has-checkbox .row-cell.checkbox {
  margin: 0px; }

.permitted-uses-agreement-header {
  height: 70px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-left: -30px;
  padding: 0px 70px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
  position: fixed;
  z-index: 2000; }
  .permitted-uses-agreement-header .title {
    display: flex;
    align-items: center; }
    .permitted-uses-agreement-header .title h3, .permitted-uses-agreement-header .title h6 {
      margin: 0px; }
  .permitted-uses-agreement-header .button {
    text-align: right; }
  .permitted-uses-agreement-header .divider {
    height: 26px;
    width: 1px;
    margin-left: 20px;
    border-left: 1px solid #E7E7E7; }
  .permitted-uses-agreement-header .version {
    height: 50%;
    margin-left: 20px;
    align-items: center; }

.agreement.permitted-uses {
  padding-top: 150px;
  padding-bottom: 35px;
  flex-direction: column; }
  .agreement.permitted-uses .section-label {
    margin-bottom: 30px;
    font-size: 20px; }
  .agreement.permitted-uses .info-container {
    padding: 0px 70px; }
    .agreement.permitted-uses .info-container .layout {
      margin: 60px 0px; }
      .agreement.permitted-uses .info-container .layout .flex-wrapper {
        display: flex;
        width: 100%; }
  .agreement.permitted-uses .content {
    padding: inherit; }
    .agreement.permitted-uses .content .container {
      padding-left: 0px;
      padding-right: 0px; }
  .agreement.permitted-uses .pucl-data-table .header-cell .pucl-checkbox .pucl-input--selection-controls__input input {
    cursor: not-allowed; }
  .agreement.permitted-uses .pucl-data-table .header-cell .pucl-checkbox .pucl-label::before {
    background-color: #d2d2d2; }
    .agreement.permitted-uses .pucl-data-table .header-cell .pucl-checkbox .pucl-label::before:hover {
      cursor: not-allowed; }
  .agreement.permitted-uses .pucl-data-table .cell-container.first .row-cell .cell-preface.has-checkbox.has-expandable {
    margin-right: 0px; }
  .agreement.permitted-uses .pucl-data-table .cell-container.first .row-cell .cell-preface .checkbox ~ .hide-icon {
    padding: 0 10px; }
  .agreement.permitted-uses .pucl-data-table .row-cell .checkbox {
    margin: 0px; }
  .agreement.permitted-uses .changeHistory .pucl-data-table .cell-container.first .row-cell {
    padding-left: 19px; }
  .agreement.permitted-uses .pucl-collapsible-container .pucl-nested > form {
    background-color: white; }
  .agreement.permitted-uses .pucl-input label.pucl-label, .agreement.permitted-uses .pucl-date label.pucl-label {
    font-weight: initial; }
  .agreement.permitted-uses .pucl-input:not(:first-child), .agreement.permitted-uses .pucl-date:not(:first-child) {
    margin-top: 30px; }
  .agreement.permitted-uses .tooltip-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 0px; }
    .agreement.permitted-uses .tooltip-wrapper .tooltip {
      display: inline !important;
      font-family: "SSTPro-Roman";
      font-size: 14px;
      line-height: 12px;
      color: #363636;
      z-index: 1 !important; }

.WarningModal .modal-dialog {
  margin-top: 200px; }

.agreements-page {
  padding-top: 40px; }
  .agreements-page .agreements-list {
    margin-top: 40px;
    margin-bottom: 65px; }
  .agreements-page .tooltip {
    opacity: 1 !important;
    z-index: unset !important;
    font-size: 14px !important; }
  .agreements-page .action-bar-container.right .pucl-btn,
  .agreements-page .action-bar-container.right .pucl-btn .pucl-icon {
    background-color: #0072ce !important; }
  .agreements-page .action-bar-container.right .pucl-btn {
    padding-right: 20px !important; }
    .agreements-page .action-bar-container.right .pucl-btn .button-text {
      color: #ffffff !important; }
  .agreements-page .action-bar-container .tooltip:focus, .agreements-page .action-bar-container .tooltip:hover {
    text-decoration: none; }
  .agreements-page .filters .filters-container {
    flex-wrap: wrap; }
    .agreements-page .filters .filters-container .region {
      min-width: 305px; }
    .agreements-page .filters .filters-container .agreementType {
      min-width: 310px; }
    .agreements-page .filters .filters-container .status {
      min-width: 305px; }
    .agreements-page .filters .filters-container .country {
      min-width: 305px; }
    .agreements-page .filters .filters-container .voucherProductType {
      padding-top: 10px;
      min-width: 305px; }
  .agreements-page .pucl-data-table .header-cell-wrapper .content {
    padding: 0px; }
  .agreements-page .pucl-data-table .cell-container.first-column .row-cell {
    margin-left: 20px !important; }
    .agreements-page .pucl-data-table .cell-container.first-column .row-cell a {
      text-decoration: none; }
    .agreements-page .pucl-data-table .cell-container.first-column .row-cell a:visited {
      color: #0072ce; }
  .agreements-page .pucl-data-table .cell-container.partner-column a {
    text-decoration: none; }
  .agreements-page .pucl-data-table .cell-container.partner-column a:visited {
    color: #0072ce; }
  .agreements-page .pucl-data-table .cell-container.status-column .row-cell-content .pucl-tag-icon-only {
    margin-right: 5px; }
    .agreements-page .pucl-data-table .cell-container.status-column .row-cell-content .pucl-tag-icon-only.valid {
      background-color: #2d9e94; }
    .agreements-page .pucl-data-table .cell-container.status-column .row-cell-content .pucl-tag-icon-only.inactive {
      background-color: #767676; }
    .agreements-page .pucl-data-table .cell-container.status-column .row-cell-content .pucl-tag-icon-only.warning {
      background-color: #d99c34; }
    .agreements-page .pucl-data-table .cell-container.status-column .row-cell-content .pucl-tag-icon-only.error {
      background-color: #c5474b; }
  .agreements-page .agreements-paginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between; }
    .agreements-page .agreements-paginator .pucl-select-container {
      min-width: 80px; }
    .agreements-page .agreements-paginator .page-link {
      text-decoration: none; }
    .agreements-page .agreements-paginator .sie-select__field .sie-select-arrow {
      color: #999999; }
    .agreements-page .agreements-paginator .sr-only {
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit; }

.users-list {
  padding-top: 40px; }
  .users-list .users-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    width: 100%;
    padding: 30px 45px; }
    .users-list .users-nav .users-nav-content {
      display: inline-flex;
      min-width: 1270px; }
      .users-list .users-nav .users-nav-content .pucl-input-container {
        padding-left: 55px; }
  .users-list .filters-container .filter.types {
    width: 200px; }
  .users-list .filters-container .filter.roleCode {
    width: 200px; }
  .users-list .filters-container .filter.status {
    width: 200px; }
  .users-list .filters-container .filter .pucl-date-range .pucl-input-field-container {
    width: 280px !important; }
  .users-list .action-bar-container {
    margin-left: 30px; }
    .users-list .action-bar-container.left .action-bar-filter-button-container .tooltip {
      opacity: 1;
      margin-left: -27px; }
    .users-list .action-bar-container.left .action-bar-filter-button-container .tooltip:hover {
      text-decoration: none; }
    .users-list .action-bar-container.right .pucl-btn {
      background-color: #0072CE !important;
      padding: 0 20px !important;
      width: auto !important; }
      .users-list .action-bar-container.right .pucl-btn .button-text,
      .users-list .action-bar-container.right .pucl-btn .ico-action-add {
        background-color: #0072CE !important;
        color: #fff !important; }
  .users-list .header-cell .header-cell-wrapper .content {
    padding-left: 0px; }
  .users-list .partner {
    padding-top: initial; }
  .users-list .cell-container.userName.first .row-cell a:visited {
    color: #0072ce; }
  .users-list .cell-container.userName.first .row-cell a:hover {
    text-decoration: none; }
  .users-list .cell-container.userName.first .row-cell .cell-preface {
    margin-right: 0px; }
    .users-list .cell-container.userName.first .row-cell .cell-preface .hide-icon {
      padding-right: 0px; }
  .users-list .cell-container.userName.first .row-cell .tooltip {
    opacity: 1;
    padding-right: 5px; }
  .users-list .cell-container.partner .row-cell a:visited {
    color: #0072ce; }
  .users-list .cell-container.partner .row-cell a:hover {
    text-decoration: none; }
  .users-list .cell-container.partner .row-cell .cell-preface {
    margin-right: 0px; }
    .users-list .cell-container.partner .row-cell .cell-preface .hide-icon {
      padding-right: 0px; }
  .users-list .users-paginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between; }
    .users-list .users-paginator .pucl-select-container {
      min-width: 80px; }
    .users-list .users-paginator .page-link {
      text-decoration: none; }
    .users-list .users-paginator .sie-select__field .sie-select-arrow {
      color: #999999; }
    .users-list .users-paginator .sr-only {
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit; }

.textarea-container {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  position: relative;
  font-size: 14px;
  color: #363636; }
  .textarea-container .textarea-label {
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold; }
    .textarea-container .textarea-label .star {
      margin-right: 5px;
      color: #EF5A75; }
  .textarea-container .textarea-scroll-container {
    border: 1px solid #D2D2D2;
    border-radius: 2.5px; }
    .textarea-container .textarea-scroll-container[disabled] {
      background: #EEEEEE;
      border-color: #DBDBDB;
      color: #767676;
      pointer-events: none; }
    .textarea-container .textarea-scroll-container:focus-within {
      border: 1px solid #9ABFD3; }
  .textarea-container .textarea-placeholder {
    position: absolute;
    left: 13px;
    z-index: 2;
    color: #999999; }
  .textarea-container .textarea {
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    padding: 6px 12px;
    outline: none; }
    .textarea-container .textarea[disabled] {
      background: transparent; }
  .textarea-container .toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .textarea-container .toolbar .spacer {
      width: 10px; }
    .textarea-container .toolbar .section {
      display: flex;
      flex: 1 1; }
      .textarea-container .toolbar .section.right {
        justify-content: flex-end; }
      .textarea-container .toolbar .section .management-icons .pgp-delete-key,
      .textarea-container .toolbar .section .management-icons .download {
        z-index: 1; }

.user-info .pucl-divider {
  border: none;
  background: #e7e7e7; }

.user-info .pucl-input:not(:last-child) {
  margin-bottom: 10px; }

.user-info .error {
  color: #c30000; }

.user-info .success {
  color: #00a700; }

.user-info .instructions {
  margin-bottom: 50px;
  margin-top: 30px; }

.pgp-key-modal .modal-dialog {
  margin-top: 171px; }

.downloadIcon {
  color: #003791;
  cursor: pointer; }

.trashIcon {
  margin-left: 15px;
  color: #003791;
  cursor: pointer; }

.user-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px; }
  @media (max-width: 1199px) {
    .user-page {
      margin-top: -20px; } }
  .user-page .user-nav {
    height: 60px;
    width: 100%;
    background: #fff;
    padding: 10px 0px;
    position: fixed;
    top: 100px;
    z-index: 1;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3); }
    .user-page .user-nav .container {
      padding-left: 0px;
      padding-right: 0px; }
    .user-page .user-nav .title {
      font-size: 26px;
      font-family: 'SSTPro-Light'; }
    .user-page .user-nav .buttons {
      text-align: right; }
    @media (max-width: 1199px) {
      .user-page .user-nav {
        top: 80px; } }
  .user-page .user-info {
    margin-left: 0px;
    padding: 0px 70px; }

.pucl-collapsible-container {
  background: white; }
  .pucl-collapsible-container .pucl-nested {
    overflow: hidden !important; }

.userName span {
  color: #063A8f;
  cursor: pointer; }

.userInfoDatesDetail {
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px; }
  .userInfoDatesDetail span {
    margin-left: 154px; }

.userInfoDetail {
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px; }
  .userInfoDetail span {
    margin-left: 170px; }

.pgp-key {
  margin-bottom: 60px; }
  .pgp-key .pgp-hr {
    margin: 0;
    margin-bottom: 30px; }
  .pgp-key .tooltip {
    opacity: 1;
    position: static !important;
    margin-left: 6px;
    text-decoration: none; }

.ecc-warning {
  margin-left: 4px;
  cursor: pointer;
  color: #003791; }

.pgp-modal-buttons {
  display: flex;
  justify-content: flex-end; }
  .pgp-modal-buttons button:last-child {
    margin-left: 10px; }

.pgp-validation {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  font-size: 12px;
  font-family: 'SSTPro-Roman'; }
  .pgp-validation.error {
    color: #b00c12; }
  .pgp-validation.success {
    color: #00a68e; }
  .pgp-validation .validation-text {
    margin-left: 10px; }
  .pgp-validation .pucl-icon {
    margin-top: 3px; }

.pgp-key-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px; }
  .pgp-key-buttons button {
    margin-left: 10px; }

.pgpKeyAdd {
  text-decoration: none !important; }

.pgp-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .pgp-input-container .label {
    display: flex;
    margin-bottom: 10px;
    padding: 0px;
    color: #363636;
    font-size: 14px;
    text-align: left;
    font-family: 'SSTPro-Roman'; }
  .pgp-input-container .pucl-btn-link {
    height: 0px;
    padding: 0px;
    border: none; }
  .pgp-input-container .text-area-input {
    min-width: 478px;
    min-height: 136px; }
  .pgp-input-container .management-icons {
    display: flex; }
    .pgp-input-container .management-icons .download {
      margin-right: 10px; }
    .pgp-input-container .management-icons .pucl-icon {
      margin: 0px;
      font-size: 14px; }
  .pgp-input-container .tooltip-pgp-key-icon {
    color: #616161; }

.primary-row {
  display: grid;
  background-color: #F3F3F3; }
  .primary-row .tooltip {
    opacity: 1; }
  .primary-row .pucl-icon {
    font-size: 16px; }
  .primary-row .primary-header {
    padding: 10px 20px;
    border: 1px solid #cacaca;
    white-space: wrap;
    word-break: break-word; }
  .primary-row .cell-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px; }
    .primary-row .cell-container.codeName .content {
      display: flex;
      align-items: center;
      padding: unset; }
    .primary-row .cell-container .truncate {
      word-break: break-all;
      color: #363636;
      font-size: 14px; }
      .primary-row .cell-container .truncate.codeNameLink {
        color: #0089d8; }
  .primary-row .codeName a:visited {
    color: #0072ce; }
  .primary-row .activity {
    display: flex;
    padding-right: 10px; }
    .primary-row .activity .pucl-input.pucl-input-wrapper {
      width: 190px; }
  .primary-row .quantity {
    padding-right: 10px;
    padding-left: 10px; }
    .primary-row .quantity .pucl-input .text-input-row-flex-wrapper {
      padding-top: 0; }
    .primary-row .quantity .quantity-input-wrapper .tooltip-wrapper {
      right: -24px; }
  .primary-row .useCount {
    display: flex;
    padding-right: 10px; }
    .primary-row .useCount .pucl-input .text-input-row-flex-wrapper {
      padding-top: 0; }
  .primary-row .orderFee {
    display: flex; }
  .primary-row .redemptionFee {
    display: flex; }

.tooltipDefaultStyles.useCount {
  width: 200px; }

.tooltipDefaultStyles.partnerComments {
  max-width: 500px; }

.po-number .po-number-input-wrapper input {
  width: 137px; }

.po-number.cell-container {
  border-bottom: unset; }

.po-number .file_upload, .po-number .file-download-icon, .po-number .insert_drive_file {
  color: #616161;
  text-decoration: none; }

.po-number .file_upload:hover, .po-number .file-download-icon:hover, .po-number .insert_drive_file:hover {
  color: #006db4; }

.po-number .po-upload-hidden .file-download-upload-label {
  margin-left: 10px; }
  .po-number .po-upload-hidden .file-download-upload-label .file-download-icon {
    padding-top: 8px; }

.po-number .po-upload {
  min-width: 33px;
  min-height: 24px;
  display: flex; }
  .po-number .po-upload .file-download-upload-label {
    padding-left: 5px; }
  .po-number .po-upload .tooltip {
    opacity: 1; }

.po-number .row-cell .row-cell-content .po-upload-hidden .po-pending {
  margin-left: 5px; }
  .po-number .row-cell .row-cell-content .po-upload-hidden .po-pending .tooltip {
    top: 3px; }

.po-number .row-cell .row-cell-content .po-upload-hidden .po-error-icon {
  margin-left: 1px; }

.po-number #error-po {
  color: #C5474B; }

.po-number .po-upload > label {
  margin-left: 10px;
  margin-bottom: 0px;
  color: #0072ce;
  cursor: pointer;
  width: 100%;
  height: 100%; }
  .po-number .po-upload > label input {
    display: none; }

.po-number .ico-alert {
  padding-left: 5px; }

.pucl-data-table .po-number.cell-container .row-cell {
  min-height: 40px;
  height: unset; }

.secondary-row .pucl-icon {
  font-size: 16px; }

.secondary-row .cell-container {
  padding-left: 10px; }

.secondary-row .secondary-headers-row {
  display: flex;
  justify-content: flex-start;
  background-color: #F3F3F3;
  align-items: stretch; }
  .secondary-row .secondary-headers-row .secondary-header {
    padding: 10px 20px;
    border: 1px solid #cacaca;
    white-space: wrap;
    word-break: break-word;
    display: flex; }

.secondary-row .secondary-row-content {
  display: flex;
  justify-content: flex-start;
  background-color: #FFF; }
  .secondary-row .secondary-row-content .startDateTime, .secondary-row .secondary-row-content .endDateTime {
    margin-left: 10px;
    margin-bottom: 5px; }
    .secondary-row .secondary-row-content .startDateTime .time-input, .secondary-row .secondary-row-content .endDateTime .time-input {
      margin-left: 10px; }

.secondary-row .download-history-icon {
  margin: 10px;
  display: flex;
  align-items: center; }
  .secondary-row .download-history-icon .tooltip-label {
    margin-right: 5px; }
  .secondary-row .download-history-icon .order-history-info-tooltip {
    padding-top: 5px; }

.secondary-row .no-po {
  margin-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px; }

.secondary-row .poNumber {
  width: 300px;
  display: flex;
  align-items: center; }

.secondary-row .tooltip-wrapper {
  align-self: center; }

.secondary-row .startEndDate {
  display: flex;
  flex-grow: 2;
  align-items: center; }

.secondary-row .startDateTime, .secondary-row .endDateTime {
  display: flex; }
  .secondary-row .startDateTime .pucl-date, .secondary-row .endDateTime .pucl-date {
    min-width: 163px;
    margin-right: 10px; }
  .secondary-row .startDateTime .pucl-time-input.t12hr > div:nth-child(2), .secondary-row .endDateTime .pucl-time-input.t12hr > div:nth-child(2) {
    padding-top: 5px; }

.secondary-row .text.startDateTime, .secondary-row .text.endDateTime, .secondary-row .no-date {
  margin-left: 20px; }

.tooltipDefaultStyles.startEndDate, .tooltipDefaultStyles.poNumber {
  width: 250px;
  display: flex;
  flex-direction: column; }

.tertiary-row {
  border-bottom: 1px solid #E7E7E7; }
  .tertiary-row .pucl-icon {
    font-size: 16px; }
  .tertiary-row .tertiary-headers-row {
    display: flex;
    justify-content: flex-start;
    background-color: #F3F3F3;
    align-items: stretch; }
    .tertiary-row .tertiary-headers-row .tertiary-header {
      padding: 10px 20px;
      border: 1px solid #cacaca;
      word-break: break-word;
      display: flex; }
  .tertiary-row .tertiary-row-content {
    display: flex;
    justify-content: flex-start; }
    .tertiary-row .tertiary-row-content .comments {
      padding-top: 10px;
      padding-left: 10px;
      max-width: 930px; }
  .tertiary-row .tertiary-order-row-content {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: auto;
    grid-gap: 0px;
    gap: 0px; }
    .tertiary-row .tertiary-order-row-content .pucl-input.pucl-text-field.form-field {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start; }
      .tertiary-row .tertiary-order-row-content .pucl-input.pucl-text-field.form-field .pucl-form-input {
        width: unset; }
      .tertiary-row .tertiary-order-row-content .pucl-input.pucl-text-field.form-field .pucl-input-field-container {
        margin-left: 5px; }
      .tertiary-row .tertiary-order-row-content .pucl-input.pucl-text-field.form-field .pucl-label {
        order: 1; }
    .tertiary-row .tertiary-order-row-content .commenter, .tertiary-row .tertiary-order-row-content .commented-date, .tertiary-row .tertiary-order-row-content .comment, .tertiary-row .tertiary-order-row-content .pucl-input.pucl-input-wrapper {
      padding: 20px;
      border-top: 1px solid #cacaca;
      height: 100%;
      display: flex;
      align-items: center; }
  .tertiary-row .comments {
    flex-grow: 3;
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid #cacaca; }
    .tertiary-row .comments .pucl-input.pucl-text-field.form-field {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start; }
      .tertiary-row .comments .pucl-input.pucl-text-field.form-field .pucl-form-input {
        width: unset; }
      .tertiary-row .comments .pucl-input.pucl-text-field.form-field .pucl-input-field-container {
        margin-left: 5px; }
      .tertiary-row .comments .pucl-input.pucl-text-field.form-field .pucl-label {
        order: 1; }
    .tertiary-row .comments .commenter {
      width: 160px;
      padding-right: 20px; }
    .tertiary-row .comments .commented-date {
      width: 170px;
      padding-right: 20px; }
    .tertiary-row .comments .comment, .tertiary-row .comments .pucl-input.pucl-input-wrapper {
      width: 642px;
      padding-right: 20px; }
  .tertiary-row .cta {
    display: flex;
    flex-grow: 1;
    align-items: center;
    border-bottom: 1px solid #cacaca; }

.tooltipDefaultStyles.orderComments {
  width: 250px; }

.thirdPartyProduct-header {
  padding-top: 40px;
  width: 1370px; }
  .thirdPartyProduct-header > .header {
    width: 1370px; }
    .thirdPartyProduct-header > .header > .search-header-secondary-nav {
      width: 1370px; }
      .thirdPartyProduct-header > .header > .search-header-secondary-nav > div.flex {
        width: 1370px; }
  .thirdPartyProduct-header.thirdPartyCatalog-init-partner-select {
    margin-top: 20px; }
  .thirdPartyProduct-header div.flex:nth-child(2) {
    order: 2; }
  .thirdPartyProduct-header .partner-select-group-true {
    margin-top: 38px; }
  .thirdPartyProduct-header .partner-select-subheader-false {
    margin-bottom: 16px;
    margin-top: 16px; }
  .thirdPartyProduct-header .partner-select-subheader-true {
    margin-bottom: 16px;
    margin-top: 26px; }
  .thirdPartyProduct-header .partner-select-header {
    margin-top: 0 !important; }
  .thirdPartyProduct-header .pucl-search__category-select-menu {
    z-index: 2205; }
  .thirdPartyProduct-header .partner-select-container {
    margin-top: 10px !important; }

.thirdPartyProduct-list.layout {
  padding-top: 40px;
  margin-bottom: 65px;
  width: 1370px; }
  .thirdPartyProduct-list.layout.super-admin {
    padding-top: 100px; }
  .thirdPartyProduct-list.layout .tooltip__content {
    z-index: 10; }
  .thirdPartyProduct-list.layout .pucl-action-bar {
    z-index: 10; }
    .thirdPartyProduct-list.layout .pucl-action-bar label {
      font-weight: initial; }
  .thirdPartyProduct-list.layout .search-header-container {
    top: 100px; }
  .thirdPartyProduct-list.layout .action-bar-container.left .tooltip {
    display: flex !important;
    position: relative !important;
    opacity: 1; }
    .thirdPartyProduct-list.layout .action-bar-container.left .tooltip:hover {
      text-decoration: none; }
  .thirdPartyProduct-list.layout .pucl-data-table .cell-container.registrationRegion .row-cell-content {
    display: -webkit-inline-box; }
  .thirdPartyProduct-list.layout .pucl-data-table .cell-container.selected {
    z-index: initial; }
  .thirdPartyProduct-list.layout .pucl-data-table .row-cell {
    overflow-wrap: anywhere; }
    .thirdPartyProduct-list.layout .pucl-data-table .row-cell .activity .pucl-select__value {
      line-height: 12px; }
  .thirdPartyProduct-list.layout .pucl-data-table .row-cell-content.lastColumn {
    width: 99%; }
  .thirdPartyProduct-list.layout .pucl-data-table .row-cell-content a:visited {
    color: #0072ce; }
  .thirdPartyProduct-list.layout .pucl-data-table .lastColumn {
    justify-content: flex-start;
    top: 20; }
    .thirdPartyProduct-list.layout .pucl-data-table .lastColumn .header-cell-wrapper {
      justify-content: flex-start;
      padding-right: 65px; }
    .thirdPartyProduct-list.layout .pucl-data-table .lastColumn .tooltip {
      margin-left: 0px; }
    .thirdPartyProduct-list.layout .pucl-data-table .lastColumn .download-history-tooltip {
      width: 14px;
      height: 15px; }
    .thirdPartyProduct-list.layout .pucl-data-table .lastColumn .no-order-history {
      width: 240px; }
    .thirdPartyProduct-list.layout .pucl-data-table .lastColumn .download-hisotry-loader {
      display: flex;
      justify-content: center;
      align-items: center; }
  .thirdPartyProduct-list.layout .pucl-data-table .tooltip {
    opacity: 1;
    font-family: 'SSTPro-Roman'; }
    .thirdPartyProduct-list.layout .pucl-data-table .tooltip .truncate {
      color: #363636;
      font-size: 14px; }
      .thirdPartyProduct-list.layout .pucl-data-table .tooltip .truncate.codeNameLink {
        font-size: 14px;
        color: #0089d8; }
  .thirdPartyProduct-list.layout .pucl-data-table .error-tooltip {
    margin-left: -32px;
    margin-right: 20px; }
    .thirdPartyProduct-list.layout .pucl-data-table .error-tooltip .tooltip {
      position: initial !important;
      color: #616161; }
  .thirdPartyProduct-list.layout .pucl-data-table a:focus.tooltip, .thirdPartyProduct-list.layout .pucl-data-table a:hover.tooltip {
    text-decoration: none; }
  .thirdPartyProduct-list.layout .pucl-data-table .content {
    padding: initial; }
  .thirdPartyProduct-list.layout .pucl-data-table .availabilityRegion {
    display: inline;
    width: 100%;
    align-items: flex-end;
    position: static; }
    .thirdPartyProduct-list.layout .pucl-data-table .availabilityRegion .region-name {
      display: inline-flex; }
    .thirdPartyProduct-list.layout .pucl-data-table .availabilityRegion .countryTooltipContainer {
      display: inline-flex;
      align-items: flex-end;
      padding-left: 4px;
      z-index: 1; }
    .thirdPartyProduct-list.layout .pucl-data-table .availabilityRegion .multiCountryTooltip {
      display: flex;
      width: 100%;
      align-items: inherit; }
  .thirdPartyProduct-list.layout .pucl-data-table .table-no-results-found .empty-table-message {
    padding-bottom: 10px; }
  .thirdPartyProduct-list.layout .pucl-data-table .table-no-results-found .content-pipeline-link {
    text-decoration: none; }
  .thirdPartyProduct-list.layout .subtable-wrapper .header-cell.first .header-cell-wrapper {
    margin-left: 55px !important; }
  .thirdPartyProduct-list.layout .subtable-wrapper .cell-container.first .row-cell-content {
    margin-left: 55px !important; }
  .thirdPartyProduct-list.layout .thirdPartyCatalogPageTableContainer {
    width: 100%; }
    .thirdPartyProduct-list.layout .thirdPartyCatalogPageTableContainer .tooltipDefaultStyles a {
      color: #fff; }
    .thirdPartyProduct-list.layout .thirdPartyCatalogPageTableContainer .pucl-table-wrapper .expandable-container {
      background-color: #fbfbfb;
      max-height: none; }
  .thirdPartyProduct-list.layout .thirdPartyCatalogPagePaginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between; }
    .thirdPartyProduct-list.layout .thirdPartyCatalogPagePaginator .pucl-select-container {
      min-width: 80px; }
    .thirdPartyProduct-list.layout .thirdPartyCatalogPagePaginator .page-link {
      text-decoration: none; }
    .thirdPartyProduct-list.layout .thirdPartyCatalogPagePaginator .sr-only {
      width: 9px !important;
      height: 12px !important;
      margin: 0;
      position: inherit; }
  .thirdPartyProduct-list.layout .loading-vouchers {
    text-align: center;
    font-size: 20px; }
  .thirdPartyProduct-list.layout .results-text {
    font-weight: bold;
    padding: 10px 0 20px; }
  .thirdPartyProduct-list.layout .thirdPartyProduct-row {
    display: flex;
    margin-top: 13px;
    margin-bottom: 13px; }
  .thirdPartyProduct-list.layout .row div {
    padding: 5px 5px 5px 15px;
    min-width: 30px;
    -ms-word-break: break-all;
    word-break: break-all; }
  .thirdPartyProduct-list.layout .panel-title a {
    display: inline;
    padding: 10px 0; }
  .thirdPartyProduct-list.layout .row div.panel-title {
    padding: 5px 0; }
  .thirdPartyProduct-list.layout div.panel, .thirdPartyProduct-list.layout div.panel-heading {
    padding: 0;
    background: none;
    box-shadow: none;
    border: 0;
    margin-bottom: 0; }
  .thirdPartyProduct-list.layout .headers {
    display: flex;
    font-weight: bold;
    border-bottom: 1px solid #000;
    font-size: 12px;
    color: #063A8F; }
    .thirdPartyProduct-list.layout .headers > div {
      cursor: pointer;
      word-break: break-word; }
      .thirdPartyProduct-list.layout .headers > div.desc::after {
        content: ' \25BC' !important; }
      .thirdPartyProduct-list.layout .headers > div.asc::after {
        content: ' \25B2' !important; }
  .thirdPartyProduct-list.layout .thirdPartyProduct-link, .thirdPartyProduct-list.layout .code-product-link {
    cursor: pointer; }
    .thirdPartyProduct-list.layout .thirdPartyProduct-link:hover, .thirdPartyProduct-list.layout .code-product-link:hover {
      text-decoration: underline; }
    .thirdPartyProduct-list.layout .thirdPartyProduct-link:visited, .thirdPartyProduct-list.layout .code-product-link:visited {
      color: #0072ce; }
  .thirdPartyProduct-list.layout .normal-icon {
    color: black; }
  .thirdPartyProduct-list.layout .iconPlacement {
    margin-right: 5px; }
    .thirdPartyProduct-list.layout .iconPlacement a {
      position: static !important; }
  .thirdPartyProduct-list.layout .has-lines span.toggle:before {
    display: inline-block; }
  .thirdPartyProduct-list.layout .line-headers {
    background: #DFDFDF;
    color: #A8A8A8; }
    .thirdPartyProduct-list.layout .line-headers td, .thirdPartyProduct-list.layout .line-headers th {
      padding: 2px 8px !important;
      word-break: break-word; }
    .thirdPartyProduct-list.layout .line-headers th {
      font-weight: normal; }
  .thirdPartyProduct-list.layout #truncate.tooltip {
    z-index: initial !important; }
  .thirdPartyProduct-list.layout .expanded-table {
    z-index: -1 !important; }
  .thirdPartyProduct-list.layout .download-button-container {
    margin-right: 25px; }
  .thirdPartyProduct-list.layout .download-history-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 240px;
    min-height: 50px; }
    .thirdPartyProduct-list.layout .download-history-container .download-history-info {
      justify-content: left;
      text-align: left;
      flex-direction: column;
      flex-wrap: wrap; }
    .thirdPartyProduct-list.layout .download-history-container .download-history-info-title {
      font-weight: bold; }
    .thirdPartyProduct-list.layout .download-history-container .download-history-row {
      display: flex; }
    .thirdPartyProduct-list.layout .download-history-container .download-name {
      margin-bottom: 10px; }
    .thirdPartyProduct-list.layout .download-history-container .expiration-message {
      margin-top: 10px; }
    .thirdPartyProduct-list.layout .download-history-container .download-name,
    .thirdPartyProduct-list.layout .download-history-container .last-downloaded,
    .thirdPartyProduct-list.layout .download-history-container .last-downloaded-by,
    .thirdPartyProduct-list.layout .download-history-container .order-id,
    .thirdPartyProduct-list.layout .download-history-container .batch-id,
    .thirdPartyProduct-list.layout .download-history-container .start-end-date {
      display: flex;
      flex-direction: column; }
    .thirdPartyProduct-list.layout .download-history-container .last-downloaded,
    .thirdPartyProduct-list.layout .download-history-container .last-downloaded-by,
    .thirdPartyProduct-list.layout .download-history-container .order-id,
    .thirdPartyProduct-list.layout .download-history-container .batch-id {
      width: 50%; }

.ThirdPartyWarningModalConfirmButton {
  margin-left: 15px; }

.fade.modal-backdrop {
  z-index: 2250; }

.fade.warning-modal.in.modal {
  z-index: 2251; }
  .fade.warning-modal.in.modal .modal-dialog {
    margin-top: 90px; }
  .fade.warning-modal.in.modal .WarningButton.yes {
    width: 105px;
    margin-left: 15px; }

@media (min-width: 1200px) {
  .container {
    width: 1400px; } }

.quantity-input-wrapper {
  display: flex;
  align-items: center;
  position: relative; }
  .quantity-input-wrapper .tooltip-wrapper {
    position: absolute;
    right: -50px; }

.tooltip-wrapper {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-left: 10px; }
  .tooltip-wrapper .tooltip {
    opacity: 1;
    color: #636365; }
  .tooltip-wrapper .alert-icon {
    color: #c5474b; }

.cart-header {
  background: white;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 100px;
  left: 0px;
  margin: 0px;
  width: 100%;
  padding: 5px 45px;
  z-index: 2002;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px; }
  .cart-header .container .cart-header-layout {
    width: 1370px;
    justify-content: space-between; }
  @media (max-width: 1199px) {
    .cart-header {
      top: 80px; } }
  .cart-header a:hover {
    text-decoration: none; }
  .cart-header h3 {
    margin-top: 5px; }
  .cart-header .button-toolbar {
    margin-top: 4px;
    text-align: right; }
  .cart-header .back-to-product {
    padding-right: 10px; }

.cart-note {
  padding-top: 180px;
  color: #636365;
  margin: 0 auto; }

.pucl-data-table .cell-container .row-cell {
  overflow-wrap: anywhere; }

.cart {
  margin: 0 auto;
  width: 1370px;
  padding-top: 20px; }
  .cart .pucl-data-table .content {
    padding: initial;
    white-space: initial !important; }
  .cart a:hover {
    text-decoration: none; }
  .cart .cart-content {
    width: 100%;
    padding-bottom: 65px; }
    .cart .cart-content .tooltip__content {
      z-index: 10; }
    .cart .cart-content .pucl-table-wrapper .cell-container.registrationRegion .row-cell-content {
      display: -webkit-inline-box; }
    .cart .cart-content .pucl-table-wrapper .cell-container.selected {
      z-index: initial; }
    .cart .cart-content .pucl-table-wrapper .cart-row-product-name .tooltip {
      opacity: 1; }
      .cart .cart-content .pucl-table-wrapper .cart-row-product-name .tooltip .truncate .code-product-link {
        font-size: 14px;
        line-height: 20px; }
    .cart .cart-content .pucl-table-wrapper .expandable-container {
      background-color: #fbfbfb;
      max-height: none; }
      .cart .cart-content .pucl-table-wrapper .expandable-container .po-number-input-wrapper .pucl-input.pucl-input-wrapper {
        padding-bottom: 0px; }
    .cart .cart-content .cell-container .trash-icon-wrap {
      width: 100%; }
      .cart .cart-content .cell-container .trash-icon-wrap i {
        float: right;
        color: #616161; }
    .cart .cart-content .code-product-link {
      color: #0072ce; }
    .cart .cart-content .quantity-input-wrapper {
      display: flex;
      align-items: center;
      position: relative; }
      .cart .cart-content .quantity-input-wrapper .tooltip-wrapper {
        position: absolute;
        right: -25px; }
    .cart .cart-content .tooltip-wrapper {
      width: 16px;
      height: 16px;
      margin-top: 2px;
      margin-left: 10px; }
      .cart .cart-content .tooltip-wrapper .tooltip {
        opacity: 1;
        color: #636365; }
      .cart .cart-content .tooltip-wrapper .alert-icon {
        color: #c5474b; }
    .cart .cart-content .time-zone-field {
      padding-top: 10px; }
    .cart .cart-content .pucl-date .pucl-input-field-container {
      min-width: 147px; }
    .cart .cart-content .pucl-select-container {
      min-width: 96px; }
  .cart .calendar-container {
    z-index: 1; }
  .cart .cartPagePaginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between; }
    .cart .cartPagePaginator .pucl-select-container {
      min-width: 80px; }
    .cart .cartPagePaginator .page-link {
      text-decoration: none; }
    .cart .cartPagePaginator .sie-select__field .sie-select-arrow {
      color: #999999; }
    .cart .cartPagePaginator .sr-only {
      width: 7.5px;
      height: 12px;
      margin: 0;
      position: inherit; }

.custom-modal-footer {
  width: 900px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #E7E7E7;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 30px;
  margin-left: -31px; }
  .custom-modal-footer .buttons-toolbar {
    display: contents; }

.custom-modal-body {
  white-space: pre-wrap;
  text-align: justify; }

.order-history a:hover {
  text-decoration: none; }

.order-history .order-history-header {
  padding-top: 40px; }
  .order-history .order-history-header > .header {
    width: 1370px; }
    .order-history .order-history-header > .header .search-header-secondary-nav {
      justify-content: flex-end; }
  .order-history .order-history-header .pucl-search__category-select-menu {
    z-index: 2101; }

.order-history .order-history-table {
  margin-top: 40px;
  width: 1370px; }
  .order-history .order-history-table .tooltip {
    opacity: 1;
    z-index: 0;
    font-family: 'SSTPro-Roman' !important; }
    .order-history .order-history-table .tooltip .tooltip__inner-content {
      z-index: 1000; }
      .order-history .order-history-table .tooltip .tooltip__inner-content .comment-overlay {
        z-index: 1000; }
    .order-history .order-history-table .tooltip .tooltip--visible {
      opacity: 1; }
  .order-history .order-history-table .tooltip__content.tooltip--visible {
    z-index: 2100; }
  .order-history .order-history-table .code-product-name-container {
    width: 100%; }
    .order-history .order-history-table .code-product-name-container .tooltip {
      position: relative !important; }
  .order-history .order-history-table .cell-container.order-cta.stuck-horizontal-right {
    z-index: 997 !important; }
  .order-history .order-history-table .cell-container.order-cta {
    z-index: 0 !important; }
    .order-history .order-history-table .cell-container.order-cta .row-cell-content {
      justify-content: end; }
  .order-history .order-history-table .order-cta-button {
    width: 132px;
    line-height: 15px; }
  .order-history .order-history-table .pucl-action-bar {
    z-index: 10; }
    .order-history .order-history-table .pucl-action-bar label {
      font-weight: initial; }
  .order-history .order-history-table .action-bar-container {
    margin-left: 30px; }
    .order-history .order-history-table .action-bar-container.right .bulk-download-button {
      background: #EEEEEE !important;
      border: 1px solid #D2D2D2;
      border-radius: 3px;
      padding-right: 20px !important; }
      .order-history .order-history-table .action-bar-container.right .bulk-download-button .button-text {
        color: #999999 !important; }
    .order-history .order-history-table .action-bar-container.right .bulk-download-button:hover {
      background-color: #dadada !important; }
    .order-history .order-history-table .action-bar-container .tooltip {
      margin-left: -27px;
      margin-right: 10px; }
  .order-history .order-history-table .order-history-content {
    width: 100%;
    padding-bottom: 65px;
    margin: 0 auto; }
    .order-history .order-history-table .order-history-content .tooltipDefaultStyles a {
      color: #fff; }
    .order-history .order-history-table .order-history-content .pucl-table-wrapper .expandable-container {
      background-color: #fbfbfb;
      max-height: none; }
    .order-history .order-history-table .order-history-content .header-cell:first-child .header-cell-wrapper .content {
      padding-left: 20px; }
    .order-history .order-history-table .order-history-content .sort-icons {
      margin-left: 0px !important; }
    .order-history .order-history-table .order-history-content .header-cell:not(:first-child) .header-cell-wrapper .content {
      padding-left: 0px;
      padding-right: 10px; }
    .order-history .order-history-table .order-history-content .header-cell-info {
      border-left: none; }
    .order-history .order-history-table .order-history-content .row-cell .order-history-info-tooltip {
      width: 20px;
      height: 20px;
      margin-left: 10px; }
      .order-history .order-history-table .order-history-content .row-cell .order-history-info-tooltip .tooltip {
        color: #767676;
        width: auto; }
        .order-history .order-history-table .order-history-content .row-cell .order-history-info-tooltip .tooltip i {
          font-size: 20px; }
      .order-history .order-history-table .order-history-content .row-cell .order-history-info-tooltip .info-overlay {
        box-sizing: border-box;
        /* Clear floats after the columns */ }
        .order-history .order-history-table .order-history-content .row-cell .order-history-info-tooltip .info-overlay .column {
          float: left;
          width: 50%;
          padding-left: 15px; }
        .order-history .order-history-table .order-history-content .row-cell .order-history-info-tooltip .info-overlay .row:after {
          display: table;
          clear: both; }
      .order-history .order-history-table .order-history-content .row-cell .order-history-info-tooltip .tooltip__content {
        width: 400px;
        z-index: 2000; }
    .order-history .order-history-table .order-history-content .cell-container.first .row-cell.checkbox {
      margin-top: 0px; }
      .order-history .order-history-table .order-history-content .cell-container.first .row-cell.checkbox .pucl-input--selection-controls__input.disabled input {
        margin-left: 0px; }
    .order-history .order-history-table .order-history-content .cell-container .calendar-container {
      z-index: 1; }
    .order-history .order-history-table .order-history-content .cell-container .cta-button-wrap {
      display: block; }
    .order-history .order-history-table .order-history-content .cell-container .time-zone-field {
      width: 96px;
      padding-top: 10px; }
    .order-history .order-history-table .order-history-content .comment-tooltip {
      margin: 0 auto; }
      .order-history .order-history-table .order-history-content .comment-tooltip .tooltip {
        color: #767676; }
    .order-history .order-history-table .order-history-content #order-history-comment-tooltip.tooltip__content {
      width: 300px;
      padding: 10px 0px; }
      .order-history .order-history-table .order-history-content #order-history-comment-tooltip.tooltip__content hr {
        margin-top: 10px;
        margin-bottom: 10px;
        border-color: #d8d8d8; }
      .order-history .order-history-table .order-history-content #order-history-comment-tooltip.tooltip__content .comment-overlay-header,
      .order-history .order-history-table .order-history-content #order-history-comment-tooltip.tooltip__content .comment-overlay-content {
        padding-left: 10px;
        padding-right: 10px; }
      .order-history .order-history-table .order-history-content #order-history-comment-tooltip.tooltip__content .comment-overlay-content .comment-overlay-content-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px; }
    .order-history .order-history-table .order-history-content .info-tooltip {
      margin: 0 auto; }
      .order-history .order-history-table .order-history-content .info-tooltip .tooltip {
        color: #767676; }
      .order-history .order-history-table .order-history-content .info-tooltip .info-overlay {
        box-sizing: border-box;
        /* Clear floats after the columns */ }
        .order-history .order-history-table .order-history-content .info-tooltip .info-overlay .column {
          float: left;
          width: 50%;
          padding-left: 15px; }
        .order-history .order-history-table .order-history-content .info-tooltip .info-overlay .row:after {
          display: table;
          clear: both; }
      .order-history .order-history-table .order-history-content .info-tooltip .tooltip__content {
        width: 400px; }
    .order-history .order-history-table .order-history-content .code-product-link {
      color: #0072ce;
      font-size: 14px; }
    .order-history .order-history-table .order-history-content .pucl-date {
      padding-right: 4px; }
      .order-history .order-history-table .order-history-content .pucl-date .pucl-input-field-container {
        min-width: 158px; }
    .order-history .order-history-table .order-history-content .information-row-container {
      padding: 0 50px; }
      .order-history .order-history-table .order-history-content .information-row-container .information-row-comment {
        margin-right: 0px;
        margin-left: auto; }
        .order-history .order-history-table .order-history-content .information-row-container .information-row-comment .information-row-item-title {
          margin-right: 0px; }
        .order-history .order-history-table .order-history-content .information-row-container .information-row-comment .order-history-info-tooltip {
          margin-left: 10px; }
          .order-history .order-history-table .order-history-content .information-row-container .information-row-comment .order-history-info-tooltip .tooltip {
            color: #636365; }
    .order-history .order-history-table .order-history-content .content-split-horizontal-rule {
      margin-left: 70px; }
  .order-history .order-history-table .orderHistoryPagePaginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between; }
    .order-history .order-history-table .orderHistoryPagePaginator .pucl-select-container {
      min-width: 80px; }
    .order-history .order-history-table .orderHistoryPagePaginator .page-link {
      text-decoration: none; }
    .order-history .order-history-table .orderHistoryPagePaginator .sie-select__field .sie-select-arrow {
      color: #999999; }
    .order-history .order-history-table .orderHistoryPagePaginator .sr-only {
      width: 7.5px;
      height: 12px;
      margin: 0;
      position: inherit; }

.order-history .truncate {
  overflow-wrap: anywhere;
  font-size: 14px;
  color: #363636; }

.voucher-types-list {
  padding-top: 40px; }
  .voucher-types-list .voucherTypes-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    width: 100%;
    padding: 30px 45px; }
    .voucher-types-list .voucherTypes-nav .voucherTypes-nav-content {
      display: inline-flex;
      min-width: 1270px; }
      .voucher-types-list .voucherTypes-nav .voucherTypes-nav-content .pucl-input-container {
        padding-left: 40px;
        padding-right: 20px;
        width: 460px; }
  .voucher-types-list .action-bar-container {
    margin-left: 30px; }
    .voucher-types-list .action-bar-container.left .action-bar-filter-button-container .tooltip {
      opacity: 1;
      margin-left: -27px; }
    .voucher-types-list .action-bar-container.left .action-bar-filter-button-container .tooltip:hover {
      text-decoration: none; }
    .voucher-types-list .action-bar-container.right .pucl-btn {
      background-color: #0072CE !important;
      padding: 0 20px !important;
      width: auto !important; }
      .voucher-types-list .action-bar-container.right .pucl-btn .button-text,
      .voucher-types-list .action-bar-container.right .pucl-btn .ico-action-add {
        background-color: #0072CE !important;
        color: #fff !important; }
    .voucher-types-list .action-bar-container.right .new-code-product.pucl-btn {
      padding-right: 20px;
      background-color: #0072ce; }
      .voucher-types-list .action-bar-container.right .new-code-product.pucl-btn .button-text {
        color: #fff; }
  .voucher-types-list .filters-container .npRegion {
    width: 315px; }
  .voucher-types-list .header-cell .header-cell-wrapper .content {
    padding-left: 0px; }
  .voucher-types-list .cell-container.voucherTypeName.first .row-cell a:visited {
    color: #0072ce; }
  .voucher-types-list .cell-container.voucherTypeName.first .row-cell a:hover {
    text-decoration: none; }
  .voucher-types-list .cell-container.voucherTypeName.first .row-cell .cell-preface {
    margin-right: 0px; }
    .voucher-types-list .cell-container.voucherTypeName.first .row-cell .cell-preface .hide-icon {
      padding-right: 0px; }
  .voucher-types-list .cell-container.voucherTypeName.first .row-cell .tooltip {
    opacity: 1;
    padding-right: 5px; }
  .voucher-types-list .voucherTypes-paginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between; }
    .voucher-types-list .voucherTypes-paginator .pucl-select-container {
      min-width: 80px; }
    .voucher-types-list .voucherTypes-paginator .page-link {
      text-decoration: none; }
    .voucher-types-list .voucherTypes-paginator .sie-select__field .sie-select-arrow {
      color: #999999; }
    .voucher-types-list .voucherTypes-paginator .sr-only {
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit; }

#voucherBatches-container.voucher-batches-list.container {
  padding-top: 40px;
  width: 1380px; }
  #voucherBatches-container.voucher-batches-list.container .voucherBatches-nav {
    justify-content: space-between;
    padding-top: 30px; }
    #voucherBatches-container.voucher-batches-list.container .voucherBatches-nav .search {
      margin-right: 20px; }
  #voucherBatches-container.voucher-batches-list.container .bulk-action {
    display: flex;
    margin-top: 20px;
    margin-bottom: 30px; }
    #voucherBatches-container.voucher-batches-list.container .bulk-action .bulk-action-selector {
      width: 200px; }
  #voucherBatches-container.voucher-batches-list.container.loading-voucher-batches {
    text-align: center;
    font-size: 20px; }
  #voucherBatches-container.voucher-batches-list.container .filters-toggle {
    color: #063a8f;
    font-weight: bold; }
    #voucherBatches-container.voucher-batches-list.container .filters-toggle span {
      margin-right: 5px; }
  #voucherBatches-container.voucher-batches-list.container .voucherBatchBulkAction-button {
    margin-left: 10px; }
  #voucherBatches-container.voucher-batches-list.container .results-text {
    font-weight: bold;
    padding: 10px 0 20px; }
  #voucherBatches-container.voucher-batches-list.container span.toggle:before {
    content: '\25BC ';
    display: none;
    cursor: pointer; }
  #voucherBatches-container.voucher-batches-list.container span.toggle.collapsed:before {
    content: '\25B6 '; }
  #voucherBatches-container.voucher-batches-list.container .row > div {
    padding: 5px;
    -ms-word-break: break-all;
    word-break: break-all; }
    #voucherBatches-container.voucher-batches-list.container .row > div.status {
      -ms-word-break: break-word;
      word-break: break-word; }
  #voucherBatches-container.voucher-batches-list.container .checkboxCol {
    padding: 0; }
  #voucherBatches-container.voucher-batches-list.container .voucherPurpose {
    max-width: 100px; }
  #voucherBatches-container.voucher-batches-list.container .panel-title a {
    display: inline;
    padding: 10px 0; }
  #voucherBatches-container.voucher-batches-list.container .row div.panel-title {
    padding: 5px 0; }
  #voucherBatches-container.voucher-batches-list.container div.panel, #voucherBatches-container.voucher-batches-list.container div.panel-heading {
    padding: 0;
    background: none;
    box-shadow: none;
    border: 0;
    margin-bottom: 0; }
  #voucherBatches-container.voucher-batches-list.container .headers {
    font-weight: bold;
    border-bottom: 1px solid #000;
    font-size: 12px;
    color: #063a8f; }
    #voucherBatches-container.voucher-batches-list.container .headers div {
      cursor: pointer;
      word-break: break-word; }
      #voucherBatches-container.voucher-batches-list.container .headers div.desc::after {
        content: ' \25BC' !important; }
      #voucherBatches-container.voucher-batches-list.container .headers div.voucherBatchId.desc::after {
        position: absolute;
        top: 7px;
        left: 80px; }
      #voucherBatches-container.voucher-batches-list.container .headers div.asc::after {
        content: ' \25B2' !important; }
      #voucherBatches-container.voucher-batches-list.container .headers div.voucherBatchId.asc::after {
        position: absolute;
        top: 7px;
        left: 80px; }
    #voucherBatches-container.voucher-batches-list.container .headers .disableSort {
      cursor: default;
      color: #333333; }
    #voucherBatches-container.voucher-batches-list.container .headers .allCheckbox {
      width: 20px;
      display: inline; }
    #voucherBatches-container.voucher-batches-list.container .headers .voucherBatchIdLabel {
      position: absolute;
      top: 7px;
      left: 25px; }
  #voucherBatches-container.voucher-batches-list.container .checkbox {
    margin-top: 0px;
    margin-bottom: 0px; }
  #voucherBatches-container.voucher-batches-list.container .voucher-batch-link {
    color: #063a8f;
    cursor: pointer;
    padding-top: -3px; }
    #voucherBatches-container.voucher-batches-list.container .voucher-batch-link:hover {
      text-decoration: underline; }
  #voucherBatches-container.voucher-batches-list.container .page-header {
    border: none; }
    #voucherBatches-container.voucher-batches-list.container .page-header h1 {
      margin-top: 0;
      font-size: 24px; }
  #voucherBatches-container.voucher-batches-list.container .voucher-batch-row {
    padding: 5px 0;
    position: relative; }
  #voucherBatches-container.voucher-batches-list.container input#createdStartDate,
  #voucherBatches-container.voucher-batches-list.container input#createdEndDate {
    border-color: #cccccc;
    border-radius: 4px !important;
    border-style: solid;
    border-width: 1px;
    height: 38px; }
  #voucherBatches-container.voucher-batches-list.container .separator {
    height: auto;
    position: relative;
    width: 100vw;
    border-bottom: 1px solid gray;
    left: calc(-50vw + 50% - 8px); }
  #voucherBatches-container.voucher-batches-list.container .action-bar-container {
    margin-left: 30px; }
    #voucherBatches-container.voucher-batches-list.container .action-bar-container.left .action-bar-filter-button-container .tooltip {
      opacity: 1;
      margin-left: -27px; }
    #voucherBatches-container.voucher-batches-list.container .action-bar-container.left .action-bar-filter-button-container .tooltip:hover {
      text-decoration: none; }

.voucher-batches #readOnlyBatchTooltip {
  position: absolute; }

.voucher-batches .voucherBatch-batchId a:link {
  text-decoration: none; }

.voucher-batches .voucherBatch-batchId a:hover {
  text-decoration: none; }

.voucher-batches .voucherBatch-batchId a:visited {
  text-decoration: none;
  color: #0072ce; }

.voucherBatch-status-information {
  display: flex;
  width: 100%;
  justify-content: space-between; }

.search-header {
  height: auto;
  position: relative;
  margin-top: -69px;
  top: 0;
  min-height: 66px;
  width: 100vw;
  left: calc(-50vw + 50% - 8px); }

.header-cell.voucherBatch-batchId.first {
  background-color: green;
  padding: 10px; }
  .header-cell.voucherBatch-batchId.first .row-cell a:visited {
    color: #0072ce; }
  .header-cell.voucherBatch-batchId.first .row-cell a:hover {
    text-decoration: none; }
  .header-cell.voucherBatch-batchId.first .row-cell .cell-preface.has-checkbox.has-expandable {
    margin-right: 0px; }
    .header-cell.voucherBatch-batchId.first .row-cell .cell-preface.has-checkbox.has-expandable .hide-icon {
      padding-right: 0px; }
  .header-cell.voucherBatch-batchId.first .row-cell .tooltip {
    opacity: 1;
    padding-right: 5px; }

.container.voucher-batches .header-cell .header-cell-wrapper .content {
  padding: 0px; }

.container.voucher-batches .header-cell .header-cell-wrapper .sort-icons {
  margin-left: 15px; }

.cell-container.voucherBatch-batchId.first .row-cell .cell-preface.has-checkbox.has-expandable {
  margin-right: 0px; }
  .cell-container.voucherBatch-batchId.first .row-cell .cell-preface.has-checkbox.has-expandable .expand-icon.hide-icon {
    padding: 0px 10px; }

.cell-container.voucherBatch-kabob .tooltip {
  opacity: 1;
  z-index: 0; }

.cell-container.voucherBatch-kabob .row-cell-content {
  justify-content: space-between; }

.cell-container.voucherBatch-kabob .read-only-status {
  width: 100%;
  display: flex;
  justify-content: space-between; }
  .cell-container.voucherBatch-kabob .read-only-status .status-icon {
    width: 40px; }

#voucher-batch-table-cancel-batch-modal {
  z-index: 9999; }

.voucherBatches-paginator {
  margin-top: 45px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between; }
  .voucherBatches-paginator .pucl-select-container {
    min-width: 80px; }
  .voucherBatches-paginator .page-link {
    text-decoration: none; }
  .voucherBatches-paginator .sie-select__field .sie-select-arrow {
    color: #999; }
  .voucherBatches-paginator .sr-only {
    width: 9px;
    height: 12px;
    margin: 0;
    position: inherit; }

.warning-modal-intro {
  margin-bottom: 20px; }

.code-products-list {
  padding: 40px 0px 0px 0px;
  width: 1300px; }
  .code-products-list .header {
    width: 1300px;
    padding-top: 30px;
    padding-bottom: 40px; }
  .code-products-list.loading-code-products {
    text-align: center;
    font-size: 20px; }
  .code-products-list .tooltip {
    z-index: unset; }
  .code-products-list .exclusiveToPartner .pucl-select-container .pucl-select__filter.hide-filter {
    display: unset; }
  .code-products-list .exclusiveToPartner .pucl-select__options-select-all {
    display: none; }
  .code-products-list .exclusiveToPartner .pucl-select-container .pucl-select__menu.multiple .select-element-menu {
    margin-top: 0px; }
  .code-products-list .pucl-action-bar {
    z-index: inherit; }
    .code-products-list .pucl-action-bar .bar .action-bar-container.left .action-bar-filter-button-container .tooltip {
      opacity: 1; }
    .code-products-list .pucl-action-bar .bar .action-bar-container.left .action-bar-filter-button-container .tooltip:hover {
      text-decoration: none; }
    .code-products-list .pucl-action-bar .bar .action-bar-container.right > #new-code-product.new-code-product.pucl-btn {
      padding-right: 20px;
      background-color: #0072ce; }
      .code-products-list .pucl-action-bar .bar .action-bar-container.right > #new-code-product.new-code-product.pucl-btn > .button-text {
        color: #fff; }
        .code-products-list .pucl-action-bar .bar .action-bar-container.right > #new-code-product.new-code-product.pucl-btn > .button-text > .pucl-icon {
          background-color: #0072ce; }
    .code-products-list .pucl-action-bar .pucl-filter-container.open .filters .filter {
      width: 500px; }
  .code-products-list .code-products-table {
    width: 1300px; }
    .code-products-list .code-products-table .code-products-content .sort-icons {
      margin-left: 0px !important; }
    .code-products-list .code-products-table .code-products-content .header-cell:first-child .header-cell-wrapper .content {
      padding-left: 0px; }
    .code-products-list .code-products-table .code-products-content .header-cell:not(:first-child) .header-cell-wrapper .content {
      padding-left: 0px;
      padding-right: 10px; }
    .code-products-list .code-products-table .code-products-content .cell-container.code-product-name.first .row-cell a:visited {
      color: #0072ce; }
    .code-products-list .code-products-table .code-products-content .cell-container.code-product-name.first .row-cell a:hover {
      text-decoration: none; }
    .code-products-list .code-products-table .code-products-content .cell-container.code-product-name.first .row-cell .cell-preface {
      margin-right: 0px; }
      .code-products-list .code-products-table .code-products-content .cell-container.code-product-name.first .row-cell .cell-preface .hide-icon {
        padding-right: 0px; }
    .code-products-list .code-products-table .code-products-content .cell-container .tooltip {
      opacity: 1; }
    .code-products-list .code-products-table .code-products-content .cell-container.applicable-agreement-types a:hover {
      text-decoration: none; }
    .code-products-list .code-products-table .code-products-content .cell-container.exclusive-to-Partner a:hover {
      text-decoration: none; }
    .code-products-list .code-products-table .code-products-content .cell-container .multiple {
      color: #0072ce;
      font-size: 14px;
      line-height: 20px;
      font-family: 'SSTPro-Medium'; }
  .code-products-list .code-products-paginator {
    margin-top: 45px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between; }
    .code-products-list .code-products-paginator .pucl-select-container {
      min-width: 80px; }
    .code-products-list .code-products-paginator .page-link {
      text-decoration: none; }
    .code-products-list .code-products-paginator .sie-select__field .sie-select-arrow {
      color: #999999; }
    .code-products-list .code-products-paginator .sr-only {
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit; }

.voucher-type-detail-page {
  padding-top: 140px; }
  .voucher-type-detail-page .voucher-type-header {
    background: white;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 100px;
    left: 0px;
    margin: 0px;
    width: 100%;
    height: 59px;
    padding: 5px 45px;
    z-index: 2002;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px; }
    .voucher-type-detail-page .voucher-type-header .header.layout {
      justify-content: center; }
      .voucher-type-detail-page .voucher-type-header .header.layout .header-title-container {
        padding-left: 15px; }
        .voucher-type-detail-page .voucher-type-header .header.layout .header-title-container .title {
          margin-top: 10px; }
      .voucher-type-detail-page .voucher-type-header .header.layout .buttons-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px; }
        .voucher-type-detail-page .voucher-type-header .header.layout .buttons-container .buttons-wrapper {
          display: flex;
          width: 184px;
          justify-content: space-between; }
          .voucher-type-detail-page .voucher-type-header .header.layout .buttons-container .buttons-wrapper .pucl-btn .button-text {
            width: 45px; }
            .voucher-type-detail-page .voucher-type-header .header.layout .buttons-container .buttons-wrapper .pucl-btn .button-text a {
              color: inherit; }
        .voucher-type-detail-page .voucher-type-header .header.layout .buttons-container .cancel-button-wrapper {
          display: flex;
          justify-content: space-between; }
          .voucher-type-detail-page .voucher-type-header .header.layout .buttons-container .cancel-button-wrapper .pucl-btn .button-text {
            width: 45px; }
            .voucher-type-detail-page .voucher-type-header .header.layout .buttons-container .cancel-button-wrapper .pucl-btn .button-text a {
              color: inherit; }
  .voucher-type-detail-page .voucher-type-details-section .voucher-type-details-form {
    padding: 72px; }
    .voucher-type-detail-page .voucher-type-details-section .voucher-type-details-form .voucher-type-details-row-2,
    .voucher-type-detail-page .voucher-type-details-section .voucher-type-details-form .voucher-type-details-row-3 {
      padding-top: 20px; }
  .voucher-type-detail-page .voucher-type-details-section .copy-icon > i.pucl-icon.ico.ico-copy {
    cursor: pointer;
    padding-left: 5px; }
  .voucher-type-detail-page .voucher-type-details-section .copy-icon .ico-copy:before {
    font-size: 18px; }
  .voucher-type-detail-page .associated-code-product-section .cell-container.code-product-name.first > .row-cell > .row-cell-content {
    padding-left: 20px; }
    .voucher-type-detail-page .associated-code-product-section .cell-container.code-product-name.first > .row-cell > .row-cell-content > a {
      cursor: pointer;
      color: #0072ce;
      font-weight: 500;
      text-decoration: none; }
  .voucher-type-detail-page .associated-code-product-section .cell-container.code-product-country > .row-cell > .row-cell-content .tooltip {
    opacity: 1;
    text-decoration: none; }
    .voucher-type-detail-page .associated-code-product-section .cell-container.code-product-country > .row-cell > .row-cell-content .tooltip .multiple {
      cursor: pointer;
      color: #0072ce;
      font-weight: 500;
      font-size: 14px; }
    .voucher-type-detail-page .associated-code-product-section .cell-container.code-product-country > .row-cell > .row-cell-content .tooltip .countries-tooltip-content {
      max-width: 300px; }
  .voucher-type-detail-page .voucher-batch-section .pucl-collapsible-container .pucl-table-wrapper .cell-container.voucher-batch-table-batch-id.first .has-checkbox > .row-cell.checkbox {
    margin-top: 0px;
    margin-bottom: 0px; }
  .voucher-type-detail-page .voucher-batch-section .pucl-collapsible-container .pucl-table-wrapper .cell-container.voucher-batch-table-batch-id.first .row-cell-content > .voucher-batchId-link {
    cursor: pointer;
    color: #0072ce;
    font-weight: 500;
    text-decoration: none; }
  .voucher-type-detail-page .voucher-batch-section .pucl-collapsible-container .pucl-table-wrapper .cell-container.voucher-batch-table-actions.actions-column .tooltip {
    opacity: 1; }
  .voucher-type-detail-page .voucher-batch-section .pucl-collapsible-container .voucher-batches-paginator {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 45px;
    margin-bottom: 145px;
    display: flex;
    justify-content: space-between; }
    .voucher-type-detail-page .voucher-batch-section .pucl-collapsible-container .voucher-batches-paginator .pucl-select-container {
      min-width: 80px; }
    .voucher-type-detail-page .voucher-batch-section .pucl-collapsible-container .voucher-batches-paginator .page-link {
      text-decoration: none; }
    .voucher-type-detail-page .voucher-batch-section .pucl-collapsible-container .voucher-batches-paginator .sie-select__field .sie-select-arrow {
      color: #999999; }
    .voucher-type-detail-page .voucher-batch-section .pucl-collapsible-container .voucher-batches-paginator .sr-only {
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit; }
  .voucher-type-detail-page .voucher-batch-section .pucl-collapsible-container .pucl-scrollarea--vertical-track {
    padding-bottom: 220px; }
  .voucher-type-detail-page .tooltip__content.tooltip--visible {
    z-index: 1000; }

#voucher-type-detail-form-header-desc {
  font-size: 12px; }

.cancel-batch-modal .warning-icon {
  color: #FBB700;
  margin-right: 5px; }

.sku-info-section-container {
  max-width: 1261px;
  margin: 23px auto 46px;
  padding: 25px 0; }
  .sku-info-section-container .sku-info-section-header {
    border-bottom: 1px solid #ccc;
    margin-left: 0;
    margin-right: 0;
    padding: 0 40px 10px; }
    .sku-info-section-container .sku-info-section-header .sku-info-section-header-label {
      font-size: 19px;
      line-height: 23px;
      color: #858383; }
    .sku-info-section-container .sku-info-section-header .new-sku-btn-col {
      margin-top: -5px; }
      .sku-info-section-container .sku-info-section-header .new-sku-btn-col button.new-sku-btn {
        font-size: 1.2em;
        color: #063A8F; }
        .sku-info-section-container .sku-info-section-header .new-sku-btn-col button.new-sku-btn span {
          border-radius: 100px;
          background: #063a8f;
          width: 23px;
          color: white;
          display: inline-block;
          margin-right: 10px;
          text-align: center; }
  .sku-info-section-container .sku-info-section-skus-table {
    padding: 30px 40px 10px; }
    .sku-info-section-container .sku-info-section-skus-table .container {
      width: auto; }
    .sku-info-section-container .sku-info-section-skus-table .sku-info-table-header, .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row {
      border-bottom: 1px solid #ccc; }
    .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row {
      padding: 15px 0; }
      .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row.header {
        padding: 0 0 5px 0;
        font-weight: bold; }
        .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row.header .sku-info-table-sku-id {
          padding-left: 35px;
          font-weight: bold; }
      .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row.no-children .sku-info-table-sku-id .expanding-icon, .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row.child .sku-info-table-sku-id .expanding-icon {
        visibility: hidden; }
      .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row .sku-info-table-sku-id {
        height: 20px;
        font-weight: bold; }
        .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row .sku-info-table-sku-id .sku-id-value {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 334px;
          display: inline-block; }
          .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row .sku-info-table-sku-id .sku-id-value .ellipsis {
            display: block;
            position: absolute;
            top: 0;
            right: 20px;
            width: 20px;
            height: 20px;
            z-index: 1;
            cursor: pointer; }
        .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row .sku-info-table-sku-id .tooltip {
          opacity: 1; }
      .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row .expanding-icon {
        cursor: pointer;
        top: -3px;
        margin-right: 5px; }
      .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row .actions-column {
        white-space: nowrap; }
        .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row .actions-column .remove-icon-wrapper {
          cursor: pointer; }
          .sku-info-section-container .sku-info-section-skus-table .sku-info-table-row .actions-column .remove-icon-wrapper .remove-icon {
            padding-right: 5px;
            height: 16px; }
  .sku-info-section-container .no-sku-text-container {
    text-align: center;
    padding: 50px 0; }
    .sku-info-section-container .no-sku-text-container .no-sku-text {
      color: grey; }
      .sku-info-section-container .no-sku-text-container .no-sku-text > span {
        display: block; }

.sku-info-section-sku-id-tooltip.top .tooltip-arrow {
  border-top-color: #767676; }

.sku-info-section-sku-id-tooltip .tooltip-inner {
  background-color: #767676;
  color: #ffffff;
  word-break: break-all; }

.sku-info-section .country.first.subtable .content, .sku-info-section .header-cell.country .content, .sku-info-section .header-cell.status .content, .sku-info-section .header-cell.start-date.subtable .content, .sku-info-section .header-cell.end-date.subtable .content {
  padding: 0; }

.sku-info-section .row-cell-content .ico-copy {
  margin-left: 5px; }

.sku-info-section .row-cell-content .ico-trashcan {
  margin-right: 5px; }

.sku-info-section .cell-container.sku-info-table-sku-id.first #skuId-truncate {
  cursor: default; }

.sku-info-section .cell-container.sku-info-table-sku-id.first .tooltip {
  opacity: 1; }

.sku-info-table-sku-id-link a {
  font-size: 14px;
  font-family: 'SSTPro-Roman'; }

.bulk-action-row {
  padding: 20px 56px; }

.skus-list {
  min-width: 1380px; }
  .skus-list .sku-header-wrapper {
    background: white;
    display: flex;
    position: fixed;
    top: 100px;
    left: 0;
    padding-left: 140px;
    width: 100%;
    height: 59px;
    z-index: 2101;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 15px;
    align-items: center;
    justify-content: center; }
    .skus-list .sku-header-wrapper .sku-header {
      height: inherit;
      display: flex;
      align-items: center;
      min-width: 1380px; }
      .skus-list .sku-header-wrapper .sku-header .sku-header-title h3 {
        margin-top: 0px;
        margin-bottom: 0px; }
      .skus-list .sku-header-wrapper .sku-header .sku-header-buttons {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-left: 50px; }
        .skus-list .sku-header-wrapper .sku-header .sku-header-buttons .sku-search {
          justify-content: end;
          padding-right: 15px; }
          .skus-list .sku-header-wrapper .sku-header .sku-header-buttons .sku-search .text-input-row-flex-wrapper {
            padding-top: 6px; }
        .skus-list .sku-header-wrapper .sku-header .sku-header-buttons .add-sku-button,
        .skus-list .sku-header-wrapper .sku-header .sku-header-buttons .cancel-button {
          width: 100px; }
        .skus-list .sku-header-wrapper .sku-header .sku-header-buttons .cancel-button {
          margin-right: 10px; }
  .skus-list .pucl-table-wrapper .pucl-action-bar .bar .action-bar-container.left .action-bar-filter-button-container .tooltip {
    opacity: 1; }
  .skus-list .pucl-table-wrapper .pucl-action-bar .bar .action-bar-container.left .action-bar-filter-button-container .tooltip:hover {
    text-decoration: none; }
  .skus-list .pucl-table-wrapper .pucl-action-bar .pucl-filter-container.open .filters .filter {
    width: 500px; }
  .skus-list .pucl-table-wrapper .pucl-data-table > .header-cell.sku-header-contentType.clickable.first.has-checkbox.sortable > div.header-cell-wrapper.has-preface {
    margin-left: 10px; }
  .skus-list .pucl-table-wrapper .pucl-data-table .header-cell .content {
    padding: 0px; }
  .skus-list .pucl-table-wrapper .pucl-data-table div.cell-container.sku-contentType.first .has-checkbox > .row-cell.checkbox {
    margin-top: 0px;
    margin-bottom: 0px; }
  .skus-list .pucl-table-wrapper .pucl-data-table div.cell-container.sku-contentType.first .has-checkbox > .expand-icon.hide-icon {
    display: none; }
  .skus-list .pucl-table-wrapper .pucl-data-table .cell-container.sku-parentProductName .truncate,
  .skus-list .pucl-table-wrapper .pucl-data-table .cell-container.sku-skuId .truncate,
  .skus-list .pucl-table-wrapper .pucl-data-table .cell-container.sku-skuName .truncate,
  .skus-list .pucl-table-wrapper .pucl-data-table .cell-container.sku-skuAvailabilities .truncate {
    word-break: break-word; }
  .skus-list .pucl-table-wrapper .pucl-data-table .cell-container.sku-parentProductName .tooltip,
  .skus-list .pucl-table-wrapper .pucl-data-table .cell-container.sku-skuId .tooltip,
  .skus-list .pucl-table-wrapper .pucl-data-table .cell-container.sku-skuName .tooltip,
  .skus-list .pucl-table-wrapper .pucl-data-table .cell-container.sku-skuAvailabilities .tooltip {
    opacity: 1;
    color: #363636;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none; }
  .skus-list .sku-selection-paginator {
    margin-top: 45px;
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between; }
    .skus-list .sku-selection-paginator .pucl-select-container {
      min-width: 80px; }
    .skus-list .sku-selection-paginator .page-link {
      text-decoration: none; }
    .skus-list .sku-selection-paginator .sie-select__field .sie-select-arrow {
      color: #999999; }
    .skus-list .sku-selection-paginator .sr-only {
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit; }

.code-product .code-product-header {
  background: white;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 100px;
  left: 0px;
  margin: 0px;
  width: 100%;
  height: 59px;
  padding: 5px 45px;
  z-index: 2002;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px; }
  .code-product .code-product-header .header.layout {
    justify-content: center; }
    .code-product .code-product-header .header.layout .header-title-container {
      padding-left: 15px; }
      .code-product .code-product-header .header.layout .header-title-container .title {
        margin-top: 10px; }
    .code-product .code-product-header .header.layout .buttons-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 15px; }
      .code-product .code-product-header .header.layout .buttons-container .buttons-wrapper {
        display: flex;
        width: 184px;
        justify-content: space-between; }
        .code-product .code-product-header .header.layout .buttons-container .buttons-wrapper .pucl-btn .button-text {
          width: 45px; }
          .code-product .code-product-header .header.layout .buttons-container .buttons-wrapper .pucl-btn .button-text a {
            color: inherit; }

.code-product .code-product-content {
  padding-top: 170px; }
  .code-product .code-product-content .product-type-section .pucl-collapsible-container .collapsible_subheader a {
    color: inherit; }
  .code-product .code-product-content .product-type-section .pucl-collapsible-container .collapsible_subheader .voucher-batch-id {
    display: flex; }
    .code-product .code-product-content .product-type-section .pucl-collapsible-container .collapsible_subheader .voucher-batch-id .voucher-label-link {
      padding-left: 5px; }
  .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content {
    padding: 20px 50px 20px 50px; }
    .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content {
      display: flex;
      flex-direction: column; }
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content .code-product-type,
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content .code-product-name,
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content .code-product-name-thirdparty,
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content .code-product-status-select,
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content .code-product-status-select,
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content .region-select,
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content .countries-multi-display,
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content .exclusive-to-partners-select-thirdparty {
        padding-top: 20px; }
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content .pucl-divider.secondary {
        margin-top: 20px;
        margin-bottom: 20px; }
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-third-party-left-content .end-date {
        padding-top: 20px; }
    .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-subscription-left-content .code-product-type-subscription {
      padding-top: 20px; }
    .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-section-right-content {
      display: flex;
      flex-direction: column; }
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-section-right-content .pucl-input {
        padding-top: 20px; }
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-section-right-content .region-flex-subscription .pucl-input {
        padding-top: 0px; }
      .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-section-right-content .korea-radio-group {
        margin-top: 20px; }
        .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-section-right-content .korea-radio-group .pucl-radio-group.required::after {
          display: none; }
        .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-section-right-content .korea-radio-group .pucl-radio-group .pucl-radio-group-label {
          padding-bottom: 0px;
          font-size: 14px;
          color: #363636;
          font-weight: 700; }
        .code-product .code-product-content .product-type-section .pucl-collapsible-container .product-type-section-content .product-type-section-right-content .korea-radio-group .pucl-radio-group .pucl-input.pucl-input-wrapper.pucl-radio {
          padding-top: 6px; }

.code-product .sku-info-section .sku-info-table-sku-id .row-cell .row-cell-content .tooltip {
  opacity: 1; }

.code-product .sku-info-section .pucl-table-wrapper .pucl-data-table .sku-info-table-actions .remove-text {
  padding-left: 5px; }

.code-product .sku-info-section .pucl-table-wrapper .header-cell:not(:first-child) .content {
  padding-left: 0px; }

.code-product .sku-info-section .expandable-container .header-border.stuck {
  box-shadow: none; }

.code-product .sku-info-section .expandable-container .bottom-shadow {
  display: none; }

.code-product .sku-info-section .expandable-container .pucl-scrollarea--vertical-track.table-scroll-margin {
  margin-top: 0px; }

.code-product .product-detail-form-right-third-party {
  padding-left: 100px; }

.code-product .code-product-status-select-third-party,
.code-product .applicable-agreement-types-thirdparty {
  margin-top: 20px; }

.code-product .product-detail-form-center-third-party {
  height: 524px; }

.code-product .product-detail-section {
  padding-bottom: 150px; }
  .code-product .product-detail-section .variable-load-help {
    color: #767676;
    size: 12px;
    font-weight: 400; }
  .code-product .product-detail-section .tooltip.face-value-tooltip {
    opacity: unset; }
    .code-product .product-detail-section .tooltip.face-value-tooltip .face-value-icon {
      color: #767676;
      margin-left: 5px; }
  .code-product .product-detail-section .product-detail-form-subscription {
    padding: 50px 50px 50px 50px;
    flex-direction: row; }
    .code-product .product-detail-section .product-detail-form-subscription .pucl-divider.secondary {
      margin-top: 20px;
      margin-bottom: 20px; }
    .code-product .product-detail-section .product-detail-form-subscription .face-value-money {
      margin-left: -15px; }
      .code-product .product-detail-section .product-detail-form-subscription .face-value-money .pucl-input.pucl-input-wrapper .pucl-input-field-container {
        width: 378px; }
  .code-product .product-detail-section .product-detail-form-money {
    padding: 50px 50px 50px 50px;
    flex-direction: row;
    align-items: start; }
    .code-product .product-detail-section .product-detail-form-money .pucl-divider.secondary {
      margin-top: 20px;
      margin-bottom: 20px; }
    .code-product .product-detail-section .product-detail-form-money .face-value-money {
      margin-top: 20px; }
      .code-product .product-detail-section .product-detail-form-money .face-value-money > .face-value-selection {
        display: flex; }
        .code-product .product-detail-section .product-detail-form-money .face-value-money > .face-value-selection .pucl-select__field {
          width: 110px;
          margin-top: 10px;
          border-radius: 2.5px 0px 0px 2.5px; }
        .code-product .product-detail-section .product-detail-form-money .face-value-money > .face-value-selection .pucl-input.pucl-input-wrapper .pucl-input-field-container {
          border-radius: 0px 2.5px 2.5px 0px;
          border-left: none; }
      .code-product .product-detail-section .product-detail-form-money .face-value-money > .face-value-selection-hidden {
        display: flex; }
        .code-product .product-detail-section .product-detail-form-money .face-value-money > .face-value-selection-hidden .pucl-select__field {
          width: 110px;
          margin-top: 10px;
          border-radius: 2.5px 0px 0px 2.5px; }
        .code-product .product-detail-section .product-detail-form-money .face-value-money > .face-value-selection-hidden .pucl-input.pucl-input-wrapper .pucl-input-field-container {
          border-radius: 0px 2.5px 2.5px 0px;
          border-left: none; }
  .code-product .product-detail-section .product-detail-form-center-money {
    height: 432px; }
  .code-product .product-detail-section .product-detail-form-center-subscription {
    height: 340px; }
  .code-product .product-detail-section .code-product-status-select-money,
  .code-product .product-detail-section .countries-select,
  .code-product .product-detail-section .code-product-description,
  .code-product .product-detail-section .applicable-agreement-types-money {
    margin-top: 20px; }
  .code-product .product-detail-section .applicable-agreement-types-subscription {
    margin-top: 20px; }
  .code-product .product-detail-section .exclusive-to-partners-select,
  .code-product .product-detail-section .exclusive-to-partners-select-subscription {
    margin-top: 20px; }
  .code-product .product-detail-section .exclusive-to-partners-select-money {
    margin-top: 20px; }
  .code-product .product-detail-section .countries-multi-display {
    margin-top: 20px; }
    .code-product .product-detail-section .countries-multi-display .pucl-select-pill__close,
    .code-product .product-detail-section .countries-multi-display .pucl-select-pill__clear {
      pointer-events: none;
      cursor: pointer; }
  .code-product .product-detail-section .no-product-details {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    font-size: 18px; }

.warning-modal-footer {
  padding-top: 20px; }

.tos {
  width: 100%;
  position: relative; }
  .tos .header {
    top: 60px;
    width: calc(100% + 60px);
    height: 60px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 70px;
    position: fixed;
    left: -30px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1); }
    .tos .header .title {
      margin-bottom: 20px; }
    .tos .header .spacer {
      width: 16px; }
    .tos .header .buttons {
      display: flex; }
  .tos .body {
    padding: 0px 240px;
    padding-bottom: 40px;
    flex-direction: column; }
    .tos .body .title {
      margin-bottom: 50px;
      font-size: 30px; }
    .tos .body h3 {
      margin-top: 0px; }
    .tos .body .terms {
      white-space: pre-wrap;
      text-align: justify; }

.need-pgp-setup-page {
  padding-top: 41px; }
  .need-pgp-setup-page a {
    text-decoration: none; }
  .need-pgp-setup-page .header {
    width: calc(100% + 60px);
    height: 60px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 70px 12px 60px;
    position: fixed;
    left: -30px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1); }
    .need-pgp-setup-page .header .title {
      margin-bottom: 20px; }
    .need-pgp-setup-page .header .spacer {
      width: 16px; }
    .need-pgp-setup-page .header .buttons {
      display: flex; }
  .need-pgp-setup-page .need-pgp-setup-page-content {
    padding-top: 100px; }
    .need-pgp-setup-page .need-pgp-setup-page-content .pucl-label {
      font-weight: 500;
      text-transform: none !important; }

.order-success-page {
  padding-top: 41px; }
  .order-success-page a {
    text-decoration: none; }
  .order-success-page .order-success-page-content-wrapper {
    padding-top: 140px;
    width: 100%; }
    .order-success-page .order-success-page-content-wrapper .order-success-page-content {
      max-width: 600px;
      margin: 0 auto;
      text-align: center; }
      .order-success-page .order-success-page-content-wrapper .order-success-page-content .check_circle-icon {
        color: #2b9e98; }
      .order-success-page .order-success-page-content-wrapper .order-success-page-content .footer {
        text-align: left; }
      .order-success-page .order-success-page-content-wrapper .order-success-page-content .footer-buttons {
        padding-top: 80px;
        display: inline-flex;
        flex-wrap: wrap; }
        .order-success-page .order-success-page-content-wrapper .order-success-page-content .footer-buttons .product-catalog {
          margin-right: 17.5px; }
          .order-success-page .order-success-page-content-wrapper .order-success-page-content .footer-buttons .product-catalog .pucl-btn {
            width: 185px; }
        .order-success-page .order-success-page-content-wrapper .order-success-page-content .footer-buttons .orders {
          margin-left: 17.5px; }
          .order-success-page .order-success-page-content-wrapper .order-success-page-content .footer-buttons .orders .pucl-btn {
            width: 185px; }

.voucher-batch {
  padding-top: 140px; }
  .voucher-batch .tooltip {
    opacity: 1;
    text-decoration: none; }
  .voucher-batch .voucher-batch-detail-page > .flex.voucher-batch-detail-page-loading {
    display: flex;
    justify-content: center; }
  .voucher-batch .voucher-batch-header {
    background: white;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100px;
    left: 0px;
    margin: 0px;
    width: 100%;
    height: 59px;
    padding: 5px 45px;
    z-index: 2002;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px; }
    .voucher-batch .voucher-batch-header .voucher-batch-header-content {
      width: 1273px;
      display: flex; }
      .voucher-batch .voucher-batch-header .voucher-batch-header-content .voucher-batch-header-left > .title {
        margin-top: 10px; }
      .voucher-batch .voucher-batch-header .voucher-batch-header-content .voucher-batch-header-right {
        display: flex;
        align-items: center;
        justify-content: end; }
        .voucher-batch .voucher-batch-header .voucher-batch-header-content .voucher-batch-header-right .cancel-button {
          margin-right: 10px; }
  .voucher-batch .voucher-batch-info-section-wrapper .pucl-nested {
    padding-bottom: 250px; }
  .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-top {
    padding-top: 30px;
    padding-left: 96px; }
    .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-top .voucher-type-id-label,
    .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-top .voucher-batch-status {
      font-size: 16px;
      font-weight: 600; }
    .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-top .voucher-type-id {
      font-size: 16px;
      font-weight: 600;
      padding-left: 5px;
      color: #0072ce; }
    .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-top .voucher-type-id-edit-button {
      font-size: 14px;
      font-weight: 600;
      padding-left: 5px;
      color: #0072ce; }
  .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-top .voucher-id {
    padding-right: 10px; }
  .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details {
    padding-top: 30px; }
    .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details .layout.voucher-batch-details-layout {
      justify-content: center; }
      .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details .layout.voucher-batch-details-layout .voucher-batch-form-group {
        padding-top: 20px; }
        .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details .layout.voucher-batch-details-layout .voucher-batch-form-group.voucher-batch-details-form-field > .pucl-input.pucl-input-wrapper.pucl-text-area {
          width: 100% !important; }
          .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details .layout.voucher-batch-details-layout .voucher-batch-form-group.voucher-batch-details-form-field > .pucl-input.pucl-input-wrapper.pucl-text-area .text-area-input {
            width: 100%; }
  .voucher-batch .voucher-batch-info-section-wrapper .copy-icon > i.pucl-icon.ico.ico-copy {
    cursor: pointer;
    padding-left: 5px; }
  .voucher-batch .voucher-batch-info-section-wrapper .copy-icon .ico-copy:before {
    font-size: 18px; }
  .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-form hr {
    margin: 30px 65px 30px 65px;
    border-color: #bdbdbd; }
  .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-form .download-section-container {
    padding-left: 95px; }
    .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-form .download-section-container .button-container-col {
      display: flex;
      align-items: start;
      flex-direction: column; }
      .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-form .download-section-container .button-container-col .pucl-progress-indicator-container {
        padding-top: 80px; }
      .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-form .download-section-container .button-container-col .button-group {
        padding-top: 30px; }
      .voucher-batch .voucher-batch-info-section-wrapper .voucher-batch-details-form .download-section-container .button-container-col .button {
        padding-top: 10px;
        padding-right: 10px; }
  .voucher-batch .date-country-section-container-wrap {
    height: 400px; }
    .voucher-batch .date-country-section-container-wrap .container.date-country-section-container {
      padding-top: 27px; }
      .voucher-batch .date-country-section-container-wrap .container.date-country-section-container .voucher-batch-header-desc {
        padding: 10px 0px 10px 0px; }
      .voucher-batch .date-country-section-container-wrap .container.date-country-section-container .layout.sku-country-availability-container {
        padding: 0 35px; }
        .voucher-batch .date-country-section-container-wrap .container.date-country-section-container .layout.sku-country-availability-container .check-button-container {
          padding-top: 20px; }
      .voucher-batch .date-country-section-container-wrap .container.date-country-section-container hr {
        margin: 10px;
        border-color: #bdbdbd; }
      .voucher-batch .date-country-section-container-wrap .container.date-country-section-container .voucher-batch-time-zone-radio-container {
        padding-top: 35px; }
      .voucher-batch .date-country-section-container-wrap .container.date-country-section-container .voucher-batch-date-time {
        display: flex; }
        .voucher-batch .date-country-section-container-wrap .container.date-country-section-container .voucher-batch-date-time .voucher-batch-date-input {
          padding-right: 10px; }
        .voucher-batch .date-country-section-container-wrap .container.date-country-section-container .voucher-batch-date-time .voucher-batch-time-input {
          padding-top: 23px; }
          .voucher-batch .date-country-section-container-wrap .container.date-country-section-container .voucher-batch-date-time .voucher-batch-time-input .pucl-time-input.t12hr div:nth-child(2) {
            padding-top: 5px; }
  .voucher-batch .batch-audit-history .header-cell.Date.first .header-cell-wrapper {
    margin-left: 10px; }
  .voucher-batch .batch-audit-history .header-cell-wrapper .content {
    padding-left: 10px; }
  .voucher-batch .batch-audit-history .cell-container.cell .row-cell {
    padding-left: 20px; }

.cancel-batch-modal .pucl-radio-group.required::after {
  display: none; }

.cancel-batch-modal .warning-message {
  padding-top: 38px; }

.date-time {
  display: flex; }
  .date-time .date-input {
    padding-right: 10px; }
  .date-time .time-input {
    padding-top: 23px; }
    .date-time .time-input .pucl-time-input.t12hr div:nth-child(2) {
      padding-top: 5px; }

.voucher-type-id-selector-container {
  min-width: 1380px; }
  .voucher-type-id-selector-container .pucl-table-wrapper .header-cell.type-id-header-name.first.has-checkbox > .header-preface > .pucl-input.pucl-input-wrapper.pucl-checkbox {
    visibility: hidden; }
  .voucher-type-id-selector-container .pucl-table-wrapper div.cell-container.voucher-type-name.first .has-checkbox > .row-cell.checkbox {
    margin-top: 0px;
    margin-bottom: 0px; }
  .voucher-type-id-selector-container .pucl-table-wrapper div.cell-container.voucher-type-name.first .has-checkbox > .expand-icon.hide-icon {
    display: none; }
  .voucher-type-id-selector-container .pucl-table-wrapper .header-cell.first .header-cell-wrapper {
    margin-left: 0px; }
    .voucher-type-id-selector-container .pucl-table-wrapper .header-cell.first .header-cell-wrapper .content {
      padding-left: 15px; }
  .voucher-type-id-selector-container .pucl-table-wrapper .header-cell .header-cell-wrapper .content {
    padding-left: 0px; }
  .voucher-type-id-selector-container .type-id-wrapper {
    background: white;
    display: flex;
    position: fixed;
    top: 100px;
    left: 0;
    padding-left: 40px;
    width: 100%;
    height: 59px;
    z-index: 2101;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 15px;
    align-items: center;
    justify-content: center; }
    .voucher-type-id-selector-container .type-id-wrapper .type-id {
      height: inherit;
      display: flex;
      align-items: center;
      min-width: 1380px; }
      .voucher-type-id-selector-container .type-id-wrapper .type-id .type-id-title h3 {
        margin-top: 0px;
        margin-bottom: 0px; }
      .voucher-type-id-selector-container .type-id-wrapper .type-id .type-id-buttons {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-left: 50px; }
        .voucher-type-id-selector-container .type-id-wrapper .type-id .type-id-buttons .type-id-search.search-open {
          justify-content: end;
          padding-right: 15px; }
          .voucher-type-id-selector-container .type-id-wrapper .type-id .type-id-buttons .type-id-search.search-open .text-input-row-flex-wrapper {
            padding-top: 6px; }
          .voucher-type-id-selector-container .type-id-wrapper .type-id .type-id-buttons .type-id-search.search-open > .pucl-search__input-container > .pucl-search__text-input-container > .pucl-input.pucl-input-wrapper {
            padding-bottom: 0px; }
        .voucher-type-id-selector-container .type-id-wrapper .type-id .type-id-buttons .add-type-id-button,
        .voucher-type-id-selector-container .type-id-wrapper .type-id .type-id-buttons .cancel-button {
          width: 120px; }
        .voucher-type-id-selector-container .type-id-wrapper .type-id .type-id-buttons .add-type-id-button {
          width: 191px; }
        .voucher-type-id-selector-container .type-id-wrapper .type-id .type-id-buttons .cancel-button {
          margin-right: 10px; }
  .voucher-type-id-selector-container .select-voucher-type-paginator {
    margin-top: 45px;
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between; }
    .voucher-type-id-selector-container .select-voucher-type-paginator .pucl-select-container {
      min-width: 80px; }
    .voucher-type-id-selector-container .select-voucher-type-paginator .page-link {
      text-decoration: none; }
    .voucher-type-id-selector-container .select-voucher-type-paginator .sie-select__field .sie-select-arrow {
      color: #999999; }
    .voucher-type-id-selector-container .select-voucher-type-paginator .sr-only {
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit; }

.container.maintainance-page {
  margin: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#3FAECD, #2391CB);
  /* .content-title-icon {
    width: 100px;
    height: 200px;
  } */ }
  .container.maintainance-page .layout .flex:last-of-type {
    margin: auto;
    margin-top: 200px;
    margin-bottom: 200px; }
  .container.maintainance-page .maintainance-page-content-wrapper {
    width: 860px;
    height: 460px;
    background-color: #FFFFFF;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .container.maintainance-page .maintainance-page-content-wrapper .content-title h3 {
      font-weight: 200;
      font-size: 36px; }
    .container.maintainance-page .maintainance-page-content-wrapper .content-title-icon {
      width: 128px; }
    .container.maintainance-page .maintainance-page-content-wrapper .maintainance-page-content {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 10px;
      gap: 10px; }
      .container.maintainance-page .maintainance-page-content-wrapper .maintainance-page-content .maintainance-page-content-body {
        padding-top: 10px;
        text-align: center;
        font-size: 20px;
        font-weight: 200; }
    .container.maintainance-page .maintainance-page-content-wrapper .help-link {
      display: flex;
      text-decoration: none;
      color: #0072CE;
      font-size: 14px;
      font-weight: 500; }
    .container.maintainance-page .maintainance-page-content-wrapper .help-link:hover {
      text-decoration: none;
      color: #0089D8; }
    .container.maintainance-page .maintainance-page-content-wrapper .help-text {
      margin-left: 5px; }

.adminDashboard-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  /** Maintenance Mode - Start */
  /** Maintenance Mode - End */
  /** Batch Notifications - Start */
  /** Batch Notifications - End */
  /** Partner Merge - Start */
  /** Partner Merge - End */ }
  @media (max-width: 1199px) {
    .adminDashboard-page {
      margin-top: -20px; } }
  .adminDashboard-page .dashboard-title {
    align-self: start;
    margin-bottom: 60px; }
  .adminDashboard-page .venom-jobs-table {
    width: 100%;
    display: flex;
    justify-content: center; }
    .adminDashboard-page .venom-jobs-table .pucl-table-wrapper .status {
      display: flex;
      justify-content: center; }
      .adminDashboard-page .venom-jobs-table .pucl-table-wrapper .status .row-cell {
        padding-right: 0px; }
      .adminDashboard-page .venom-jobs-table .pucl-table-wrapper .status .switch-wrapper input {
        min-width: 28px; }
      .adminDashboard-page .venom-jobs-table .pucl-table-wrapper .status .switch-wrapper .pucl-input.pucl-input-wrapper {
        padding-bottom: 0px; }
    .adminDashboard-page .venom-jobs-table .pucl-table-wrapper .job-code {
      border-left: 1px solid #E7E7E7; }
    .adminDashboard-page .venom-jobs-table .pucl-table-wrapper .job-description .tooltip {
      opacity: 1;
      z-index: 2100;
      margin-left: 0px;
      text-decoration: none; }
      .adminDashboard-page .venom-jobs-table .pucl-table-wrapper .job-description .tooltip .description-tooltip {
        max-width: 200px;
        white-space: wrap; }
      .adminDashboard-page .venom-jobs-table .pucl-table-wrapper .job-description .tooltip .truncate {
        color: #363636;
        font-size: 14px; }
  .adminDashboard-page .vids-jobs-table {
    width: 100%;
    display: flex;
    justify-content: center; }
    .adminDashboard-page .vids-jobs-table .pucl-table-wrapper .status {
      display: flex;
      justify-content: center; }
      .adminDashboard-page .vids-jobs-table .pucl-table-wrapper .status .row-cell {
        padding-right: 0px; }
      .adminDashboard-page .vids-jobs-table .pucl-table-wrapper .status .switch-wrapper input {
        min-width: 28px; }
      .adminDashboard-page .vids-jobs-table .pucl-table-wrapper .status .switch-wrapper .pucl-input.pucl-input-wrapper {
        padding-bottom: 0px; }
    .adminDashboard-page .vids-jobs-table .pucl-table-wrapper .job-code {
      border-left: 1px solid #E7E7E7; }
    .adminDashboard-page .vids-jobs-table .pucl-table-wrapper .job-description .tooltip {
      opacity: 1;
      z-index: 2100;
      margin-left: 0px;
      text-decoration: none; }
      .adminDashboard-page .vids-jobs-table .pucl-table-wrapper .job-description .tooltip .description-tooltip {
        max-width: 200px;
        white-space: wrap; }
      .adminDashboard-page .vids-jobs-table .pucl-table-wrapper .job-description .tooltip .truncate {
        color: #363636;
        font-size: 14px; }
  .adminDashboard-page .maintenance-mode {
    width: 100%;
    display: flex;
    justify-content: center; }
    .adminDashboard-page .maintenance-mode .maintenance-mode-content {
      padding: 50px 50px 50px 20px;
      display: flex; }
      .adminDashboard-page .maintenance-mode .maintenance-mode-content .maintenance-mode-toggle {
        padding-left: 20px; }
        .adminDashboard-page .maintenance-mode .maintenance-mode-content .maintenance-mode-toggle .maintenance-mode-switch .pucl-switch-input {
          margin-top: 2px; }
      .adminDashboard-page .maintenance-mode .maintenance-mode-content .maintenance-mode-value {
        padding-left: 5px; }
  .adminDashboard-page .batch-notifications {
    width: 100%;
    display: flex;
    justify-content: center; }
    .adminDashboard-page .batch-notifications .batch-notifications-content {
      padding: 50px 50px 50px 20px;
      display: block;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      min-height: 250px; }
      .adminDashboard-page .batch-notifications .batch-notifications-content .inputs {
        display: flex;
        padding-top: 20px; }
        .adminDashboard-page .batch-notifications .batch-notifications-content .inputs .entity-type,
        .adminDashboard-page .batch-notifications .batch-notifications-content .inputs .notification-type {
          min-width: 200px;
          padding-left: 30px; }
    .adminDashboard-page .batch-notifications .batch-notifications-footer {
      height: 70px;
      display: flex;
      width: 100%;
      justify-content: end;
      align-items: center;
      padding-right: 20px; }
  .adminDashboard-page .partner-merge {
    width: 100%;
    display: flex;
    justify-content: center; }
    .adminDashboard-page .partner-merge .partner-merge-content {
      padding: 50px 50px 50px 20px;
      display: block;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
      .adminDashboard-page .partner-merge .partner-merge-content .inputs {
        display: flex;
        padding-top: 20px; }
        .adminDashboard-page .partner-merge .partner-merge-content .inputs .arrow-forward {
          display: flex;
          align-items: center;
          padding: 16px 20px 0px 20px; }
        .adminDashboard-page .partner-merge .partner-merge-content .inputs .validate-button {
          display: block;
          align-items: center;
          padding-top: 32px;
          padding-left: 20px; }
    .adminDashboard-page .partner-merge .partner-merge-footer {
      height: 70px;
      display: flex;
      width: 100%;
      justify-content: end;
      align-items: center;
      padding-right: 20px; }

.confirmation {
  width: 100%;
  height: 100%;
  position: relative; }
  .confirmation .header {
    top: 60px;
    width: calc(100% + 60px);
    height: 60px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 70px;
    position: fixed;
    left: -30px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 900px) {
      .confirmation .header {
        display: none; } }
  .confirmation .body {
    padding-bottom: 40px;
    flex-direction: column;
    align-items: center;
    height: 100%; }
    .confirmation .body .content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%; }
      @media only screen and (max-width: 900px) {
        .confirmation .body .content {
          flex-direction: column;
          padding: 0px 40px; } }
      .confirmation .body .content .left-content .image {
        width: 240px;
        height: 240px; }
        @media only screen and (max-width: 900px) {
          .confirmation .body .content .left-content .image {
            width: 120px;
            height: 120px; } }
      .confirmation .body .content .right-content .title {
        font-size: 36px;
        font-weight: 300; }
        @media only screen and (max-width: 900px) {
          .confirmation .body .content .right-content .title {
            text-align: center; } }
      .confirmation .body .content .right-content .description {
        margin-top: 24px;
        font-size: 20px;
        font-weight: 300;
        white-space: pre-wrap;
        max-width: 400px; }
      .confirmation .body .content .right-content .button-container {
        margin-top: 24px; }
        @media only screen and (max-width: 900px) {
          .confirmation .body .content .right-content .button-container {
            text-align: center; } }
        @media only screen and (max-width: 900px) {
          .confirmation .body .content .right-content .button-container .confirmation-button {
            width: 148px; } }

.spinner-loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #363636;
  margin: auto;
  padding: 25px;
  height: 100%; }

.inline .spinner-loader {
  padding: 0; }

.rotate-spinner {
  -webkit-animation: 2s linear infinite;
          animation: 2s linear infinite;
  -webkit-animation-name: rotate-spinner;
          animation-name: rotate-spinner; }

.push-spinner-left {
  margin-right: 1em; }

.push-spinner-right {
  margin-left: 1em; }

.push-spinner-down {
  margin-top: 2px; }

@-webkit-keyframes rotate-spinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate-spinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

#globalHeader {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 12108; }

.global-header {
  top: 0;
  margin-bottom: 0;
  border-radius: none; }
  .global-header .partners-logo {
    margin-top: -5px;
    width: 236px;
    height: auto; }

.venom-header {
  z-index: 2150;
  padding-top: 60px;
  margin-left: -30px;
  margin-right: -30px;
  position: relative; }
  .venom-header .pucl-local-navigation {
    position: fixed;
    font-family: 'SSTPro-Medium'; }
    .venom-header .pucl-local-navigation .dropdown label {
      font-weight: inherit; }
    .venom-header .pucl-local-navigation .dropdown ul {
      border: none;
      margin: 0px;
      padding: 0px; }
  .venom-header li span {
    text-decoration: none; }
  .venom-header .dropdown-menu {
    z-index: 2150; }
  @media (max-width: 1199px) {
    .venom-header {
      top: -20px; } }

.venom-header-disabled-orders {
  z-index: 2150;
  padding-top: 60px;
  margin-left: -30px;
  margin-right: -30px;
  position: relative; }
  .venom-header-disabled-orders .pucl-local-navigation {
    position: fixed;
    font-family: 'SSTPro-Medium'; }
    .venom-header-disabled-orders .pucl-local-navigation .dropdown label {
      font-weight: inherit; }
    .venom-header-disabled-orders .pucl-local-navigation .dropdown ul {
      border: none;
      margin: 0px;
      padding: 0px; }
  .venom-header-disabled-orders li span {
    text-decoration: none; }
  .venom-header-disabled-orders .dropdown-menu {
    z-index: 2150; }
  .venom-header-disabled-orders .pucl-local-navigation > .dropdown:first-child {
    cursor: default;
    pointer-events: none; }
  @media (max-width: 1199px) {
    .venom-header-disabled-orders {
      top: -20px; } }

.navbar-default {
  background-color: #000;
  background-image: none;
  border: none; }
  .navbar-default .navbar-nav > li > a {
    text-shadow: none; }
    .navbar-default .navbar-nav > li > a:focus {
      color: #777; }
  .navbar-default .navbar-nav > .active > a {
    background-image: none;
    background-color: #000;
    color: #fff; }
    .navbar-default .navbar-nav > .active > a:hover {
      background-image: none;
      background-color: #000;
      color: #fff; }
  .navbar-default .navbar-nav > li > a:hover {
    color: #fff; }
  .navbar-default .navbar-nav > .active > a {
    color: #fff;
    background: #000; }
    .navbar-default .navbar-nav > .active > a:focus {
      color: #fff;
      background: #000; }
  .navbar-default .navbar-nav li.disabled a {
    color: #777; }

.help-link {
  color: #fff;
  font-size: 30px;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer; }

.navbar-nav > li {
  padding: 0 20px; }

