.skus-list {
  min-width: 1380px;

  .sku-header-wrapper {
    background: rgb(255, 255, 255);
    display: flex;
    position: fixed;
    top: 100px;
    left: 0;
    padding-left: 140px;
    width: 100%;
    height: 59px;
    z-index: 2101;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 15px;
    align-items: center;
    justify-content: center;
  
    .sku-header {
      height: inherit;
      display: flex;
      align-items: center;
      min-width: 1380px;

      .sku-header-title {
        h3 {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }

      .sku-header-buttons {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-left: 50px;

        .sku-search {
          justify-content: end;
          padding-right: 15px;

          .text-input-row-flex-wrapper {
            padding-top: 6px;
          }
        }

        .add-sku-button, 
        .cancel-button {
          width: 100px;
        }

        .cancel-button {
          margin-right: 10px;
        }
      }
    }
  }

  .pucl-table-wrapper {
    .pucl-action-bar {
      .bar {
        .action-bar-container.left {
          .action-bar-filter-button-container {
            .tooltip {
              opacity: 1;
            }
  
            .tooltip:hover {
              text-decoration:none;
            }
          }
        }
      }

      .pucl-filter-container.open {
        .filters {
          .filter {
            width: 500px;
          }
        }
      }
    }
    .pucl-data-table {
      > .header-cell.sku-header-contentType.clickable.first.has-checkbox.sortable {
        > div.header-cell-wrapper.has-preface {
          margin-left: 10px;
        }
      }
      .header-cell .content {
        padding: 0px;
      }
      div.cell-container.sku-contentType.first {
        .has-checkbox {
          > .row-cell.checkbox {
            margin-top: 0px;
            margin-bottom: 0px;
          }
          > .expand-icon.hide-icon {
            display: none;
          }
        }
      }

      .cell-container.sku-parentProductName,
      .cell-container.sku-skuId,
      .cell-container.sku-skuName,
      .cell-container.sku-skuAvailabilities {
        .truncate{
          word-break: break-word;
        }
        .tooltip {
          opacity: 1;
          color: #363636;
          font-size: 14px;
          line-height: 20px;
          text-decoration: none;
        }
      }
    }
  }

  .sku-selection-paginator {
    margin-top: 45px;
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between;
    .pucl-select-container {
      min-width: 80px;
    }
  
    .page-link {
      text-decoration: none;
    }
  
    .sie-select__field{
      .sie-select-arrow{
        color: #999999;
      }
    }
  
    .sr-only{
      width: 9px;
      height: 12px;
      margin: 0;
      position: inherit;
    }
  }
}