.partner-users {
  .partner-detail-users-grid {
    width: 100%;
    margin: 0px;
  
    .partner-detail-users-header-col {
      padding: 0px;
      .pucl-loader {
        margin-top: 100px !important;
      }
    
      div {
        .pucl-data-table {
          .header-cell.first {
            .content {
              padding-left: 0px;
            }
          }
          .header-cell:not(.first) {
            .content {
              padding-left: 0px;
            }
          }
          .cell-container.first {
            padding-left: 20px;
          }
          .header-cell-pgpKeys-hidden {
            display: none;
          }
    
          .header-cell-role {
            pointer-events: none;
          }
    
          .header-cell-pgpKeys {
            pointer-events: none;
          }

          .header-cell-status {
            .sort-icons {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  
  .new-pgpKey {
    color: #003597;
    font-weight: 500;
    padding: 0 !important;
    margin-top: -8px;
    text-decoration: none !important;
  }
  
  .partner-detail-users-pgp-keys {
    text-decoration: none !important;
  }
  
  .pgp-button-label {
    width: 100%;
    display: flex;
    align-items: center;
    color: #003597;
  }
  
  .pgp-key-add-plus-button {
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #003597;
    border-radius: 50%;
    font-size: 13px;
  }
  
  .partner-detail-users-paginator {
    margin: 30px;
    display: flex;
    padding-bottom: 15px;
    justify-content: space-between;
    .pucl-select-container {
      min-width: 80px;
    }
  
    .page-link {
      text-decoration: none;
    }
  
    .sr-only {
      width: 7.5px!important;
      height: 12px!important;
      margin: 0;
      position: inherit;
    }
  }
}

