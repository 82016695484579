.tooltip-comment, .tooltip-input {
  width: 200px;
  opacity: 1!important;

  .tooltip-inner {
    text-align: left;
    background-color: #fff;
    border: 1px solid #878787;
    color: #000;
    font-size: 10px;
    padding: 5px 8px 8px;

    textarea {
      font-size: 12px;
    }

    span {
      color: #878787;
    }

    .input-note {
      line-height: 2;
    }
    
    .input-buttons {
      text-align: right;
    }

    [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .comment-header {
      border-bottom: 1px solid #878787;
      line-height: 2;
    }
    span.close-icon {
      float: right;
      color: #000;
      font-weight:bold;
      cursor: pointer;
    }
    .comment-info {
      line-height:2;
      padding-top: 3px;
      padding-bottom:8px;
    }
    .comment-date {
      text-align: right;
    }
    .comment-body {
      font-size: 12px;
      word-break: break-all;
    }
  }
}
