.code-product {
  .code-product-header {
    background: rgb(255, 255, 255);
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 100px;
    left: 0px;
    margin: 0px;
    width: 100%;
    height: 59px;
    padding: 5px 45px;
    z-index: 2002;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;

    .header.layout {
      justify-content: center;

      .header-title-container {
        padding-left: 15px;
        .title {
          margin-top: 10px;
        }
      }

      .buttons-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px;

        .buttons-wrapper {
          display: flex;
          width: 184px;
          justify-content: space-between;

          .pucl-btn {
            .button-text {
              width: 45px;

              a {
                color: inherit;
              }
            }
          }
        }
      }
    }
  }

  .code-product-content {
    padding-top: 170px;

    .product-type-section {
      .pucl-collapsible-container {
        .collapsible_subheader {
          a {
            color: inherit;
          }

          .voucher-batch-id {
            display: flex;

            .voucher-label-link {
              padding-left: 5px;
            }
          }
        }

        .product-type-section-content {
          padding: 20px 50px 20px 50px;

          .product-type-third-party-left-content {
            display: flex;
            flex-direction: column;

            .code-product-type,
            .code-product-name,
            .code-product-name-thirdparty,
            .code-product-status-select,
            .code-product-status-select,
            .region-select,
            .countries-multi-display,
            .exclusive-to-partners-select-thirdparty {
              padding-top: 20px;
            }

            .pucl-divider.secondary {
              margin-top: 20px;
              margin-bottom: 20px;
            }

            .end-date {
              padding-top: 20px;
            }
          }

          .product-type-subscription-left-content {
            .code-product-type-subscription {
              padding-top: 20px;
            }
          }

          .product-type-section-right-content {
            display: flex;
            flex-direction: column;

            .pucl-input {
              padding-top: 20px;
            }

            .region-flex-subscription {
              .pucl-input {
                padding-top: 0px;
              }
            }

            .korea-radio-group {
              margin-top: 20px;

              .pucl-radio-group.required::after {
                display: none;
              }

              .pucl-radio-group {
                .pucl-radio-group-label {
                  padding-bottom: 0px;
                  font-size: 14px;
                  color: #363636;
                  font-weight: 700;
                }

                .pucl-input.pucl-input-wrapper.pucl-radio {
                  padding-top: 6px;
                }
              }
            }
          }
        }
      }
    }
  }


  // Sku Info
  .sku-info-section {
    .sku-info-table-sku-id {
      .row-cell {
        .row-cell-content {
          .tooltip {
            opacity: 1;
          }
        }
      }
    }

    .pucl-table-wrapper {
      .pucl-data-table {
        .sku-info-table-actions {
          .remove-text {
            padding-left: 5px;
          }
        }
      }

      .header-cell:not(:first-child) {
        .content {
          padding-left: 0px;
        }
      }
    }

    .expandable-container {
      .header-border.stuck {
        box-shadow: none;
      }

      .bottom-shadow {
        display: none;
      }

      .pucl-scrollarea--vertical-track.table-scroll-margin {
        margin-top: 0px;
      }
    }
  }

  .product-detail-form-right-third-party {
    padding-left: 100px;
  }

  .code-product-status-select-third-party,
  .applicable-agreement-types-thirdparty {
    margin-top: 20px;
  }

  .product-detail-form-center-third-party {
    height: 524px;
  }

  // Product Detail
  .product-detail-section {
    padding-bottom: 150px;
    .variable-load-help {
      color: #767676;
      size: 12px;
      font-weight: 400;
    }
    .tooltip.face-value-tooltip {
      opacity: unset;
      .face-value-icon {
        color: #767676;
        margin-left: 5px;
      }
    }
    .product-detail-form-subscription {
      padding: 50px 50px 50px 50px;
      flex-direction: row;

      .pucl-divider.secondary {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .face-value-money {
        margin-left: -15px;

        .pucl-input.pucl-input-wrapper {
          .pucl-input-field-container {
            width: 378px;
          }
        }
      }
    }

    .product-detail-form-money {
      padding: 50px 50px 50px 50px;
      flex-direction: row;
      align-items: start;

      .pucl-divider.secondary {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .face-value-money { 
        margin-top: 20px;

        > .face-value-selection {
          display: flex;

          .pucl-select__field {
            width: 110px;
            margin-top: 10px;
            border-radius: 2.5px 0px 0px 2.5px;
          }

          .pucl-input.pucl-input-wrapper {
            .pucl-input-field-container {
              border-radius: 0px 2.5px 2.5px 0px;
              border-left: none;
            }
          }
        }

        > .face-value-selection-hidden {
          display: flex;

          .pucl-select__field {
            width: 110px;
            margin-top: 10px;
            border-radius: 2.5px 0px 0px 2.5px;
          }

          .pucl-input.pucl-input-wrapper {
            .pucl-input-field-container {
              border-radius: 0px 2.5px 2.5px 0px;
              border-left: none;
            }
          }
        }
      }

    }

    .product-detail-form-center-money {
      height: 432px;
    }

    .product-detail-form-center-subscription {
      height: 340px;
    }

    .code-product-status-select-money,
    .countries-select,
    .code-product-description,
    .applicable-agreement-types-money {
      margin-top: 20px;
    }
    .applicable-agreement-types-subscription {
      margin-top: 20px;
    }

    .exclusive-to-partners-select,
    .exclusive-to-partners-select-subscription {
      margin-top: 20px;
    }

    .exclusive-to-partners-select-money {
      margin-top: 20px;
    }

    .countries-multi-display {
      margin-top: 20px;

      .pucl-select-pill__close,
      .pucl-select-pill__clear {
        pointer-events: none;
        cursor: pointer;
      }
    }

    .no-product-details {
      display: flex;
      justify-content: center;
      margin-top: 100px;
      font-size: 18px;
    }
  }
}

.warning-modal-footer {
  padding-top: 20px;
}