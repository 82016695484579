@media (min-width: 1200px){
  .container {
      width: 96%;
}
}
.partner-detail-agreements-grid {
  margin: 0px;
  padding: 0px;
  width: 100%;
  .partner-detail-agreements-row {
    margin: 0px;
    .partner-detail-agreements-col {
      margin: 0px;
      padding: 0px;

      .disable-link { color: #363636; }

      .pucl-loader {
        margin-top: 100px !important;
      }

      div {
        .pucl-data-table {
          .header-cell.first {
            .content {
              padding-left: 0px;
            }
          }
          .header-cell:not(.first) {
            .content {
              padding-left: 0px;
            }
          }
          .cell-container.first {
            padding-left: 20px;
          }

          .cell-container.agreementStatus {
            .row-cell-content {
              span {
                margin-right: 6px;
              }
            }
          }

          .header-cell-status {
            .sort-icons {
              padding-right: 10px;
            }
          }
        }

       .header-cell-agreementId {
          pointer-events: none;
        }

        .header-cell-countryCoverage {
          pointer-events: none;
        }

        .header-cell-voucherProductTypeCoverage {
          pointer-events: none;
        }
      }
    }
  }
}

.partner-detail-agreements-paginator {
  margin: 30px;
  display: flex;
  padding-bottom: 115px;
  justify-content: space-between;
  .pucl-select-container {
    min-width: 80px;
  }

  .page-link {
    text-decoration: none;
  }

  .sr-only {
    width: 9px!important;
    height: 12px!important;
    margin: 0;
    position: inherit;
  }
}

