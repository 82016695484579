.static-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: 'SSTPro-Medium';
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .label {
    width: 100%;
    margin-bottom: 10px;
    padding: 0px;
    font-weight: bold;
    color: #363636;
    text-align: left;
    font-size: 14px;
    font-family: 'SSTPro-Roman';

    &.isSafari {
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-text-stroke:1px transparent;
    }
  }

  .value {
    &.isSafari {
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-text-stroke:1px transparent;
    }
  }

  .required-symbol {
    color: red;
  }
}
