.adminDashboard-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;

  @media(max-width: 1199px) {
    margin-top: -20px;
  }

  .dashboard-title {
    align-self: start;
    margin-bottom: 60px;
  }

  // Venom Jobs Table
  .venom-jobs-table {
    width: 100%;
    display: flex;
    justify-content: center;

    .pucl-table-wrapper {
      .status {
        display: flex;
        justify-content: center;
        .row-cell {
          padding-right: 0px;
        }

        .switch-wrapper {
          input {
            min-width: 28px;
          }
          .pucl-input.pucl-input-wrapper {
            padding-bottom: 0px;
          }
        }
      }

      .job-code {
        border-left: 1px solid #E7E7E7;
      }

      .job-description {
        .tooltip {
          opacity: 1;
          z-index: 2100;
          margin-left: 0px;
          text-decoration: none;
          .description-tooltip{
            max-width: 200px;
            white-space: wrap;
          }

          .truncate {
            color: #363636;
            font-size: 14px;
          }
        }
      }
    }
  }

  // Vids Jobs Table
  .vids-jobs-table {
    width: 100%;
    display: flex;
    justify-content: center;

    .pucl-table-wrapper {
      .status {
        display: flex;
        justify-content: center;
        .row-cell {
          padding-right: 0px;
        }

        .switch-wrapper {
          input {
            min-width: 28px;
          }
          .pucl-input.pucl-input-wrapper {
            padding-bottom: 0px;
          }
        }
      }

      .job-code {
        border-left: 1px solid #E7E7E7;
      }

      .job-description {
        .tooltip {
          opacity: 1;
          z-index: 2100;
          margin-left: 0px;
          text-decoration: none;
          .description-tooltip {
            max-width: 200px;
            white-space: wrap;
          }

          .truncate {
            color: #363636;
            font-size: 14px;
          }
        }
      }
    }
  }

  /** Maintenance Mode - Start */
  .maintenance-mode {
    width: 100%;
    display: flex;
    justify-content: center;
    
    .maintenance-mode-content {
      padding: 50px 50px 50px 20px;
      display: flex;

      .maintenance-mode-toggle {
        padding-left: 20px;

        .maintenance-mode-switch {
          .pucl-switch-input {
            margin-top: 2px;
          }
        }
      }

      .maintenance-mode-value {
        padding-left: 5px;
      }
    }
  }
  /** Maintenance Mode - End */

  /** Batch Notifications - Start */
  .batch-notifications {
    width: 100%;
    display: flex;
    justify-content: center;
    
    .batch-notifications-content {
      padding: 50px 50px 50px 20px;
      display: block;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      min-height: 250px;

      .inputs {
        display: flex;
        padding-top: 20px;

        .entity-type,
        .notification-type {
          min-width: 200px;
          padding-left: 30px;
        }
      }
    }

    .batch-notifications-footer {
      height: 70px;
      display: flex;
      width: 100%;
      justify-content: end;
      align-items: center;
      padding-right: 20px;
    }
  }
  /** Batch Notifications - End */

    /** Partner Merge - Start */
    .partner-merge {
      width: 100%;
      display: flex;
      justify-content: center;
  
      .partner-merge-content {
        padding: 50px 50px 50px 20px;
        display: block;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  
        .inputs {
          display: flex;
          padding-top: 20px;
  
          .arrow-forward {
            display: flex;
            align-items: center;
            padding: 16px 20px 0px 20px;
          }
  
          .validate-button {
            display: block;
            align-items: center;
            padding-top: 32px;
            padding-left: 20px;
          }
        }
      }
  
      .partner-merge-footer {
        height: 70px;
        display: flex;
        width: 100%;
        justify-content: end;
        align-items: center;
        padding-right: 20px;
      }
    }
    /** Partner Merge - End */
}