.date-time {
  display: flex;

  .date-input {
    padding-right: 10px;
  }

  .time-input {
    padding-top: 23px;

    .pucl-time-input.t12hr {
      div:nth-child(2) {
        padding-top: 5px;
      }
    }
  }
}